export const themeColors = {
  primary: '#fec70b',
  secondary: '#393939',
  primaryButton: '#6D5FA6',
  secondaryButton: '#393939',
  accent: '#6d5fa5',
  dark: '#222222',
  darkGrey: '#888888',
  lightGrey: '#eeeeee',
  divider: '#e3e3e3',
  inputDisabled: '#C4C4C4',
  body: '#000000',
  green: '#49B876',
  success: '#3CC60A',
  purple: '#6C5EA7',
  white: '#ffffff',
  blue: '#48B4D7',
  lightBlue: '#C1DCE0',
  error: '#E4333E',
  warning: '#F29D39',
  background: '#f5f5f5'
};

export const inputSxStyles = {
  '& label.Mui-focused': {
    color: themeColors.secondary
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: themeColors.primary
    },
    '&.Mui-focused fieldset': {
      borderColor: themeColors.primary
    }
  }
};

export const inputDenseSxStyles = {
  '& label.Mui-focused': {
    color: themeColors.secondary
  },
  '& .MuiOutlinedInput-root': {
    input: {
      padding: '4px 32px 4px 16px'
    },
    '&:hover fieldset': {
      borderColor: themeColors.primary
    },
    '&.Mui-focused fieldset': {
      borderColor: themeColors.primary
    }
  }
};

export const size = {
  mobile: '599px',
  tabletPortrait: '600px',
  tabletLandscape: '900px',
  desktopS: '900px',
  desktopM: '960px',
  desktop: '1200px',
  desktopL: '1800px'
};

export const device = {
  mobileOnly: `(max-width: ${size.mobile})`,
  mobileMin: `(min-width: ${size.mobile})`,
  tabletPortrait: `(min-width: ${size.tabletPortrait})`,
  tabletLandscape: `(min-width: ${size.tabletLandscape})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktopM: `(max-width: ${size.desktopM})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopMax: `(max-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`
};

export const boxShadow: string = `0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);`;
