export const convertKeysToLowerCase = (obj: any): any => {
  return Object.keys(obj).reduce((acc: any, key: string) => {
    const newKey = key.charAt(0).toLowerCase() + key.slice(1);
    let value = obj[key];
    if (Array.isArray(value)) {
      value = value.map((item: any) => (typeof item === 'object' ? convertKeysToLowerCase(item) : item));
    } else if (typeof value === 'object' && value !== null) {
      value = convertKeysToLowerCase(value);
    }
    acc[newKey] = value;
    return acc;
  }, {});
};