
export const setAccessToken = (dispatch:any, data: any) => {
  dispatch({ type: 'SET_TOKEN', payload: data });
}

export const triggerSnackbar = (dispatch:any, data: any) => {
  dispatch({ type: 'TRIGGER_SNACKBAR', payload: data});
}

export const updateIntroSteps = (dispatch:any, data: any) => {
  dispatch({ type: 'UPDATE_INTRO_STEPS', payload: data});
}

export const getConnectors = (dispatch:any, data: any) => {
  dispatch({ type: 'GET_CONNECTORS', payload: data });
}