import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import { IconSvgProps } from 'utils/interfaces/iconSvg.interface';

const TooltipIcon: FC<IconSvgProps> = ({
    color = themeColors.body,
    height = '16px',
    width = '16px'
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-automation-id="icon-svg-tooltip-icon"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.44 6.75684C1.44 3.99541 3.58903 1.75684 6.24 1.75684C8.89094 1.75684 11.04 3.99541 11.04 6.75684C11.04 9.51824 8.89094 11.7568 6.24 11.7568C3.58903 11.7568 1.44 9.51824 1.44 6.75684ZM6.24 0.756836C3.05883 0.756836 0.479996 3.44313 0.479996 6.75684C0.479996 10.0705 3.05883 12.7568 6.24 12.7568C9.42115 12.7568 12 10.0705 12 6.75684C12 3.44313 9.42115 0.756836 6.24 0.756836ZM5.76 4.25684C5.76 3.9807 5.97489 3.75684 6.24 3.75684C6.5051 3.75684 6.72 3.9807 6.72 4.25684C6.72 4.53298 6.5051 4.75684 6.24 4.75684C5.97489 4.75684 5.76 4.53298 5.76 4.25684ZM6.24 9.75684C6.5051 9.75684 6.72 9.53299 6.72 9.25684V6.25664C6.72 5.98049 6.5051 5.75663 6.24 5.75663C5.97489 5.75663 5.76 5.98049 5.76 6.25664V9.25684C5.76 9.53299 5.97489 9.75684 6.24 9.75684Z"
            fill={color}
        />
    </svg>
);

export default TooltipIcon;
