
import styled from 'styled-components';
import { themeColors } from 'assets/theme/style';
import { fontSizes} from 'assets/theme/typography';

export const CustomerMenuContainer = styled.div`
    display: flex;
    flex-direction: column;

    .MuiButtonBase-root:hover {
      background-color: transparent ;
    }
`;

