export const AppReducer = (state: any, action: any) => {
  switch (action.type) {

    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload
        
      };

      case "TRIGGER_SNACKBAR":
      return {
        ...state,
        snackbarOpen: action.payload.open,
        snackbarData: action.payload.data  
      };

      case "UPDATE_INTRO_STEPS":
      return {
        ...state,
        stepIntegrations: action.payload.stepIntegrations,
        stepInvitations: action.payload.stepInvitations  
      };

      case "GET_CONNECTORS":
      return {
        ...state,
        connectors: action.payload
      };
          
    default:
      return state;
  }
};
  