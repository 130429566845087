import { ChangeEvent, MouseEvent, FC } from 'react';
import {
    OutlinedInputContainer,
    OutlinedInputLabel,
    OutlinedInputMessage,
    OutlinedInputStyled
} from './styled';

export interface OutlinedInputProps {
    defaultValue?: string;
    message?: string;
    errorMessage?: string;
    changeHandler?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    register?: any;
    label?: string;
    type?: 'number' | 'password' | 'text';
    placeholder?: string;
    multiline?: boolean;
    disabled?: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    endAdornment?: any;
    startAdornment?: any;
}

const OutlinedInput: FC<OutlinedInputProps> = ({
    defaultValue,
    message,
    errorMessage,
    register,
    label,
    type,
    placeholder,
    disabled,
    changeHandler,
    onClick,
    multiline,
    endAdornment,
    startAdornment
}) => (
    <OutlinedInputContainer>
        {label && <OutlinedInputLabel>{label}</OutlinedInputLabel>}

        <OutlinedInputStyled
            autoComplete="off"
            placeholder={placeholder || ''}
            value={defaultValue}
            type={type}
            disabled={disabled}
            onClick={onClick}
            onChange={changeHandler}
            inputProps={register}
            multiline={multiline}
            endAdornment={endAdornment}
            startAdornment={startAdornment}
        />

        {(message || errorMessage) && (
            <OutlinedInputMessage errorMessage={errorMessage || ''}>
                {message || errorMessage}
            </OutlinedInputMessage>
        )}
    </OutlinedInputContainer>
);

export default OutlinedInput;
