import { useState, useEffect } from 'react';
import { Content, TabsContainer, TabsStyled } from './styled';

interface TabProps {
    children: any;
    initialTab: any;
}

const Tabs: React.FC<TabProps> = ({ children, initialTab }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.title);

    const handleClick = (e: any, newActiveTab: any) => {
        e.preventDefault();
        setActiveTab(newActiveTab);
    };

    useEffect(() => {
        if (initialTab) {
            setActiveTab(initialTab);
        }
    }, []);

    return (
        <div>
            <TabsContainer>
                <TabsStyled>
                    {children.map((tab: any) => {
                        const title = tab.props.title;
                        return (
                            <li
                                className={title === activeTab ? 'active' : ''}
                                key={title}
                                onClick={(e) => handleClick(e, title)}
                            >
                                {title}
                            </li>
                        );
                    })}
                </TabsStyled>
            </TabsContainer>
            {children.map((tabbedContent: any) => {
                if (tabbedContent.props.title === activeTab) {
                    return (
                        <Content key={tabbedContent.props.title}>
                            {tabbedContent.props.children}
                        </Content>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
};

export { Tabs };
