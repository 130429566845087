import { Dialog } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const DialogStyled = styled(Dialog)`
  width: 100%;

  .MuiDialog-paperWidthSm{
    max-width: 960px;
  }
`;
