export const CustomerReducer = (state: any, action: any) => {
  switch (action.type) {

    case "GET_CUSTOMERS":
      return {
        ...state,
        customers: action.payload
        
      };
          
    default:
      return state;
  }
};
  