import { boxShadow, themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { device } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';

type AnalyticsType = {
  justifyContent?: 'flex-end' | 'flex-start' | 'center';
  direction?: 'column' | 'row';
};

export const AnalyticsViewContainer = styled.div`
  width: calc(100% - 64px);
  display: flex;
  padding: 0 32px;
  justify-content: flex-end;
  flex-direction: column;
  gap: 8px;
`;

export const AnalyticsImage = styled.img`
  width: calc(100% - 64px);
  padding: 32px;
  border-radius: 8px;
  background-color: ${themeColors.primary};
`;

export const AnalyticsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AnalyticsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobileOnly} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const AnalyticsButtonContainer = styled.div<AnalyticsType>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  align-items: center;
  gap: 16px;
  width: 100%;
  @media ${device.mobileOnly} {
    justify-content: center;
  }
`;

export const AnalyticsChartsWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  gap: 16px;
  width: calc(100% - 64px);
  min-height: calc(100vh - 420px);
  padding: 32px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${themeColors.primary};
  @media ${device.desktopMax} {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    padding: 16px;
  }
`;

export const AnalyticsHeaderActionButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const AnalyticsHeaderActionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
`;

export const AnalyticsChartsNewReportContainer = styled.div`
  display: flex;
  ${typography.captionRegular};
`;

export const AnalyticsChartsLastReportDate = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  color: ${themeColors.darkGrey};
  font-size: ${fontSizes.xsmall};
`;

export const AnalyticsChartsLeftWrapper = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 16px;
  @media ${device.desktopMax} {
    width: 100%;
  }
`;

export const AnalyticsChartsRightWrapper = styled.div`
  display: flex;
  width: 70%;
  gap: 16px;
  @media ${device.desktopMax} {
    width: 100%;
  }
`;

export const AnalyticsChartsRightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AnalyticsChartsTitle = styled.h2`
  ${typography.bodyBold};
`;

export const AnalyticsChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  height: 100%;
  background-color: ${themeColors.white};
  box-shadow: ${boxShadow};
`;

export const AnalyticsChartsRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media ${device.mobileOnly} {
      flex-direction: column;
  }
`;

export const AnalyticsChartsEmptyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnalyticsChartsEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  min-height: calc(100vh - 420px);
  box-shadow: ${boxShadow};
  background-color: ${themeColors.white};
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 32px;
  @media ${device.mobileOnly} {
    padding: 16px;
  }
`;

export const AnalyticsChartsEmptyMessage = styled.p`
  text-align: center;
`;

export const AnalyticsChartsNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 380px;
  color: ${themeColors.white};
`;

// TAB - COMPANIES HOUSE
export const TabOuterContainer = styled.div`
  position: relative;
  background-color: ${themeColors.primary};
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 32px;
`;

export const TabCard = styled.div`
  display grid;
  gap: 1rem;
  box-shadow: ${boxShadow};
  @media ${device.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const TabUpdateButton = styled.div`
  display flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  font-size: ${fontSizes.medium};
  .update-button-container {
    display: flex; 
    gap: 1rem;
    align-items: center;
  }
  .import-label {
    font-size: ${fontSizes.small}
  }
  button {
    cursor: pointer;
    &:hover {
      background: ${themeColors.secondaryButton};
      color: ${themeColors.white}
    }
  }
`;

export const TabCardContainer = styled.div`
  position: relative;
  gap: 15px;
  min-height: 611px;
  justify-content: center;
  box-shadow: ${boxShadow};
  border: 1px solid ${themeColors.white};
  background-color: ${themeColors.white};
  border-radius: 8px;
  padding: 32px;
  @media ${device.mobileOnly} {
    padding: 16px;
  }
  h2 {
    font-weight: bold;
    font-size: ${fontSizes.large};
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
  span {
    font-weight: bold;
  }
  .divider {
    border-top: 1px solid ${themeColors.divider};
    margin: 1rem 0;
    &.small {
      width: 30%;
    }
  }
`;
