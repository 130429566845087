import styled from 'styled-components';

export const InvitationsContainer = styled.div`
  width: calc(100% - 64px);
  display: flex;
  padding: 0 32px;
  justify-content: flex-end;
  flex-direction: column;
  gap: 20px;
`;

export const InvitationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  button {
    height: auto;
  }
`;

