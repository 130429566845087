/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState } from 'react';
import {
    IntroDashboardViewWrapper,
    IntroDashboardViewHeaderTitle,
    IntroDashboardViewContent,
    IntroDashboardViewContentVideo,
    IntroDashboardViewContentDescription,
    IntroDashboardViewButtonContainer,
    IntroDashboardViewButtonText,
    IntroDashboardViewButtonWrapper,
    IntroDashboardViewContentTitle,
    IntroDashboardViewContentSteps,
    IntroDashboardViewContentStepsContainer,
    IntroDashboardViewTextBold,
    CheckIconContainer,
    IntroDashboardViewHeaderIcon
} from './styled';
import Context from 'context';
import CloseIcon from '@mui/icons-material/Close';
import video from 'assets/videos/intro-dashboard.mp4';
import { PrimaryButton } from 'components/common/buttons';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CustomerInvitationView from 'views/CustomerInvitationView';
import FullViewDialog from 'components/dialog/FullViewDialog';
import ReactPlayer from 'react-player';
import { themeColors } from 'assets/theme/style';

interface IntroDashboardViewProps {
    closeHandler?: () => void;
}

const IntroDashboardView: FC<IntroDashboardViewProps> = ({ closeHandler }) => {
    const navigate = useNavigate();
    const [state] = useContext(Context);
    const [inviteCustomerDialogOpen, setInviteCustomerDialogOpen] = useState<boolean>(false);
    const stepIntegrations: boolean = state?.app?.stepIntegrations;

    const toggleInviteClickHandler: (status?: string) => void = (status) => {
        if (status === 'close') {
            setInviteCustomerDialogOpen(!inviteCustomerDialogOpen);
            closeHandler && closeHandler();
            window.location.reload();
        } else {
            setInviteCustomerDialogOpen(!inviteCustomerDialogOpen);
        }
    };

    return (
        <IntroDashboardViewWrapper>
            <IntroDashboardViewHeaderIcon>
                <CloseIcon
                    onClick={closeHandler}
                    style={{ color: themeColors.body, cursor: 'pointer' }}
                />
            </IntroDashboardViewHeaderIcon>

            <FullViewDialog
                open={inviteCustomerDialogOpen}
                clickHandler={toggleInviteClickHandler}
                dialogContent={
                    <CustomerInvitationView
                        closeHandler={() => toggleInviteClickHandler('close')}
                    />
                }
            />
            <IntroDashboardViewHeaderTitle>
                Get Started using G2F Connect
            </IntroDashboardViewHeaderTitle>
            <IntroDashboardViewContent>
                <IntroDashboardViewContentVideo>
                    <ReactPlayer
                        url={video}
                        width="100%"
                        height="100%"
                        style={{ justifyContent: 'center' }}
                        controls
                    />
                </IntroDashboardViewContentVideo>
                <IntroDashboardViewContentDescription>
                    <IntroDashboardViewContentTitle>
                        Steps to success:
                    </IntroDashboardViewContentTitle>
                    <IntroDashboardViewContentStepsContainer>
                        <IntroDashboardViewContentSteps>
                            <IntroDashboardViewTextBold>Step 1 :</IntroDashboardViewTextBold> Select
                            and setup which integrations you wish to use. 
                        </IntroDashboardViewContentSteps>

                        <IntroDashboardViewContentSteps>
                            <IntroDashboardViewTextBold>Step 2 :</IntroDashboardViewTextBold> Invite
                            your customers to connect.
                        </IntroDashboardViewContentSteps>
                        <IntroDashboardViewContentSteps>
                            <IntroDashboardViewTextBold>Step 3 :</IntroDashboardViewTextBold>{' '}
                            Generate an API Key to access the data via the APIs, or simply view it
                            via the Customer screen.
                        </IntroDashboardViewContentSteps>
                    </IntroDashboardViewContentStepsContainer>
                </IntroDashboardViewContentDescription>
            </IntroDashboardViewContent>
            <IntroDashboardViewButtonWrapper>
                <IntroDashboardViewButtonContainer>
                    <PrimaryButton clickHandler={() => navigate('/integrations')}>
                        <IntroDashboardViewButtonText>Integrations​</IntroDashboardViewButtonText>
                    </PrimaryButton>
                    {stepIntegrations && (
                        <CheckIconContainer>
                            <CheckIcon />
                        </CheckIconContainer>
                    )}
                </IntroDashboardViewButtonContainer>
                <IntroDashboardViewButtonContainer>
                    <PrimaryButton
                        clickHandler={toggleInviteClickHandler}
                        disabled={!stepIntegrations}
                    >
                        <IntroDashboardViewButtonText>
                            Invite a Customer ​
                        </IntroDashboardViewButtonText>
                    </PrimaryButton>
                </IntroDashboardViewButtonContainer>
                <IntroDashboardViewButtonContainer>
                    <PrimaryButton
                        clickHandler={() => navigate('/developer')}
                        disabled={!stepIntegrations}
                    >
                        <IntroDashboardViewButtonText>
                            Generate your API Keys 
                        </IntroDashboardViewButtonText>
                    </PrimaryButton>
                </IntroDashboardViewButtonContainer>
            </IntroDashboardViewButtonWrapper>
        </IntroDashboardViewWrapper>
    );
};

export default IntroDashboardView;
