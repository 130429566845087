import { FC, useState } from 'react';
import { SnackbarWrapper } from './styled';

interface SnackbarProps {
    open: boolean;
    message?: JSX.Element | string;
    closeHandler?: () => void;
    role?: 'success' | 'warning' | 'error' | 'alert' | undefined;
}

const SnackbarContainer: FC<SnackbarProps> = ({
    open,
    message,
    closeHandler,
    role = 'success'
}) => {
    return (
        <SnackbarWrapper
            open={open}
            onClose={closeHandler}
            message={message || ''}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            role={role}
        ></SnackbarWrapper>
    );
};

export default SnackbarContainer;
