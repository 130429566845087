import {
    ConfigurationEDITypes,
    ConfigurationTypes,
    ConfigurationZohoTypes,
    IntegrationItem
} from 'utils/interfaces/IntegrationItem';

export const integrationInitialConfigurationData: ConfigurationTypes = {
    externalCustomerMapping: {
        delimiter: ',',
        hasHeader: false,
        default: `[{"ExternalCustomerRef":"{{Customer.Tokens[0]}}","DynamicProperties":{"SourceText":"{{Customer.File.Name}} Row {{Customer.Index}}","CustomerRef":"{{Customer.Tokens[0]}}","Name":"{{Customer.Tokens[1]}}","RegistrationNumber":null,"Country":null,"Active":"true","LastModifiedErpDate":"{{Customer.LastWriteTimeUtc}}","Sic":null,"Status":null,"PrimaryEmailAddress":null,"Source":"Csv"}},{"ExternalCustomerRef":"{{Customer.Tokens[3]}}","DynamicProperties":{"SourceText":"{{Customer.File.Name}} Row {{Customer.Index}}","CustomerRef":"{{Customer.Tokens[3]}}","Name":"{{Customer.Tokens[2]}}","RegistrationNumber":null,"Country":null,"Active":"true","LastModifiedErpDate":"{{Customer.LastWriteTimeUtc}}","Sic":null,"Status":null,"PrimaryEmailAddress":null,"Source":"Csv"}}]`
    },
    invoiceMapping: {
        delimiter: ',',
        hasHeader: false,
        default: `{"InvoiceUid":"{{Invoice.Tokens[4]}}","DynamicProperties":{"SourceText":"{{Invoice.File}} Row {{Invoice.Index}}","Version":"1.0","VersionDate":"2023-09-09","InvoiceNumber":"{{Invoice.Tokens[4]}}","Buyer":null,"Supplier":null,"Obligor":null,"IssueDate":"2023-09-09","DueDate":"{{Invoice.Tokens[5]}}","Amount":"{{Invoice.Tokens[6]}}","Currency":"{{Invoice.Tokens[7]}}","BalanceDue":"{{Invoice.Tokens[8]}}","AmountPaid":"{{Invoice.Tokens[9]}}","Dilution":null,"CloseDate":"{{Invoice.Tokens[10]}}","PurchaseOrder":null,"PurchaseOrderVersion":null,"Reference":null,"ShipTo":null,"Shipper":null,"TrackingNumber":null,"PaymentMethod":null,"DisputeAmount":null,"Tax":null,"Jurisdiction":null,"Id":null,"BillingAddressId":null,"LastModifiedErpDate":"{{Invoice.LastWriteTimeUtc}}","Source":"Csv","SupplierRef":"{{Invoice.Tokens[0]}}","BuyerRef":"{{Invoice.Tokens[3]}}","ObligorRef":null,"Type":"AR","Status":"Sent"}}`
    },
    paymentMapping: {
        delimiter: ',',
        hasHeader: false,
        default: `{"PaymentUid":"{{Payment.Tokens[1]}}","DynamicProperties":{"TransactionDate":"{{Payment.Tokens[2]}}","Amount":" {{Payment.Tokens[3]}}","Reference":"{{jsonescape(Payment.Tokens[4])}}","CurrencyRate":"{{Payment.Tokens[5]}}","ErpAccountId":"{{Payment.Tokens[6]}","ErpAccountCode":"{{Payment.Tokens[7]}}","InvoiceUid":"{{Payment.Tokens[0]}}","InvoiceNumber":"{{jsonescape(Payment.Tokens[0])}}","Status":"{{Payment.Tokens[8]}}"}}`
    },
    purchaseOrderMapping: {
        delimiter: ',',
        hasHeader: false,
        default: `{"PurchaseOrderUid":"{{PurchaseOrder.Tokens[0]}}","DynamicProperties":{"Version":"1.0","VersionDate":"2023-07-01","PurchaseOrderNumber":"{{jsonescape(PurchaseOrder.Tokens[1])}}","Amount":"{{PurchaseOrder.Tokens[1]}}","Currency":"{{PurchaseOrder.Tokens[2]}}","Reference":"{{jsonescape(PurchaseOrder.Tokens[7])}}","CreatedErpDate":"{{PurchaseOrder.Tokens[4]}}","LastModifiedErpDate":"{{PurchaseOrder.LastWriteTimeUtc}}","BuyerRef":"{{PurchaseOrder.Tokens[3]}}","Status":"{{PurchaseOrder.Tokens[6]}}"}}`
    }
};

export const integrationEDIConfiguration: ConfigurationEDITypes = {
    format: 'X12',
    sftpAccount: {
        hostname: '',
        username: '',
        password: ''
    }
};

export const integrationZohoConfiguration: ConfigurationZohoTypes = {
    includeLineItems: false
};

export const integrationInitialData: IntegrationItem[] = [
    {
        connectorId: '',
        connectorType: 'xero',
        clientId: '',
        clientSecret: '',
        configuration: null,
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'quickbooks',
        clientId: '',
        clientSecret: '',
        configuration: null,
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'zohobooks',
        clientId: '',
        clientSecret: '',
        configuration: integrationZohoConfiguration,
        status: 'Active',
        includeLineItems: true
    },
    {
        connectorId: '',
        connectorType: 'sage',
        clientId: '',
        clientSecret: '',
        configuration: null,
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'csv',
        clientId: '',
        clientSecret: '',
        configuration: integrationInitialConfigurationData,
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'edi',
        clientId: '',
        clientSecret: '',
        configuration: integrationEDIConfiguration,
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'fides',
        clientId: '',
        clientSecret: '',
        configuration: {},
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'tr',
        clientId: '',
        clientSecret: '',
        configuration: {},
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'freshbooks',
        clientId: '',
        clientSecret: '',
        configuration: null,
        status: 'InActive',
        includeLineItems: false
    },
    {
        connectorId: '',
        connectorType: 'myob',
        clientId: '',
        clientSecret: '',
        configuration: null,
        status: 'InActive',
        includeLineItems: false
    }
];
