import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import { FC } from 'react';
import {
    AlertDialogButtonWrapper,
    AlertDialogDialogStyled,
    AlertDialogText,
    AlertDialogTitle
} from './styled';
import Divider from 'components/divider';
import SecondaryButton from 'components/common/buttons/SecondaryButton';

interface AlertDialogProps {
    title: string;
    description: string;
    disabledPrimaryButton?: boolean;
    disabledSecondButton?: boolean;
    primaryButtonText?: string;
    secondButtonText?: string;
    open: boolean;
    clickHandler: () => void;
    clickSecondHandler: () => void;
    height?: string;
    width?: string;
}

const AlertDialog: FC<AlertDialogProps> = ({
    title,
    description,
    primaryButtonText = 'Confirm',
    secondButtonText = 'Back',
    open = false,
    clickHandler,
    clickSecondHandler,
    height = 'auto',
    width = '400px',
    disabledPrimaryButton,
    disabledSecondButton
}) => (
    <AlertDialogDialogStyled
        open={open}
        onClose={clickSecondHandler}
        disableEscapeKeyDown
        height={height}
        width={width}
    >
        <AlertDialogTitle>{title}</AlertDialogTitle>
        <Divider margin="20px 0" />
        <AlertDialogText>{description}</AlertDialogText>
        <AlertDialogButtonWrapper>
            <SecondaryButton
                text={secondButtonText}
                disabled={disabledSecondButton}
                clickHandler={() => clickSecondHandler()}
                padding="16px"
                width="fit-content"
                height="54x"
            ></SecondaryButton>
            <PrimaryButton
                disabled={disabledPrimaryButton}
                text={primaryButtonText}
                clickHandler={() => clickHandler()}
                padding="16px"
                width="fit-content"
                height="54px"
            ></PrimaryButton>
        </AlertDialogButtonWrapper>
    </AlertDialogDialogStyled>
);

export default AlertDialog;
