import { boxShadow, themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { device } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';

export const AnalyticsChartSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

export const AnalyticsChartSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  width: calc(100% - 32px);
  border-radius: 8px;
  height: 100%;
  background-color: ${themeColors.white};
  box-shadow: ${boxShadow};

`;

export const AnalyticsChartSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AnalyticsChartIconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const AnalyticsChartSummaryInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;
    @media ${device.desktopMax} {
    flex-direction: row;
    justify-content: space-around;
    gap: 32px;
  }
  @media ${device.mobileOnly} {
    flex-direction: column;
    gap: 8px;
  }
`;

export const AnalyticsChartSummaryRiskStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: center;
  @media ${device.desktopMax} {
    width: 50%;
  }
  @media ${device.mobileOnly} {
      width: 100%;
  }
`;

export const AnalyticsChartSummaryRiskStatus = styled.p`
  text-align: center;
`;

export const AnalyticsChartSummaryRiskNumber = styled.h3`
  font-size: ${fontSizes.xLarge};
  font-weight: ${fontWeights.bold};
`;

export const AnalyticsChartSummaryTitle = styled.h2`
  ${typography.bodyBold};
`;

export const AnalyticsChartSummaryDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 25px;
`;

export const AnalyticsChartSummaryDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
`;

export const AnalyticsChartSummaryDetailBoxSubtitle = styled.p`
  color: ${themeColors.body};
  text-align: center;
  font-weight: bold;
`;

export const AnalyticsChartSummaryDetailBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AnalyticsChartSummaryDetailBox = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 8px;
  justify-content: center;
  font-size: ${fontSizes.small};
  background-color: ${themeColors.lightBlue};
`;

export const AnalyticsChartSummaryDetailTitle = styled.p`
  text-align: center;
  font-size: ${fontSizes.small};
  padding: 0 16px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TooltipTitle = styled.p`
  ${typography.captionBold};
`;

export const TooltipDescription= styled.p`
  ${typography.captionRegular};
`;

