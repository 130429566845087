/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import {
    IntegrationsViewButtonWrapper,
    IntegrationsViewCheckboxContainer,
    IntegrationsViewClientContainer,
    IntegrationsViewClientWrapper,
    MappingCheckboxLabel
} from '../styled';
import TextInput from 'components/forms/inputs/TextInput';
import Switch from '@mui/material/Switch';
import { ConfigurationZohoTypes, IntegrationItem } from 'utils/interfaces/IntegrationItem';
import { Checkbox } from '@mui/material';
import { themeColors } from 'assets/theme/style';

export interface IConnectorClientViewProps {
    data: IntegrationItem[];
    saveHandler: (event: React.KeyboardEvent<HTMLInputElement>, type: string) => void;
    updateHandler: (
        type: string,
        id: string,
        key: string,
        value: string | boolean | ConfigurationZohoTypes
    ) => void;
}

const label = { inputProps: { 'aria-label': 'Switch integration status' } };

const ConnectorClientView: FC<IConnectorClientViewProps> = ({
    data,
    updateHandler,
    saveHandler
}) => {
    const checkEmptyField: (type: string) => boolean = (type) => {
        const integrationsDataByType: IntegrationItem = data.filter(
            (item: IntegrationItem) => item.connectorType === type
        )[0];

        const isDataEmpty =
            integrationsDataByType.clientId === '' || integrationsDataByType.clientSecret === '';
        return isDataEmpty;
    };
    console.log(data);
    return (
        <IntegrationsViewClientWrapper>
            {data.map((item: IntegrationItem, i: number) => (
                <IntegrationsViewClientContainer key={`${i}-Client`}>
                    <TextInput
                        label="Client Id"
                        placeholder="Client Id"
                        // disabled={item.status === 'InActive'}
                        defaultValue={item.clientId}
                        handleKeyDown={(event: any) =>
                            !checkEmptyField(item.connectorType) &&
                            saveHandler(event, item.connectorType)
                        }
                        changeHandler={(event: any) =>
                            updateHandler(
                                item.connectorType,
                                item.connectorId || '',
                                'clientId',
                                event.target.value
                            )
                        }
                    />
                    <TextInput
                        label="Secret"
                        type="text"
                        placeholder="Secret"
                        defaultValue={item.clientSecret}
                        // disabled={item.status === 'InActive'}
                        handleKeyDown={(event: any) =>
                            !checkEmptyField(item.connectorType) &&
                            saveHandler(event, item.connectorType)
                        }
                        changeHandler={(event: any) =>
                            updateHandler(
                                item.connectorType,
                                item.connectorId,
                                'clientSecret',
                                event.target.value
                            )
                        }
                    />
                    {item.includeLineItems && (
                        <IntegrationsViewCheckboxContainer>
                            <MappingCheckboxLabel>Retrieve Line Items</MappingCheckboxLabel>
                            <Checkbox
                                disableRipple
                                checked={
                                    (item.configuration as ConfigurationZohoTypes)
                                        ?.includeLineItems ?? false
                                }
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.23)',
                                    '&.MuiCheckbox-root': {
                                        justifyContent: 'flex-start'
                                    },
                                    '&.Mui-checked': {
                                        color: themeColors.primary
                                    },
                                    '&.Mui-disabled': {
                                        color: themeColors.darkGrey
                                    },
                                    '&:hover': { bgcolor: 'transparent' }
                                }}
                                onChange={(event: any) =>
                                    updateHandler(
                                        item.connectorType,
                                        item.connectorId,
                                        'configuration',
                                        { includeLineItems: event.target.checked }
                                    )
                                }
                            />
                        </IntegrationsViewCheckboxContainer>
                    )}
                    <IntegrationsViewButtonWrapper>
                        <Switch
                            {...label}
                            color="success"
                            checked={item.status === 'Active' ? true : false}
                            onChange={() =>
                                updateHandler(
                                    item.connectorType,
                                    item.connectorId,
                                    'status',
                                    item.status === 'Active' ? 'InActive' : 'Active'
                                )
                            }
                        />
                    </IntegrationsViewButtonWrapper>
                </IntegrationsViewClientContainer>
            ))}
        </IntegrationsViewClientWrapper>
    );
};

export default ConnectorClientView;
