import { Box, LinearProgress } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { FC } from 'react';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import { DataGridProps } from 'utils/interfaces/data-grid/dataGrid.interface';
import { TableHeaderStyled, TableMUIStyled, TableWrapper } from './styled';

const DataGrid: FC<DataGridProps> = ({
    headers,
    data,
    loading,
    fixedHeader = true,
    selectableRows = 'none',
    rowSelectedHandler,
    rowClickHandler,
    rowsPerPage = 5,
    tableChangeHandler,
    totalRows,
    serverSide,
    rowCellClickHandler,
    columnSortChangeClickHandler,
    changeRowsPerPageHandler,
    changePageHandler,
    page,
    tableMobileWidth,
    lastChildDiv,
    showFooter = true
}) => {
    const tableOptions: any = {
        serverSide: serverSide || false,
        page: page || 0,
        count: totalRows || 10000,
        onTableChange: (action: any, tableState: any) => {
            if (tableChangeHandler) tableChangeHandler(action, tableState);
        },
        resizableColumns: true,
        selectableRows: selectableRows,
        rowHover: true,
        fixedHeader,
        responsive: 'vertical',
        tableBodyMaxHeight: fixedHeader ? '1200px' : '',
        elevation: 0,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [20, 50, 100],
        jumpToPage: (totalRows || data.length) > (rowsPerPage || 5) ? true : false,
        textLabels: {
            pagination: {
                jumpToPage: 'Go To Page',
                next: 'Next >',
                previous: '< Previous',
                rowsPerPage: 'Total items Per Page',
                displayRows: 'of'
            }
        },
        selectToolbarPlacement: 'none',
        onChangePage(currentPage: any) {
            if (changePageHandler) changePageHandler(currentPage);
        },
        onChangeRowsPerPage(numberOfRows: any) {
            if (changeRowsPerPageHandler) changeRowsPerPageHandler(numberOfRows);
        },
        onRowSelectionChange: (allSelected: any) => {
            if (!rowSelectedHandler) return;
            const result = allSelected.map((item: any) => {
                return data.at(item.index);
            });
            const selectedItems = result.map((item: any) => {
                return item;
            });
            rowSelectedHandler(selectedItems);
        },
        onRowClick: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
            if (!rowClickHandler) return;
            rowClickHandler(rowData, rowMeta);
        },
        onCellClick: (
            colData: any,
            cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }
        ) => {
            if (!rowCellClickHandler) return;
            rowCellClickHandler(colData, cellMeta);
        },
        onColumnSortChange: (changedColumn: string, direction: string) => {
            if (!columnSortChangeClickHandler) return;
            columnSortChangeClickHandler(changedColumn, direction);
        }
    };

    const styledHeaders: () => DataGridHeaderItem[] = () => {
        return headers.map((header) => {
            const { options } = header;
            options['customHeadLabelRender'] = (columnMeta: any) => (
                <TableHeaderStyled>{columnMeta.label}</TableHeaderStyled>
            );
            return header;
        });
    };

    return (
        <TableWrapper sx={{ backgroundColor: 'transparent' }}>
            {loading && (
                <Box sx={{ width: '100%', marginBottom: '-12px' }}>
                    <LinearProgress
                        sx={{
                            backgroundColor: themeColors.primary,
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: themeColors.accent
                            }
                        }}
                    />
                </Box>
            )}

            <TableMUIStyled
                title={''}
                data={data}
                columns={styledHeaders()}
                options={tableOptions}
                rowClickable={!!rowClickHandler}
                tableMobileWidth={tableMobileWidth}
                lastChildDiv={lastChildDiv}
                showFooter={showFooter}
            />
        </TableWrapper>
    );
};

export default DataGrid;
