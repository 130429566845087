/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { authResult } from 'lib/authConfig';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import {
    InvoiceDetailBackContainer,
    InvoiceDetailContainer,
    InvoiceDetailExtensionsContainer,
    InvoiceDetailExtensionsData,
    InvoiceDetailExtensionsHeader,
    InvoiceDetailExtensionsHeaderTitle,
    InvoiceDetailExtensionsValue,
    InvoiceDetailExtensionsWrapper,
    InvoiceDetailHeader,
    InvoiceDetailHeaderInformationContainer,
    InvoiceDetailHeaderInformationTitle,
    InvoiceDetailHeaderInformationValue,
    InvoiceDetailHeaderInformationWrapper,
    InvoiceDetailInformationAddress,
    InvoiceDetailInformationLabel,
    InvoiceDetailInformationLeft,
    InvoiceDetailInformationRight,
    InvoiceDetailInformationRow,
    InvoiceDetailInformationRowContainer,
    InvoiceDetailInformationTitle,
    InvoiceDetailInformationValue,
    InvoiceDetailInformationWrapper,
    InvoiceDetailLineItemsTotalConatiner,
    InvoiceDetailLineItemsTotalWrapper,
    InvoiceDetailParticipantsInformation,
    InvoiceDetailTitle,
    InvoiceDetailTotalAmountTitle,
    InvoiceDetailTotalAmountValue,
    InvoiceDetailTotalTaxAmount
} from './styled';
import LoaderFullPage from 'components/LoaderFullPage';
import { useNavigate, useParams } from 'react-router';
import { apiConfig, get } from 'api/api';
import {
    IInvoiceDetail,
    InvoiceAddress,
    InvoiceBuyer,
    InvoiceExtensions,
    InvoiceLineItems,
    InvoiceOwner,
    InvoiceSupplier
} from 'utils/interfaces/invoice.interface';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import DataGrid from 'components/DataGrid';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import Divider from 'components/divider/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';

const InvoiceDetailView: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [loading, setLoading] = useState<boolean>(false);
    const [invoice, setInvoice] = useState<IInvoiceDetail | null>(null);
    const invoiceUid: string = params?.invoiceUid || '';
    const customerUid: string = params?.customerUid || '';

    useEffect(() => {
        if (invoiceUid) getInvoice();
    }, [invoiceUid]);

    const getInvoice: () => any = async () => {
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        get(apiConfig.invoiceDetail(`${customerUid}`, `${invoiceUid}`), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                responseData && setInvoice(responseData.value[0]);
                setLoading(false);
            });
    };

    const extentionsHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem('Key', 'key', new DataGridHeaderOptions(false, true, true)),
        new DataGridHeaderItem('Value', 'value', new DataGridHeaderOptions(false, true, true))
    ];

    const lineItemsHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem(
            'Item / description',
            'description',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem(
            'Quantity',
            'quantity',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem('Unit Price', 'unitAmount', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any) => (
                <p style={{ textAlign: 'right' }}>
                    {formatNumber(value === null ? 0 : value || 0, 2)}
                </p>
            )
        }),
        new DataGridHeaderItem('Tax', 'taxAmount', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any) => (
                <p style={{ textAlign: 'right' }}>
                    {formatNumber(value === null ? 0 : value || 0, 2)}
                </p>
            )
        }),
        new DataGridHeaderItem(`Amount (${invoice?.currency || ''})`, 'lineAmount', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any) => (
                <p style={{ textAlign: 'right' }}>
                    {formatNumber(value === null ? 0 : value || 0, 2)}
                </p>
            )
        })
    ];

    const renderInvoiceSummary: () => JSX.Element = () => (
        <InvoiceDetailInformationRowContainer>
            <InvoiceDetailInformationRow>
                <InvoiceDetailInformationLeft>
                    <InvoiceDetailInformationLabel>Issue Date:</InvoiceDetailInformationLabel>
                    <InvoiceDetailInformationValue>
                        {formatDateTime(invoice?.issueDate || '', 'dd MMM yyyy')}
                    </InvoiceDetailInformationValue>
                </InvoiceDetailInformationLeft>
                <InvoiceDetailInformationRight>
                    <InvoiceDetailInformationLabel>Due Date:</InvoiceDetailInformationLabel>
                    <InvoiceDetailInformationValue>
                        {formatDateTime(invoice?.dueDate || '', 'dd MMM yyyy')}
                    </InvoiceDetailInformationValue>
                </InvoiceDetailInformationRight>
            </InvoiceDetailInformationRow>
            <InvoiceDetailInformationRow>
                <InvoiceDetailInformationLeft>
                    <InvoiceDetailInformationLabel>Status:</InvoiceDetailInformationLabel>
                    <InvoiceDetailInformationValue>
                        {invoice?.status || ''}
                    </InvoiceDetailInformationValue>
                </InvoiceDetailInformationLeft>
                <InvoiceDetailInformationRight>
                    <InvoiceDetailInformationLabel>Reference:</InvoiceDetailInformationLabel>
                    <InvoiceDetailInformationValue>
                        {invoice?.reference || '-'}
                    </InvoiceDetailInformationValue>
                </InvoiceDetailInformationRight>
            </InvoiceDetailInformationRow>

            {invoice?.closeDate && (
                <InvoiceDetailInformationRow>
                    <InvoiceDetailInformationLeft>
                        <InvoiceDetailInformationLabel>Close Date:</InvoiceDetailInformationLabel>
                        <InvoiceDetailInformationValue>
                            {formatDateTime(invoice?.closeDate || '', 'dd MMM yyyy') || '-'}
                        </InvoiceDetailInformationValue>
                    </InvoiceDetailInformationLeft>
                </InvoiceDetailInformationRow>
            )}
        </InvoiceDetailInformationRowContainer>
    );

    const renderParticipantInfo: (
        title: string,
        participant: InvoiceOwner | InvoiceSupplier | InvoiceBuyer | null | undefined
    ) => JSX.Element = (title, participant) => (
        <InvoiceDetailParticipantsInformation>
            <InvoiceDetailInformationTitle>{title}</InvoiceDetailInformationTitle>
            {participant && (
                <InvoiceDetailInformationLabel>
                    {participant?.name || ''}
                </InvoiceDetailInformationLabel>
            )}
            {participant && participant?.legalAddress && renderAddress(participant.legalAddress)}
        </InvoiceDetailParticipantsInformation>
    );

    const renderAddress: (address: InvoiceAddress) => JSX.Element = (address) => (
        <InvoiceDetailInformationAddress>
            {[address.line1, address.line2, address.line3, address.line4]
                .filter(Boolean)
                .map((line, index) => (
                    <InvoiceDetailInformationLabel key={index}>
                        {line}
                        {index < 3 && ','}
                    </InvoiceDetailInformationLabel>
                ))}
            <InvoiceDetailInformationLabel>
                {`${address.townCity || ''} ${address.region || ''} ${address.postcode || ''} ${
                    address.country || ''
                }`}
            </InvoiceDetailInformationLabel>
        </InvoiceDetailInformationAddress>
    );

    const renderExtentionsView: () => JSX.Element = () => (
        <InvoiceDetailInformationRowContainer>
            <InvoiceDetailExtensionsWrapper>
                <InvoiceDetailInformationTitle>Extensions</InvoiceDetailInformationTitle>
                <InvoiceDetailExtensionsContainer>
                    <InvoiceDetailExtensionsHeader>
                        <InvoiceDetailExtensionsHeaderTitle>KEY</InvoiceDetailExtensionsHeaderTitle>
                        <InvoiceDetailExtensionsHeaderTitle>
                            VALUE
                        </InvoiceDetailExtensionsHeaderTitle>
                    </InvoiceDetailExtensionsHeader>

                    {invoice?.extensions.map((extension: InvoiceExtensions) => (
                        <>
                            <InvoiceDetailExtensionsData>
                                <InvoiceDetailExtensionsValue>
                                    {extension.key}
                                </InvoiceDetailExtensionsValue>
                                <InvoiceDetailExtensionsValue>
                                    {extension.value}
                                </InvoiceDetailExtensionsValue>
                            </InvoiceDetailExtensionsData>
                            <Divider />
                        </>
                    ))}
                </InvoiceDetailExtensionsContainer>
            </InvoiceDetailExtensionsWrapper>
        </InvoiceDetailInformationRowContainer>
    );

    const calculationAmountBeforeTax: (unitPrice: number, quantity: number) => number = (
        unitPrice,
        quantity
    ) => {
        return unitPrice * quantity;
    };

    const subtotalAmount: number =
        invoice && invoice?.lineItems
            ? invoice?.lineItems.reduce(
                  (accum, item: InvoiceLineItems) =>
                      accum + calculationAmountBeforeTax(item.unitAmount || 0, item.quantity || 0),
                  0
              )
            : 0;

    return (
        <InvoiceDetailContainer>
            {loading ? (
                <LoaderFullPage />
            ) : invoice ? (
                <>
                    <InvoiceDetailBackContainer
                        onClick={() => navigate(`/invoices/${invoice?.customerUid}`)}
                    >
                        <ArrowBackIcon /> Back
                    </InvoiceDetailBackContainer>
                    <InvoiceDetailInformationWrapper>
                        <InvoiceDetailHeader>
                            <InvoiceDetailTitle>
                                {invoice?.type === 'AR' && `INVOICE`}
                                {invoice?.type === 'AP' && `BILL`}
                            </InvoiceDetailTitle>
                            <InvoiceDetailHeaderInformationWrapper>
                                <InvoiceDetailHeaderInformationContainer>
                                    <InvoiceDetailHeaderInformationTitle>
                                        INVOICE NUMBER
                                    </InvoiceDetailHeaderInformationTitle>
                                    <InvoiceDetailHeaderInformationValue>
                                        #{invoice?.invoiceNumber}
                                    </InvoiceDetailHeaderInformationValue>
                                </InvoiceDetailHeaderInformationContainer>
                                <InvoiceDetailHeaderInformationContainer>
                                    <InvoiceDetailTotalAmountTitle>
                                        TOTAL
                                    </InvoiceDetailTotalAmountTitle>
                                    <InvoiceDetailTotalAmountValue>
                                        {CurrencySymbolsLookUp[invoice?.currency || '']}
                                        {formatNumber(invoice?.amount || 0, 2)}
                                    </InvoiceDetailTotalAmountValue>
                                    <InvoiceDetailTotalTaxAmount>
                                        Incl. {CurrencySymbolsLookUp[invoice?.currency || '']}
                                        {formatNumber(invoice?.tax || 0, 2)} tax
                                    </InvoiceDetailTotalTaxAmount>
                                </InvoiceDetailHeaderInformationContainer>
                            </InvoiceDetailHeaderInformationWrapper>
                        </InvoiceDetailHeader>
                        {renderInvoiceSummary()}

                        <InvoiceDetailInformationRowContainer>
                            <InvoiceDetailInformationRow>
                                {invoice?.supplier
                                    ? renderParticipantInfo('From', invoice?.supplier)
                                    : renderParticipantInfo('From', invoice?.owner)}

                                {invoice?.type === 'AR' &&
                                    renderParticipantInfo('To', invoice?.buyer)}
                                {invoice?.type === 'AP' &&
                                    renderParticipantInfo('To', invoice?.owner)}
                            </InvoiceDetailInformationRow>
                        </InvoiceDetailInformationRowContainer>

                        {invoice?.lineItems.length > 0 && (
                            <InvoiceDetailInformationRowContainer>
                                <DataGrid
                                    headers={lineItemsHeaders}
                                    data={invoice?.lineItems}
                                    ariaLabel="LineItems table"
                                    ariaCaption="A table that shows lineItems."
                                    fixedHeader
                                    totalRows={invoice?.lineItems.length}
                                    rowsPerPage={25}
                                    showFooter={invoice?.lineItems.length > 25 ? true : false}
                                />
                                <InvoiceDetailLineItemsTotalWrapper>
                                    <InvoiceDetailLineItemsTotalConatiner>
                                        <InvoiceDetailInformationLeft>
                                            <InvoiceDetailInformationLabel>
                                                Subtotal
                                            </InvoiceDetailInformationLabel>
                                            <InvoiceDetailInformationLabel>
                                                {formatNumber(subtotalAmount || 0, 2)}
                                            </InvoiceDetailInformationLabel>
                                        </InvoiceDetailInformationLeft>
                                        <InvoiceDetailInformationLeft>
                                            <InvoiceDetailInformationLabel>
                                                Total Tax
                                            </InvoiceDetailInformationLabel>
                                            <InvoiceDetailInformationLabel>
                                                {formatNumber(invoice?.tax || 0, 2)}
                                            </InvoiceDetailInformationLabel>
                                        </InvoiceDetailInformationLeft>
                                        <Divider />
                                        <InvoiceDetailInformationLeft>
                                            <InvoiceDetailInformationLabel>
                                                Total ({invoice?.currency})
                                            </InvoiceDetailInformationLabel>
                                            <InvoiceDetailInformationLabel>
                                                {formatNumber(invoice?.amount || 0, 2)}
                                            </InvoiceDetailInformationLabel>
                                        </InvoiceDetailInformationLeft>
                                    </InvoiceDetailLineItemsTotalConatiner>
                                </InvoiceDetailLineItemsTotalWrapper>
                            </InvoiceDetailInformationRowContainer>
                        )}
                        <Divider />
                        <InvoiceDetailInformationRowContainer>
                            <InvoiceDetailInformationRow>
                                <InvoiceDetailInformationLeft>
                                    <InvoiceDetailInformationLabel>
                                        Adjustments​:
                                    </InvoiceDetailInformationLabel>
                                    <InvoiceDetailInformationValue>
                                        {invoice?.adjustments || '-'}
                                    </InvoiceDetailInformationValue>
                                </InvoiceDetailInformationLeft>
                                <InvoiceDetailInformationRight>
                                    <InvoiceDetailInformationLabel>
                                        Source:
                                    </InvoiceDetailInformationLabel>
                                    <InvoiceDetailInformationValue>
                                        {invoice?.source || ''}
                                    </InvoiceDetailInformationValue>
                                </InvoiceDetailInformationRight>
                            </InvoiceDetailInformationRow>
                            <InvoiceDetailInformationRow>
                                <InvoiceDetailInformationLeft>
                                    <InvoiceDetailInformationLabel>
                                        Amount Paid:
                                    </InvoiceDetailInformationLabel>
                                    <InvoiceDetailInformationValue>
                                        {formatNumber(invoice?.amountPaid || 0, 2)}
                                    </InvoiceDetailInformationValue>
                                </InvoiceDetailInformationLeft>
                                <InvoiceDetailInformationRight>
                                    <InvoiceDetailInformationLabel>
                                        Source Date:
                                    </InvoiceDetailInformationLabel>
                                    <InvoiceDetailInformationValue>
                                        {formatDateTime(
                                            invoice?.lastModifiedErpDate || '',
                                            'dd MMM yyyy'
                                        )}
                                    </InvoiceDetailInformationValue>
                                </InvoiceDetailInformationRight>
                            </InvoiceDetailInformationRow>
                            <InvoiceDetailInformationRow>
                                <InvoiceDetailInformationLeft>
                                    <InvoiceDetailInformationLabel>
                                        Balance Due:
                                    </InvoiceDetailInformationLabel>
                                    <InvoiceDetailInformationValue>
                                        {formatNumber(invoice?.balanceDue || 0, 2)}
                                    </InvoiceDetailInformationValue>
                                </InvoiceDetailInformationLeft>
                            </InvoiceDetailInformationRow>
                        </InvoiceDetailInformationRowContainer>
                        {invoice?.extensions &&
                            invoice?.extensions.length > 0 &&
                            renderExtentionsView()}
                    </InvoiceDetailInformationWrapper>
                </>
            ) : (
                <InvoiceDetailInformationWrapper>no data</InvoiceDetailInformationWrapper>
            )}
        </InvoiceDetailContainer>
    );
};

export default InvoiceDetailView;
