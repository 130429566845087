import logo from 'assets/logos/g2f_logo.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FC, useState, useContext } from 'react';
import Context from 'context';
import {
    NavigationLogoContainer,
    NavItemButtonContainer,
    NavItemsContainer,
    NavigationButtonContainer,
    NavigationButtonText,
    NavigationContainer,
    NavigationLinkContainer,
    NavigationProfileContainer,
    NavSubmenuItemsContainer,
    NavSubmenuItemButtonContainer,
    NavigationRightContainer,
    NavItem
} from './styled';

import { PrimaryButton } from 'components/common/buttons';
import FullViewDialog from 'components/dialog/FullViewDialog';
import Profile from 'components/profile';
import { navItems } from 'lib/navigationData';
import CustomerInvitationView from 'views/CustomerInvitationView';
import G2FLogo from 'assets/icons/G2FLogo';
import MobileNav from './mobileNav';

const Nav: FC = () => {
    const navigate = useNavigate();
    const [state] = useContext(Context);
    const [inviteCustomerDialogOpen, setInviteCustomerDialogOpen] = useState<boolean>(false);
    const stepIntegrations: boolean = state?.app?.stepIntegrations || false;
    const toggleInviteClickHandler: () => void = () => {
        setInviteCustomerDialogOpen(!inviteCustomerDialogOpen);
    };
    const toggleInviteCloseHandler: () => void = () => {
        setInviteCustomerDialogOpen(!inviteCustomerDialogOpen);
        window.location.reload();
    };

    return (
        <NavigationContainer>
            <NavigationLogoContainer onClick={() => navigate('/')}>
                <G2FLogo />
            </NavigationLogoContainer>
            <NavigationRightContainer>
                <NavigationLinkContainer>
                    <NavItemsContainer>
                        {navItems.map((item, index) =>
                            window.location.pathname !== item.path ? (
                                <NavItemButtonContainer
                                    key={`${item.text}-${index}`}
                                    {...(item.submenu && item.submenu?.length > 0
                                        ? {}
                                        : {
                                              onClick: () => {
                                                  item.externalPath
                                                      ? window.open(item.externalPath, '_BLANK')
                                                      : navigate(`${item.path}`);
                                              }
                                          })}
                                >
                                    {!window.location.pathname.includes('register') &&
                                        !window.location.pathname.includes('payment') &&
                                        !window.location.pathname.includes('signup') && (
                                            <>
                                                {item.text}

                                                {item.submenu && item.submenu.length > 0 && (
                                                    <NavSubmenuItemsContainer>
                                                        {item.submenu.map((subItem, i) => (
                                                            <NavSubmenuItemButtonContainer
                                                                key={`${subItem.text}-${i}`}
                                                                onClick={() =>
                                                                    subItem.externalPath
                                                                        ? window.open(
                                                                              subItem.externalPath,
                                                                              '_BLANK'
                                                                          )
                                                                        : navigate(
                                                                              `${subItem.path}`
                                                                          )
                                                                }
                                                            >
                                                                {subItem.text}
                                                            </NavSubmenuItemButtonContainer>
                                                        ))}
                                                    </NavSubmenuItemsContainer>
                                                )}
                                            </>
                                        )}
                                </NavItemButtonContainer>
                            ) : (
                                <NavItem key={`${item.text}-${index}`}>{item.text}</NavItem>
                            )
                        )}
                    </NavItemsContainer>
                    {window.location.pathname !== '/' &&
                        !window.location.pathname.includes('register') &&
                        !window.location.pathname.includes('payment') &&
                        !window.location.pathname.includes('signup') && (
                            <NavigationButtonContainer>
                                <PrimaryButton
                                    clickHandler={toggleInviteClickHandler}
                                    disabled={!stepIntegrations}
                                >
                                    <NavigationButtonText>Invite Customer​</NavigationButtonText>
                                </PrimaryButton>
                            </NavigationButtonContainer>
                        )}

                    <FullViewDialog
                        open={inviteCustomerDialogOpen}
                        clickHandler={toggleInviteClickHandler}
                        dialogContent={
                            <CustomerInvitationView closeHandler={toggleInviteCloseHandler} />
                        }
                    />
                </NavigationLinkContainer>
                {!window.location.pathname.includes('register') &&
                    !window.location.pathname.includes('payment') &&
                    !window.location.pathname.includes('signup') && (
                        <NavigationProfileContainer>
                            <Profile />
                        </NavigationProfileContainer>
                    )}
            </NavigationRightContainer>
            <MobileNav />
        </NavigationContainer>
    );
};

export default Nav;
