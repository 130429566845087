import { AuthenticationResult, EventType, InteractionType } from '@azure/msal-browser';
import {
    MsalAuthenticationTemplate,
    useMsalAuthentication,
    useMsal, useIsAuthenticated
} from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { authResult } from 'lib/authConfig';
import { MSALProps } from 'App';
import { apiConfig, get } from 'api/api';
import ErrorFullPage from 'components/ErrorFullPage';
import LoaderFullPage from 'components/LoaderFullPage';
import { _B2C_API_PATH } from 'config/constants';
import Context from 'context';
import { getConnectors, setAccessToken, updateIntroSteps } from 'context/action/app';
import { getCustomers } from 'context/action/customers';
import { b2cPolicies } from 'lib/authConfig';
import { signUpSignInFlowRequest } from 'lib/msalRequests';
import { FC, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { AppLoaderContainer } from 'styled';
import { compareIssuingPolicy } from 'utils/compareIssuingPolicy';
import { useNavigate } from 'react-router-dom';
import IntroDialog from 'components/introDialog';
import { ConnectorTypes } from 'utils/interfaces/IntegrationItem';
import { triggerSnackbar } from 'context/action/app';
import { SnackBarBody, SnackBarContainer, SnackBarTitle } from 'components/snackbar/styled';

const ErrorComponent: () => JSX.Element = () => <ErrorFullPage detail="An Error Occurred" />;

const LoadingComponent: () => JSX.Element = () => (
    <AppLoaderContainer>
        <LoaderFullPage detail="Authentication in progress..." />;
    </AppLoaderContainer>
);

const SecureAppWrapper: FC<MSALProps> = ({ msalInstance }) => {
    useMsalAuthentication(InteractionType.Redirect);

    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [state, dispatch] = useContext(Context);
    const [stepStatus, setStepStatus] = useState<boolean>(false);
    const [connectorTypeData, setConnectorTypeData] = useState<ConnectorTypes[]>([]);
    const [ connection, setConnection ] = useState<HubConnection>();
    const navigate = useNavigate();

    const stepIntegrations = state?.app?.stepIntegrations;
    const stepInvitations = state?.app?.stepInvitations;

    useEffect(() => {
        async function connect() {
          const newConnection = new HubConnectionBuilder()
              .withUrl(`${window.spaSettings.partnerDomain}/hubs/notifications`, { accessTokenFactory: async () => await authResult(msalInstance as PublicClientApplication, isAuthenticated, inProgress) })
              .withAutomaticReconnect()
              .build();
      
            setConnection(newConnection);
        }
        connect();
      }, []);

      useEffect(() => {
          if (connection) {
              connection.start()
                  .then(_ => {
                      console.log('Connected!');
      
                      connection.on('ReceiveMessage', (message, body, status, type) => {
                        
                        console.log('Received message!', message, body, status, type);
                        let role = "info";
                        if (status.toUpperCase() == "ERROR")
                        {
                            role = "error";
                        }
                        if (status.toUpperCase() == "SUCCESS")
                        {
                            role = "success";
                        }
                        triggerSnackbar(dispatch, {
                            open: true,
                            data: {
                                message: (
                                    <SnackBarContainer>
                                        <SnackBarTitle>
                                            {message}
                                        </SnackBarTitle>
                                        <SnackBarBody>
                                            {body}
                                        </SnackBarBody>
                                    </SnackBarContainer>
                                ),
                                role: role
                            }
                        });
                      });
                  })
                  .catch(e => console.log('Connection failed: ', e));
          }
      }, [connection]);

    useEffect(() => {
        const callbackId = msalInstance.addEventCallback((event: any) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS ||
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                // if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                //     // retrieve the account from initial sing-in to the app
                //     const originalSignInAccount = instance
                //         .getAllAccounts()
                //         .find(
                //             (account) =>
                //                 account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                //                 account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                //                 compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
                //         );
                //     let signUpSignInFlowRequest = {
                //         authority: b2cPolicies.authorities.signUpSignIn.authority,
                //         account: originalSignInAccount,
                //     };
                //     // silently login again with the signUpSignIn policy
                //     instance.ssoSilent(signUpSignInFlowRequest);
                // }
                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (
                    compareIssuingPolicy(
                        event.payload.idTokenClaims,
                        b2cPolicies.names.forgotPassword
                    )
                ) {
                    msalInstance.loginRedirect(signUpSignInFlowRequest);
                }
                else
                {
                    checkIntroStepsStatus();
                    getConnectorTypeResult();
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    navigate('/reset-password');
                }
            }
        });

        return () => {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [msalInstance]);

    const checkIntroStepsStatus: () => Promise<void> = async () => {
        const token = await authResult(msalInstance as PublicClientApplication, isAuthenticated, inProgress);
        const dataIntegrations: number =
            (await get(apiConfig.getConnectors(), token).then((response: any) => response.json()))
                ?.value?.length || 0;

        const dataInvitations: number = await get(apiConfig.customerInvites(`?$count=true`), token)
            .then((response: any) => response.json())
            .then((responseData: any) => responseData['@odata.count']);

        updateIntroSteps(dispatch, {
            stepIntegrations: dataIntegrations > 0,
            stepInvitations: dataInvitations > 0
        });
        setStepStatus(true);
    };

    const getConnectorTypeResult: () => void = async () => {
        const token = await authResult(msalInstance as PublicClientApplication, isAuthenticated, inProgress);
        await get(apiConfig.getConnectorType(''), token)
            .then((response: any) => response.json())
            .then((responseData: any) => setConnectorTypeData(responseData.value));
    };

    useEffect(() => {
        getConnectors(dispatch, connectorTypeData);
    }, [connectorTypeData]);

    useEffect(() => {
        (!stepIntegrations || !stepInvitations) && checkIntroStepsStatus();
    }, [stepIntegrations, stepInvitations]);

    const authRequest = {
        scopes: ['openid', 'profile']
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            {stepStatus && <IntroDialog />}
            <Outlet />
        </MsalAuthenticationTemplate>
    );
};

export default SecureAppWrapper;
