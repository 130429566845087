import { FC, MouseEvent, useState } from 'react';
import { CustomerMenuContainer } from './styled';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router';

export interface CustomerMenuProps {
    customerUid: string;
    status: string;
    clickHandler: (id: string) => void;
}

const CustomerMenu: FC<CustomerMenuProps> = ({ customerUid, status, clickHandler }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick: (event: MouseEvent<HTMLElement>) => void = (event) =>
        setAnchorEl(event.currentTarget);

    const handleClose: () => void = () => {
        setAnchorEl(null);
    };

    const rowClickHandler: (url: string) => void = (url) => {
        customerUid && navigate(`/${url}/${customerUid}`);
        handleClose();
    };

    return (
        <CustomerMenuContainer>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="customer-item-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => rowClickHandler('invoices')}>Invoices / Bills</MenuItem>
                <MenuItem onClick={() => rowClickHandler('customers')}>Customer Details</MenuItem>
                <MenuItem onClick={() => rowClickHandler('analytics')}>Analytics</MenuItem>
                {status === 'Requires Reconnection' && (
                    <MenuItem onClick={() => clickHandler(customerUid)}>
                        Send Re-authenticate Email
                    </MenuItem>
                )}
            </Menu>
        </CustomerMenuContainer>
    );
};

export default CustomerMenu;
