import loader from 'assets/lottie-animations/finecta_loader.json';
import Typography from 'components/typography';
import Lottie from 'lottie-react';
import { FC } from 'react';
import { FullPageLoaderContainer } from './styled';

interface LoaderFullPageProps {
    detail?: string;
}

const LoaderFullPage: FC<LoaderFullPageProps> = ({ detail }) => (
    <FullPageLoaderContainer>
        <Typography tag="h2">Just a moment</Typography>
        <Typography tag="subheading1">{detail || `We’re preparing your information`}</Typography>
        <Lottie
            style={{
                height: '30vh',
                transform: 'translateY(-140px)',
                marginTop: '60px'
            }}
            loop
            animationData={loader}
            data-testid="loader-full-page"
        />
    </FullPageLoaderContainer>
);

export default LoaderFullPage;
