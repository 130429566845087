/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useRef, useState } from 'react';
import {
    AnalyticsChartExpensesTitle,
    AnalyticsChartExpensesContainer,
    AnalyticsChartExpensesWrapper,
    AnalyticsChartExpensesDetailsContainer,
    AnalyticsChartExpensesDetailBoxContainer,
    AnalyticsChartExpensesDetailBox,
    AnalyticsChartExpensesDetailTitle,
    AnalyticsChartExpensesDetailsWrapper,
    AnalyticsChartExpensesChartContainer,
    AnalyticsChartExpensesDetailsOneColumnContainer,
    AnalyticsChartExpensesContainerSubtitle,
    AnalyticsChartExpensesRowContainer,
    AnalyticsChartExpensesHeader,
    TooltipWrapper,
    TooltipTitle,
    TooltipDescription
} from './styled';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    convertNumberToShortString,
    formatNumberToShortString
} from 'lib/helpers/formatters/convertNumberToShortString';
import { themeColors } from 'assets/theme/style';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import { IAnalyticsExpensesData } from 'utils/interfaces/analytics.interface';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import IconTooltip from 'components/tooltip/IconTooltip';

interface IExpensesViewwProps {
    expensesData: IAnalyticsExpensesData | null;
}
const ExpensesView: FC<IExpensesViewwProps> = ({ expensesData }) => {
    const [data, setData] = useState<IAnalyticsExpensesData | null>(null);
    const [barChartLabels, setBarChartLabels] = useState<number[]>([]);
    const [barChartData, setBarChartData] = useState<number[]>([]);

    ChartJS.register(
        CategoryScale,
        ChartDataLabels,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    useEffect(() => {
        if (expensesData) {
            setData(expensesData);
            if (expensesData.lastThreeYears && expensesData.lastThreeYears.length > 0) {
                setBarChartLabels(expensesData.lastThreeYears.map((item) => item.year));
                setBarChartData(
                    expensesData.lastThreeYears.map((item) => parseInt(item.value, 10))
                );
            }
        }
    }, [expensesData]);

    const chartData = {
        labels: data?.lastThreeYears ? data?.lastThreeYears.map((item) => item.year) : [],
        datasets: [
            {
                labels: barChartLabels,
                data: barChartData,
                backgroundColor: ['#48ADDC', '#738AC8', '#3FB39F']
            }
        ]
    };

    const options = {
        type: 'bar',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                stacked: false,
                grid: {
                    drawBorder: false,
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: 35000,
                ticks: {
                    labelOffset: 4,
                    maxTicksLimit: 6,
                    align: 'start' as const,
                    z: 1,
                    min: 0,
                    stepSize: 100,
                    callback: (value: any) => {
                        return value === 0 ? value : `£${formatNumberToShortString(value || 0)}`;
                    }
                }
            }
        },
        plugins: {
            datalabels: {
                anchor: 'center' as const,
                color: themeColors.body,
                labels: {
                    title: {
                        font: {
                            weight: 'bold' as const
                        }
                    }
                },
                formatter: (value: any) =>
                    value > 0 ? `£${formatNumberToShortString(value || 0)}` : ''
            },
            legend: {
                display: false
            },
            title: {
                text: '',
                display: false,
                align: 'start' as any
            }
        }
    };

    return (
        <AnalyticsChartExpensesWrapper>
            <AnalyticsChartExpensesHeader>
                <AnalyticsChartExpensesTitle>EXPENSES</AnalyticsChartExpensesTitle>
                <IconTooltip
                    tooltipText={
                        <TooltipWrapper>
                            {/* <TooltipTitle>Title</TooltipTitle> */}
                            <TooltipDescription>
                                We have summarised the main data points from the payable invoices.
                                We show the sum total amounts of all payables for the last three
                                calendar years and then details from the last 12 months. The average
                                invoice value is over all time.
                            </TooltipDescription>
                        </TooltipWrapper>
                    }
                />
            </AnalyticsChartExpensesHeader>
            <AnalyticsChartExpensesContainer>
                <AnalyticsChartExpensesRowContainer>
                    <AnalyticsChartExpensesChartContainer>
                        <Bar title="" options={options} data={chartData} width="100%" />
                    </AnalyticsChartExpensesChartContainer>
                    <AnalyticsChartExpensesDetailsWrapper>
                        <AnalyticsChartExpensesContainerSubtitle>
                            Last 12 months
                        </AnalyticsChartExpensesContainerSubtitle>
                        <AnalyticsChartExpensesDetailsContainer>
                            <AnalyticsChartExpensesDetailBoxContainer>
                                <AnalyticsChartExpensesDetailBox>
                                    {Math.floor(data?.growth || 0).toLocaleString()}
                                </AnalyticsChartExpensesDetailBox>
                                <AnalyticsChartExpensesDetailTitle>
                                    % Growth
                                </AnalyticsChartExpensesDetailTitle>
                            </AnalyticsChartExpensesDetailBoxContainer>
                            <AnalyticsChartExpensesDetailBoxContainer>
                                <AnalyticsChartExpensesDetailBox>
                                    £{formatNumber(data?.growthLastTwelveMonthsValue || 0, 2)}
                                </AnalyticsChartExpensesDetailBox>
                                <AnalyticsChartExpensesDetailTitle>
                                    Growth
                                </AnalyticsChartExpensesDetailTitle>
                            </AnalyticsChartExpensesDetailBoxContainer>
                        </AnalyticsChartExpensesDetailsContainer>
                        <AnalyticsChartExpensesDetailsContainer>
                            <AnalyticsChartExpensesDetailBoxContainer>
                                <AnalyticsChartExpensesDetailBox>
                                    {data?.lastTwelveMonthsVolume || '0'}
                                </AnalyticsChartExpensesDetailBox>
                                <AnalyticsChartExpensesDetailTitle>
                                    Num. Invoices​
                                </AnalyticsChartExpensesDetailTitle>
                            </AnalyticsChartExpensesDetailBoxContainer>
                            <AnalyticsChartExpensesDetailBoxContainer>
                                <AnalyticsChartExpensesDetailBox>
                                    £{formatNumber(data?.lastTwelveMonthsValue || 0, 2)}
                                </AnalyticsChartExpensesDetailBox>
                                <AnalyticsChartExpensesDetailTitle>
                                    Value
                                </AnalyticsChartExpensesDetailTitle>
                            </AnalyticsChartExpensesDetailBoxContainer>
                        </AnalyticsChartExpensesDetailsContainer>
                        <AnalyticsChartExpensesContainerSubtitle>
                            Yearly Averages
                        </AnalyticsChartExpensesContainerSubtitle>
                        <AnalyticsChartExpensesDetailsContainer>
                            <AnalyticsChartExpensesDetailBoxContainer>
                                <AnalyticsChartExpensesDetailBox>
                                    {data?.averageYearlyVolume || '0'}
                                </AnalyticsChartExpensesDetailBox>
                                <AnalyticsChartExpensesDetailTitle>
                                    Invoices
                                </AnalyticsChartExpensesDetailTitle>
                            </AnalyticsChartExpensesDetailBoxContainer>
                            <AnalyticsChartExpensesDetailBoxContainer>
                                <AnalyticsChartExpensesDetailBox>
                                    £{formatNumber(data?.averageYearlyValue || 0, 2)}
                                </AnalyticsChartExpensesDetailBox>
                                <AnalyticsChartExpensesDetailTitle>
                                    Invoiced Amount
                                </AnalyticsChartExpensesDetailTitle>
                            </AnalyticsChartExpensesDetailBoxContainer>
                        </AnalyticsChartExpensesDetailsContainer>
                        <AnalyticsChartExpensesContainerSubtitle>
                            Average Invoice Value
                        </AnalyticsChartExpensesContainerSubtitle>
                        <AnalyticsChartExpensesDetailsContainer>
                            <AnalyticsChartExpensesDetailBoxContainer>
                                <AnalyticsChartExpensesDetailBox>
                                    £{formatNumber(data?.averageValue || 0, 2)}
                                </AnalyticsChartExpensesDetailBox>
                            </AnalyticsChartExpensesDetailBoxContainer>
                        </AnalyticsChartExpensesDetailsContainer>
                    </AnalyticsChartExpensesDetailsWrapper>
                </AnalyticsChartExpensesRowContainer>
            </AnalyticsChartExpensesContainer>
        </AnalyticsChartExpensesWrapper>
    );
};

export default ExpensesView;
