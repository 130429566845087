import { themeColors, device, boxShadow } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const NavigationContainer = styled.div`
    display: flex;
    padding: 16px 32px;
    justify-content: space-between;
    align-items: center;
    background-color: ${themeColors.white};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 6px;
    min-height: 58px;
    @media ${device.mobileOnly} {
      padding:16px;
    }
`;

export const NavigationProfileContainer = styled.div`
    display: flex;
    @media ${device.mobileOnly} {
      display: none;
    }
`;

export const NavigationLogoContainer = styled.div`
    display: flex;
    cursor: pointer;
`;

export const NavigationBottomContainer = styled.div`
    display: flex;
    border-bottom: 2px solid ${themeColors.primary};
    align-items: center;
    padding-bottom: 8px;
    @media ${device.mobileOnly} {
      display: none;
    }
`;

export const NavigationLinkContainer = styled.nav`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2px;
`;

export const NavItemsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding-left: 32px;
  transition-duration: 0.5s;

  li:hover > ul,
  li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  
`;

export const NavSubmenuItemsContainer = styled.ul`
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  padding-top: 166px;
  left: 0;
  display: none;
  z-index: 999;
`;

export const NavSubmenuItemButtonContainer = styled.li`
  visibility: visible;
  opacity: 1;
  display: flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.regular};
  background-color: ${themeColors.white};
  z-index: 999;
  height: 30px;
  width: 100%;
  padding: 10px 20px;
  font-weight: normal;
  box-shadow: ${boxShadow};
  margin-bottom: 4px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  

  &:hover {
    visibility: visible;
    opacity: 1;
    display: flex;
    background-color: ${themeColors.lightGrey};
  }
`;

export const NavItemButtonContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: ${fontSizes.medium};
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
  cursor: pointer;
  height: 40px;
  border-radius: 8px;
  padding: 0 12px;

  &:hover {
    background-color: ${themeColors.lightGrey};
  }
`;

export const NavItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: ${fontSizes.medium};
  border-radius: 8px;
  padding: 10px 12px;
  background-color: ${themeColors.lightGrey};
`;



export const NavigationButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 4px 0 20px;
`;

export const NavigationButtonText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const NavigationRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${device.mobileOnly} {
      display: none;
  }
`;
