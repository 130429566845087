/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { MSALProps } from 'App';
import { apiConfig, get, post } from 'api/api';
import ApiIcon from 'assets/icons/ApiIcon';
import DocumentationIcon from 'assets/icons/DocumentationIcon';
import { themeColors } from 'assets/theme/style';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import LoaderFullPage from 'components/LoaderFullPage';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { PrimaryButton } from 'components/common/buttons';
import BasicDialog from 'components/dialog/BasicDialog';
import FullViewDialog from 'components/dialog/FullViewDialog';
import Context from 'context';
import { authResult } from 'lib/authConfig';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import { FC, useContext, useEffect, useRef, useState, MouseEvent } from 'react';
import { Bar, Doughnut, getDatasetAtEvent } from 'react-chartjs-2';
import { useNavigate } from 'react-router';
import {
    ICustomerSyncData,
    ICustomersData,
    ICustomerSyncSupplementedData
} from 'utils/interfaces/customers.interface';
import CustomerInvitationView from 'views/CustomerInvitationView';
import IntroDashboardView from 'views/IntroDashboardView';
import {
    BarContainer,
    DashboardApiKeysCard,
    DashboardBottomContainer,
    DashboardCardButtonContainer,
    DashboardCardButtonText,
    DashboardCardContainer,
    DashboardCardContent,
    DashboardCardContentWrapper,
    DashboardCardRowText,
    DashboardCardTextBold,
    DashboardCardTitle,
    DashboardCardsContainer,
    DashboardCardsText,
    DashboardContainer,
    DashboardCustomerCardContainer,
    DashboardDocumentationCard,
    DashboardInvitationsCardContainer,
    DashboardInvitationsContainer,
    DashboardInvitationsOptionContainer,
    DashboardInvitationsOptionIcon,
    DashboardInvitationsOptionSent,
    DashboardInvitationsOptionText,
    DashboardWrapper,
    DoughnutContainer
} from './styled';
import NoSubscriptionView from 'views/NoSubscriptionView';
import { DateTime } from 'luxon';
import { updateIntroSteps } from 'context/action/app';
import Typography from 'components/typography';

const Dashboard: FC<MSALProps> = () => {
    const chartRef = useRef();
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [state, dispatch] = useContext(Context);
    const [customerCount, setCustomerCount] = useState<number>(0);
    const [customerConnections, setCustomerConnections] = useState<number>(0);
    const [customerActiveConnections, setCustomerActiveConnections] = useState<number>(0);
    const [customerInactiveConnections, setCustomerInactiveConnections] = useState<number>(0);
    const [customerReconnections, setCustomerReconnections] = useState<number>(0);
    const [customerInvites, setCustomerInvites] = useState<number>(0);
    const [customerInvitesPending, setCustomerInvitesPending] = useState<number>(0);
    const [customerInvitesRedeemed, setCustomerInvitesRedeemed] = useState<number>(0);
    const [customerInvitesRevoked, setCustomerInvitesRevoked] = useState<number>(0);
    const [renders, setRenders] = useState<number>(0);
    const [inviteCustomerDialogOpen, setInviteCustomerDialogOpen] = useState<boolean>(false);
    const [introDashboardDialogOpen, setIntroDashboardDialogOpen] = useState<boolean>(false);
    const [lastImportsData, setLastImportsData] = useState<ICustomerSyncSupplementedData[]>([]);
    const [lastImportsLoading, setLastImportsLoading] = useState<boolean>(true);
    const [customerCountCheck, setCustomerCountCheck] = useState<boolean>(false);
    const [customerInvitesCheck, setCustomerInvitesCheck] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasNoSubscription, setHasNoSubscription] = useState<boolean>(false);
    const [hasNoSubscriptionDialogOpen, setHasNoSubscriptionDialogOpen] = useState<boolean>(false);

    const stepIntegrations: boolean = state?.app?.stepIntegrations || false;

    ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

    const data = {
        labels: ['Customers', 'Connections', 'Active', 'Inactive', 'Requires Reconnection'],
        datasets: [
            {
                label: '',
                data: [
                    customerCount,
                    customerConnections,
                    customerActiveConnections,
                    customerInactiveConnections,
                    customerReconnections
                ],
                backgroundColor: `${themeColors.primary}`
            }
        ]
    };

    const options = {
        type: 'bar',
        responsive: true,
        maintainAspectRatio: false,
        onClick: function (evt: any, element: any) {
            console.log('evt', evt);
            if (element.length === 0) return;

            const index: number = element[0]?.index;
            switch (index) {
                case 0:
                    return navigate('/customers?status=allCustomers');
                case 1:
                    return navigate('/customers?status=connections');
                case 2:
                    return navigate('/customers?status=active');
                case 3:
                    return navigate('/customers?status=inactive');
                case 4:
                    return navigate('/customers?status=requiresReconnection');
                default:
                    return navigate('/customers');
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                text: '',
                display: false,
                align: 'start' as any
            }
        }
    };
    const optionsInvitations = {
        type: 'doughnut',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            }
        }
    };

    const dataInvitations = {
        labels: ['Pending', 'Redeemed', 'Revoked'],
        datasets: [
            {
                label: '',
                data: [customerInvitesPending, customerInvitesRedeemed, customerInvitesRevoked],
                backgroundColor: [`rgb(245,176,107)`, `rgb(103,213,125)`, `rgb(238,100,111)`],
                borderColor: [`rgb(245,176,107)`, `rgb(103,213,125)`, `rgb(238,100,111)`],
                borderWidth: 1
            }
        ]
    };

    const toggleInviteClickHandler: (status?: string) => void = (status) => {
        if (status === 'close') {
            setInviteCustomerDialogOpen(!inviteCustomerDialogOpen);
            window.location.reload();
        } else {
            setInviteCustomerDialogOpen(!inviteCustomerDialogOpen);
        }
    };

    const toggleIntroDashboardClickHandler: () => void = () => {
        setIntroDashboardDialogOpen(!introDashboardDialogOpen);
    };

    const toggleNoSubcriptionClickHandler: () => void = () => {
        setHasNoSubscriptionDialogOpen(!hasNoSubscriptionDialogOpen);
    };

    const introDashboardInviteClickHandler: () => void = () => {
        setIntroDashboardDialogOpen(!introDashboardDialogOpen);
        setInviteCustomerDialogOpen(!inviteCustomerDialogOpen);
    };

    const noSubscriptionClickHandler: () => void = () => navigate('/payment');

    useEffect(() => {
        setRenders(renders + 1);
    }, []);

    useEffect(() => {
        if (customerCountCheck && customerInvitesCheck) {
            if (customerCount === 0 && customerInvites === 0) {
                setIntroDashboardDialogOpen(true);
            } else {
                setIntroDashboardDialogOpen(false);
            }
        }
    }, [customerCount, customerInvites, customerCountCheck, customerInvitesCheck]);

    useEffect(() => {
        if (renders === 1) {
            initDashboard();
        }
    }, [renders]);

    const initDashboard: () => void = async () => {
        const noSubscriptionCheck = await getNoHistorySubscriptionStatus();

        if (noSubscriptionCheck) {
            setLoading(false);
            setHasNoSubscription(noSubscriptionCheck);
            return;
        }

        const promises = [
            getCustomerCount(),
            getCustomerConnections(),
            getActiveCustomerConnections(),
            getInactiveCustomerConnections(),
            getCustomerReconnections(),
            getCustomerInvites(),
            getCustomerInvitesPending(),
            getCustomerInvitesRedeemed(),
            getCustomerInvitesRevoked(),
            getCustomersSync()
        ];

        Promise.all(promises).finally(() => setLoading(false));
    };

    const getNoHistorySubscriptionStatus: () => Promise<boolean> = async () => {
        const profileRes = await get(
            apiConfig.profile(),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        );
        const { billingSubscriptionEnd } = await profileRes.json();

        if (billingSubscriptionEnd === null) return true;

        const subEndDateMillis: number = DateTime.fromISO(billingSubscriptionEnd).toMillis();
        const nowDateMillis: number = DateTime.now().toMillis();
        return subEndDateMillis < nowDateMillis;
    };

    const getCustomerCount: () => Promise<void> = async () => {
        const getCustomers = await get(
            apiConfig.customers('?$count=true&$top=0'),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        );

        if (!getCustomers) throw Error('Problem getting customers');

        const getCustomersJson = await getCustomers.json();

        setCustomerCount(getCustomersJson['@odata.count']);
        setCustomerCountCheck(true);
    };

    const getCustomerConnections: () => Promise<void> = async () => {
        get(
            apiConfig.customers('?$count=true&$top=0&$filter=connector ne null'),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerConnections(responseData['@odata.count']);
            });
    };

    const getActiveCustomerConnections: () => Promise<void> = async () => {
        get(
            apiConfig.customers(`?$count=true&$top=0&$filter=connector/status eq 'Active'`),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerActiveConnections(responseData['@odata.count']);
            });
    };

    const getInactiveCustomerConnections: () => Promise<void> = async () => {
        get(
            apiConfig.customers(`?$count=true&$top=0&$filter=connector/status eq 'Inactive'`),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerInactiveConnections(responseData['@odata.count']);
            });
    };

    const getCustomerInvites: () => Promise<void> = async () => {
        get(
            apiConfig.customerInvites(`?$count=true&$top=0`),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerInvites(responseData['@odata.count']);
                setCustomerInvitesCheck(true);
            });
    };

    const getCustomerInvitesPending: () => Promise<void> = async () => {
        get(
            apiConfig.customerInvites(`?$count=true&$top=0&$filter=status eq 'Pending'`),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerInvitesPending(responseData['@odata.count']);
            });
    };

    const getCustomerInvitesRedeemed: () => Promise<void> = async () => {
        get(
            apiConfig.customerInvites(`?$count=true&$top=0&$filter=status eq 'Redeemed'`),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerInvitesRedeemed(responseData['@odata.count']);
            });
    };

    const getCustomerInvitesRevoked: () => Promise<void> = async () => {
        get(
            apiConfig.customerInvites(`?$count=true&$top=0&$filter=status eq 'Revoked'`),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerInvitesRevoked(responseData['@odata.count']);
            });
    };

    const getCustomerReconnections: () => Promise<void> = async () => {
        get(
            apiConfig.customers(`?$count=true&$top=0&$filter=connector/status eq 'Reauthenticate'`),
            await authResult(instance as PublicClientApplication, isAuthenticated, inProgress)
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerReconnections(responseData['@odata.count']);
            });
    };

    const getCustomersSync: () => Promise<void> = async () => {
        setLastImportsLoading(true);

        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );
        get(apiConfig.customersSync(`?$top=4&$orderby=createdDate desc`), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const lastImportData: ICustomerSyncSupplementedData[] = responseData['value'];
                const customerUids = Array.from(
                    new Set(lastImportData.map((item) => item.customerUid))
                );

                get(
                    apiConfig.customers(
                        `?$expand=connector&$filter=customerUid in ('${customerUids.join("','")}')`
                    ),
                    token
                )
                    .then((response: any) => response.json())
                    .then((responseData: any) => {
                        const customersData: ICustomersData[] = responseData['value'];
                        for (let i = 0; i < lastImportData.length; i++) {
                            lastImportData[i].customerName = customersData.filter(
                                (c: ICustomersData) =>
                                    c.customerUid === lastImportData[i].customerUid
                            )[0]?.name;
                        }

                        setLastImportsData(lastImportData);
                    });
            })
            .finally(() => setLastImportsLoading(false));
    };

    return loading ? (
        <LoaderFullPage />
    ) : (
        <DashboardWrapper>
            {hasNoSubscription && (
                <BasicDialog
                    open={hasNoSubscription}
                    clickHandler={toggleNoSubcriptionClickHandler}
                    dialogContent={<NoSubscriptionView closeHandler={noSubscriptionClickHandler} />}
                />
            )}
            {/* {introDashboardDialogOpen && (
                <BasicDialog
                    open={introDashboardDialogOpen}
                    dialogContent={
                        <IntroDashboardView closeHandler={introDashboardInviteClickHandler} />
                    }
                />
            )} */}
            <DashboardContainer>
                <DashboardCustomerCardContainer>
                    <DashboardCardTitle>Connection Statuses​</DashboardCardTitle>
                    <BarContainer>
                        <Bar title="" options={options} data={data} width="100%" ref={chartRef} />
                    </BarContainer>
                </DashboardCustomerCardContainer>
                <DashboardInvitationsCardContainer>
                    <DashboardCardTitle>Invitations</DashboardCardTitle>
                    <DoughnutContainer>
                        <Doughnut data={dataInvitations} options={optionsInvitations} />
                        <DashboardInvitationsOptionSent>
                            {customerInvites}
                            <br />
                            Invitations
                        </DashboardInvitationsOptionSent>
                    </DoughnutContainer>
                    <DashboardInvitationsContainer>
                        <DashboardInvitationsOptionContainer>
                            <DashboardInvitationsOptionIcon
                                style={{ backgroundColor: `rgb(103,213,125)` }}
                            ></DashboardInvitationsOptionIcon>
                            <DashboardInvitationsOptionText>
                                Redeemed
                            </DashboardInvitationsOptionText>
                        </DashboardInvitationsOptionContainer>
                        <DashboardInvitationsOptionContainer>
                            <DashboardInvitationsOptionIcon
                                style={{ backgroundColor: `rgb(245,176,107)` }}
                            ></DashboardInvitationsOptionIcon>
                            <DashboardInvitationsOptionText>Pending</DashboardInvitationsOptionText>
                        </DashboardInvitationsOptionContainer>

                        <DashboardInvitationsOptionContainer>
                            <DashboardInvitationsOptionIcon
                                style={{ backgroundColor: `rgb(238,100,111)` }}
                            ></DashboardInvitationsOptionIcon>
                            <DashboardInvitationsOptionText>Revoked</DashboardInvitationsOptionText>
                        </DashboardInvitationsOptionContainer>
                    </DashboardInvitationsContainer>
                    <DashboardCardButtonContainer>
                        <PrimaryButton
                            clickHandler={toggleInviteClickHandler}
                            disabled={!stepIntegrations}
                        >
                            <DashboardCardButtonText>Invite Customer​</DashboardCardButtonText>
                        </PrimaryButton>
                    </DashboardCardButtonContainer>
                    <FullViewDialog
                        open={inviteCustomerDialogOpen}
                        clickHandler={toggleInviteClickHandler}
                        dialogContent={
                            <CustomerInvitationView
                                closeHandler={() => toggleInviteClickHandler('close')}
                            />
                        }
                    />
                </DashboardInvitationsCardContainer>
            </DashboardContainer>
            <DashboardBottomContainer>
                <DashboardCardsContainer>
                    <DashboardDocumentationCard
                        onClick={() =>
                            window.open('https://finecta.stoplight.io/docs/trade-api', '_BLANK')
                        }
                    >
                        <DocumentationIcon />
                        <DashboardCardsText>See our developer documentation​</DashboardCardsText>
                    </DashboardDocumentationCard>
                    <DashboardApiKeysCard onClick={() => navigate('/developer')}>
                        <ApiIcon />
                        <DashboardCardsText>Generate your API keys​​</DashboardCardsText>
                    </DashboardApiKeysCard>
                </DashboardCardsContainer>
                <DashboardCardContainer>
                    <DashboardCardTitle>Last Imports​</DashboardCardTitle>
                    <DashboardCardContentWrapper>
                        {!lastImportsLoading ? (
                            lastImportsData.length === 0 ? (
                                <Typography tag="body1large">There are no imports.</Typography>
                            ) : (
                                lastImportsData.map((data, index) => (
                                    <DashboardCardContent key={`${index}-lastImport`}>
                                        <DashboardCardRowText>
                                            {data.customerName ?? 'N/A'}
                                        </DashboardCardRowText>
                                        <DashboardCardTextBold>
                                            {formatDateTime(
                                                data.createdDate || '',
                                                'dd/MM/yyyy HH:mm'
                                            )}
                                        </DashboardCardTextBold>
                                    </DashboardCardContent>
                                ))
                            )
                        ) : (
                            <LoaderInPage />
                        )}
                    </DashboardCardContentWrapper>
                </DashboardCardContainer>
            </DashboardBottomContainer>
        </DashboardWrapper>
    );
};

export default Dashboard;
