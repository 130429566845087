/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { authResult } from 'lib/authConfig';
import { apiConfig, get } from 'api/api';
import {
    ConnectionSyncChildStepsContainer,
    ConnectionSyncChildStepsContentContainer,
    ConnectionSyncChildStepsContentLabel,
    ConnectionSyncChildStepsContentNestedContainer,
    ConnectionSyncChildStepsContentRow,
    ConnectionSyncChildStepsContentValue,
    ConnectionSyncChildStepsContentWrapper,
    ConnectionSyncChildStepsHeader,
    ConnectionSyncChildStepsTitle,
    ConnectionSyncStepsContainer,
    ConnectionSyncStepsHeader,
    ConnectionSyncStepsHeaderText,
    ConnectionSyncStepsHeaderTextWrapper,
    ConnectionSyncStepsWrapper
} from './styled';
import DataGrid from 'components/DataGrid';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import { Link } from 'components/link/styled';
import CloseIcon from '@mui/icons-material/Close';
import { themeColors } from 'assets/theme/style';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
export interface ConnectionBannerProps {
    syncId: string;
}
export interface ICustomerSyncData {
    createdDate: string;
    status: string;
    steps: ICustomerSyncStepsData[];
    syncId?: string;
}

export interface ICustomerSyncStepsData {
    childSteps: ICustomerSyncStepsData[];
    endTime: string;
    errorResponse: any;
    id: string;
    name: string;
    reference: any;
    referenceName: any;
    startTime: string;
    status: string;
}

const ConnectionSyncSteps: FC<ConnectionBannerProps> = ({ syncId }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [customerSyncData, setCustomerSyncData] = useState<ICustomerSyncData | null>(null);
    const [page, setPage] = useState<number>(0);
    const [expandedStepName, setExpandedStepName] = useState<string>('');
    const [expandedStepData, setExpandedStepData] = useState<ICustomerSyncStepsData[] | null>(null);
    const [isExpandedStepOpen, setIsExpandedStepOpen] = useState(false);
    const [syncSteps, setSyncSteps] = useState<ICustomerSyncStepsData[] | null>(null);
    const [filteredSyncSteps, setFilteredSyncSteps] = useState<ICustomerSyncStepsData[] | null>(
        null
    );

    useEffect(() => {
        getCustomerSync();
    }, []);

    useEffect(() => {
        filteredCustomerSyncData();
    }, [customerSyncData, page]);

    const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) =>
        setRowsPerPage(numberOfRows);

    const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) =>
        setPage(tableState.page);

    const stepName: (name: string) => string = (name) =>
        name ? name.replace(/([a-z])([A-Z])/g, '$1 $2') : '';

    const handleStepsClick: (open: boolean, data: any, name: string) => void = (
        open,
        data,
        name
    ) => {
        setIsExpandedStepOpen(open);
        setExpandedStepData(data);
        setExpandedStepName(stepName(name));
    };

    const renderChildStepsView: (
        data: ICustomerSyncStepsData[],
        isChildStep?: boolean
    ) => JSX.Element = (data) => {
        if (data.length === 0) {
            return <></>;
        } else {
            return (
                <>
                    <DataGrid
                        loading={false}
                        headers={childStepsTableHeaders}
                        data={data}
                        ariaLabel="Child Steps"
                        ariaCaption="A table that shows child sync steps."
                        rowsPerPage={rowsPerPage}
                        totalRows={data.length}
                        tableChangeHandler={tableChangeHandler}
                        serverSide
                        changeRowsPerPageHandler={rowsPerPageHandler}
                        page={page === -1 ? 0 : page}
                        showFooter={data.length > 10 ? true : false}
                    />
                </>
            );
        }
    };

    const getCustomerSync: () => void = async () => {
        setTableLoading(true);
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        await get(apiConfig.getCustomersSync(syncId), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const mappedData: ICustomerSyncData = {
                    createdDate: formatDateTime(responseData.createdDate || '', 'F'),
                    status: responseData.status || '',
                    steps: responseData.steps || []
                };
                setCustomerSyncData(mappedData);
                setSyncSteps(mappedData.steps);
                setTableLoading(false);
            });
    };

    const customerTableHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem('Name', 'name', {
            ...new DataGridHeaderOptions(false, false, true),
            customBodyRender: (value: any, tableMeta: any) => {
                return tableMeta.rowData[0] && stepName(tableMeta.rowData[0] || value);
            }
        }),
        new DataGridHeaderItem('Start Time', 'startTime', {
            ...new DataGridHeaderOptions(false, false, true),
            customBodyRender: (value: any, tableMeta: any) => {
                console.log(value);
                return tableMeta.rowData[1] && formatDateTime(tableMeta.rowData[1] || '', 'F');
            }
        }),
        new DataGridHeaderItem('End Time', 'endTime', {
            ...new DataGridHeaderOptions(false, false, true),
            customBodyRender: (value: any, tableMeta: any) => {
                console.log(value);
                return tableMeta.rowData[1] && formatDateTime(tableMeta.rowData[2] || '', 'F');
            }
        }),

        new DataGridHeaderItem('Status', 'status', new DataGridHeaderOptions(false, false, true)),
        new DataGridHeaderItem('Steps', 'childSteps', {
            ...new DataGridHeaderOptions(false, false, true),
            customBodyRender: (value: any, tableMeta: any) => {
                console.log(value);
                return (
                    tableMeta.rowData[4].length > 0 && (
                        <Link
                            onClick={() =>
                                handleStepsClick(true, tableMeta.rowData[4], tableMeta.rowData[0])
                            }
                        >
                            See Details
                        </Link>
                    )
                );
            }
        })
    ];


    const childStepsTableHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem('Name', 'name', {
            ...new DataGridHeaderOptions(false, false, true),
            customBodyRender: (value: any, tableMeta: any) => {
                return tableMeta.rowData[0] && stepName(tableMeta.rowData[0] || value);
            }
        }),
        new DataGridHeaderItem('Start Time', 'startTime', {
            ...new DataGridHeaderOptions(false, false, true),
            customBodyRender: (value: any, tableMeta: any) => {
                console.log(value);
                return tableMeta.rowData[1] && formatDateTime(tableMeta.rowData[1] || '', 'F');
            }
        }),
        new DataGridHeaderItem('End Time', 'endTime', {
            ...new DataGridHeaderOptions(false, false, true),
            customBodyRender: (value: any, tableMeta: any) => {
                console.log(value);
                return tableMeta.rowData[1] && formatDateTime(tableMeta.rowData[2] || '', 'F');
            }
        }),
        new DataGridHeaderItem('Status', 'status', new DataGridHeaderOptions(false, false, true)),
        new DataGridHeaderItem('Reference', 'reference', new DataGridHeaderOptions(false, false, true)),
        new DataGridHeaderItem('Error', 'errorResponse', new DataGridHeaderOptions(false, false, true))
    ];

    const filteredCustomerSyncData: () => void = async () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;

        if (syncSteps) {
            const filteredData: ICustomerSyncStepsData[] = syncSteps.slice(startIndex, endIndex);
            filteredData.length > 0 && setFilteredSyncSteps(filteredData);
        }
    };

    return (
        <ConnectionSyncStepsWrapper>
            <ConnectionSyncStepsContainer>
                {customerSyncData && syncSteps ? (
                    <>
                        <ConnectionSyncStepsHeader>
                            <ConnectionSyncStepsHeaderTextWrapper>
                                <ConnectionSyncStepsHeaderText>
                                    Status:
                                </ConnectionSyncStepsHeaderText>
                                {customerSyncData.status}
                            </ConnectionSyncStepsHeaderTextWrapper>
                            <ConnectionSyncStepsHeaderTextWrapper>
                                <ConnectionSyncStepsHeaderText>
                                    Created Date:
                                </ConnectionSyncStepsHeaderText>
                                {customerSyncData.createdDate}
                            </ConnectionSyncStepsHeaderTextWrapper>
                        </ConnectionSyncStepsHeader>
                        {filteredSyncSteps && (
                            <DataGrid
                                loading={tableLoading}
                                headers={customerTableHeaders}
                                data={filteredSyncSteps}
                                ariaLabel="Customer Sync Steps table"
                                ariaCaption="A table that shows sync steps."
                                rowsPerPage={rowsPerPage}
                                totalRows={syncSteps.length}
                                tableChangeHandler={tableChangeHandler}
                                serverSide
                                changeRowsPerPageHandler={rowsPerPageHandler}
                                page={page === -1 ? 0 : page}
                                showFooter={syncSteps.length > 10 ? true : false}
                            />
                        )}
                        {isExpandedStepOpen && expandedStepData && expandedStepData.length > 0 && (
                            <ConnectionSyncChildStepsContainer>
                                <ConnectionSyncChildStepsHeader>
                                    <ConnectionSyncChildStepsTitle>
                                        {expandedStepName} - All Steps
                                    </ConnectionSyncChildStepsTitle>
                                    <CloseIcon
                                        onClick={() => handleStepsClick(false, [], '')}
                                        style={{ color: themeColors.body, cursor: 'pointer' }}
                                    />
                                </ConnectionSyncChildStepsHeader>

                                <ConnectionSyncChildStepsContentWrapper>
                                    {renderChildStepsView(expandedStepData)}
                                </ConnectionSyncChildStepsContentWrapper>
                            </ConnectionSyncChildStepsContainer>
                        )}
                    </>
                ) : (
                    <LoaderInPage />
                )}
            </ConnectionSyncStepsContainer>
        </ConnectionSyncStepsWrapper>
    );
};

export default ConnectionSyncSteps;
