/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { authResult } from 'lib/authConfig';
import { apiConfig, put } from 'api/api';
import {
    ResetConnectorChild,
    ResetConnectorChildContainer,
    ResetConnectorConnectionMessage,
    ResetConnectorContainer,
    ResetConnectorCustomerInformation,
    ResetConnectorCustomerInformationContainer,
    ResetConnectorCustomerInformationText,
    ResetConnectorCustomerInformationTextBold,
    ResetConnectorCustomerInformationTextContainer,
    ResetConnectorHeader,
    ResetConnectorHeaderFlexible,
    ResetConnectorHeaderText,
    ResetConnectorHeaderTextWrapper,
    ResetConnectorHorizontalContainer,
    ResetConnectorSnackBarContainer,
    ResetConnectorSnackBarTitle,
    ResetConnectorWrapper,
    ResetConnectorCustomerInformationTextConnected,
    ResetConnectorCustomerInformationTitleName
} from './styled';
import { PrimaryButton } from 'components/common/buttons';
import { triggerSnackbar } from 'context/action/app';
import Context from 'context';
import AlertDialog from 'components/dialog/alertDialog';
import { themeColors } from 'assets/theme/style';
import Divider from 'components/divider/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
export interface ResetConnectionProps {
    customerUid: string;
    name: string;
    status: string;
    source: string;
    lastImport: string;
}

const ResetConnector: FC<ResetConnectionProps> = ({
    customerUid,
    name,
    status,
    source,
    lastImport
}) => {
    const [state, dispatch] = useContext(Context);
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [enableReset, setEnableReset] = useState<boolean>(true);
    const [enableDelete, setEnableDelete] = useState<boolean>(true);
    const [confirmResetDialogOpen, setConfirmResetDialogOpen] = useState<boolean>(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false);

    const deleteCustomerData: () => void = async () => {
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        const deleteCustomerDataResponse: Response = await put(
            apiConfig.deleteCustomerData(customerUid),
            {},
            token
        );

        if (deleteCustomerDataResponse.ok) return setEnableDelete(false);

        triggerSnackbar(dispatch, {
            open: true,
            data: {
                role: 'error',
                message: (
                    <ResetConnectorSnackBarContainer>
                        <ResetConnectorSnackBarTitle>An error occurred</ResetConnectorSnackBarTitle>
                        <ResetConnectorConnectionMessage>
                            We could not delete your customer's data, please try again later
                        </ResetConnectorConnectionMessage>
                    </ResetConnectorSnackBarContainer>
                )
            }
        });
    };

    const resetCustomerDates: () => void = async () => {
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        const resetCustomerDatesResponse: Response = await put(
            apiConfig.resetCustomerDates(customerUid),
            {},
            token
        );

        if (resetCustomerDatesResponse.ok) return setEnableReset(false);

        triggerSnackbar(dispatch, {
            open: true,
            data: {
                role: 'error',
                message: (
                    <ResetConnectorSnackBarContainer>
                        <ResetConnectorSnackBarTitle>An error occurred</ResetConnectorSnackBarTitle>
                        <ResetConnectorConnectionMessage>
                            We could not reset your customer's dates, please try again later
                        </ResetConnectorConnectionMessage>
                    </ResetConnectorSnackBarContainer>
                )
            }
        });
    };

    const toggleConfirmResetClickHandler: () => void = () => {
        setConfirmDeleteDialogOpen(false);
        setConfirmResetDialogOpen(!confirmResetDialogOpen);
    };

    const toggleConfirmDeleteClickHandler: () => void = () => {
        setConfirmResetDialogOpen(false);
        setConfirmDeleteDialogOpen(!confirmDeleteDialogOpen);
    };

    const renderAlertDialog: (
        title: string,
        primaryButtonText: string,
        secondButtonText: string,
        open: boolean,
        clickHandler: () => void,
        clickSecondHandler: () => void,
        disabledPrimaryButton: boolean
    ) => JSX.Element = (
        title,
        primaryButtonText,
        secondButtonText,
        open,
        clickHandler,
        clickSecondHandler,
        disabledPrimaryButton
    ) => (
        <AlertDialog
            title={title}
            disabledPrimaryButton={disabledPrimaryButton}
            primaryButtonText={primaryButtonText}
            secondButtonText={secondButtonText}
            description=""
            open={open}
            clickHandler={clickHandler}
            clickSecondHandler={clickSecondHandler}
        />
    );

    const statusColor: (status: string | undefined) => string = (status) => {
        switch (status) {
            case 'Reauthenticate':
                return themeColors.warning;
            case 'Active':
                return themeColors.success;
            case 'No active connector':
                return themeColors.darkGrey;
            default:
                return themeColors.error;
        }
    };

    return (
        <ResetConnectorWrapper>
            <ResetConnectorContainer>
                <ResetConnectorHeader>
                    <ResetConnectorCustomerInformationContainer>
                        <ResetConnectorCustomerInformation>
                            <ResetConnectorCustomerInformationTitleName>
                                {name || ''}
                            </ResetConnectorCustomerInformationTitleName>
                            <ResetConnectorCustomerInformationTextConnected
                                bgColor={statusColor(status || '')}
                            >
                                {status.toUpperCase() || ''}
                            </ResetConnectorCustomerInformationTextConnected>
                        </ResetConnectorCustomerInformation>
                        <ResetConnectorCustomerInformation>
                            <ResetConnectorCustomerInformationTextContainer>
                                <SettingsIcon color="action" />
                                <ResetConnectorCustomerInformationText>
                                    Last import:
                                    {lastImport}
                                </ResetConnectorCustomerInformationText>
                            </ResetConnectorCustomerInformationTextContainer>

                            <ResetConnectorCustomerInformationTextContainer>
                                <ResetConnectorCustomerInformationText>
                                    Source:
                                </ResetConnectorCustomerInformationText>
                                <ResetConnectorCustomerInformationTextBold>
                                    {source || ''}
                                </ResetConnectorCustomerInformationTextBold>
                            </ResetConnectorCustomerInformationTextContainer>
                        </ResetConnectorCustomerInformation>
                    </ResetConnectorCustomerInformationContainer>
                    <Divider />
                    <ResetConnectorHeaderTextWrapper>
                        <ResetConnectorHeaderText>
                            Choose whether you would like to reset customer sync dates or delete
                            customer data.
                        </ResetConnectorHeaderText>
                    </ResetConnectorHeaderTextWrapper>
                </ResetConnectorHeader>

                <ResetConnectorHorizontalContainer>
                    <ResetConnectorChild>
                        <ResetConnectorChildContainer>
                            <ResetConnectorHeader>
                                <ResetConnectorHeaderTextWrapper>
                                    <ResetConnectorHeaderText>
                                        Reset customer sync dates
                                    </ResetConnectorHeaderText>
                                </ResetConnectorHeaderTextWrapper>
                            </ResetConnectorHeader>

                            <ResetConnectorHeaderFlexible>
                                This will reset the dates of the last sync for this customer back to
                                2-years from today and retrieve any missed data in that period.
                            </ResetConnectorHeaderFlexible>

                            <PrimaryButton
                                disabled={!enableReset}
                                width="fit-content"
                                text="Reset connector dates"
                                clickHandler={toggleConfirmResetClickHandler}
                            />
                        </ResetConnectorChildContainer>

                        <ResetConnectorChildContainer>
                            <ResetConnectorHeader>
                                <ResetConnectorHeaderTextWrapper>
                                    <ResetConnectorHeaderText>
                                        Delete all customer data
                                    </ResetConnectorHeaderText>
                                </ResetConnectorHeaderTextWrapper>
                            </ResetConnectorHeader>

                            <ResetConnectorHeaderFlexible>
                                This will remove all data for this customer but will leave the
                                customer and connector configuration intact. All invoices, purchase
                                orders, payments and related data will be deleted permanently!
                            </ResetConnectorHeaderFlexible>

                            <PrimaryButton
                                disabled={!enableDelete}
                                width="fit-content"
                                text="Delete all customer data"
                                clickHandler={toggleConfirmDeleteClickHandler}
                            />
                        </ResetConnectorChildContainer>
                    </ResetConnectorChild>
                </ResetConnectorHorizontalContainer>
                {renderAlertDialog(
                    enableReset
                        ? "Are you sure you want to reset your customer's dates?"
                        : "The customer's dates have been reset.",
                    'Reset',
                    enableReset ? 'Cancel' : 'Close',
                    confirmResetDialogOpen,
                    resetCustomerDates,
                    toggleConfirmResetClickHandler,
                    enableReset ? false : true
                )}
                {renderAlertDialog(
                    enableDelete
                        ? "This is UNRECOVERABLE, are you sure you want to delete all of your customer's data?"
                        : 'Your data is being deleted, it may take some time.',
                    'Delete',
                    enableDelete ? 'Cancel' : 'Close',
                    confirmDeleteDialogOpen,
                    deleteCustomerData,
                    toggleConfirmDeleteClickHandler,
                    enableDelete ? false : true
                )}
            </ResetConnectorContainer>
        </ResetConnectorWrapper>
    );
};

export default ResetConnector;
