/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import Context from 'context';
import Footer from 'components/footer';
import Nav from 'components/nav';
import Layout from 'layout';
import { FC, useContext, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { AppWrapper, ViewWrapper } from 'styled';
import CustomersView from 'views/CustomersView';
import CustomerDetail from 'views/CustomersView/CustomerDetail';
import Dashboard from 'views/Dashboard';
import DeveloperView from 'views/DeveloperView';
import IntegrationsView from 'views/IntegrationsView';
import Invitations from 'views/Invitations';
import InvoicesView from 'views/InvoicesView';
import Payment from 'views/Payment';
import Register from 'views/Register';
import ResetPassword from 'views/ResetPassword';
import SecureAppWrapper from 'views/SecureAppWrapper';
import SignUp from 'views/SignUp';
import SnackbarContainer from 'components/snackbar/Snackbar';
import { triggerSnackbar } from 'context/action/app';
import InvoiceDetailView from 'views/InvoiceDetailView';
import ChatView from 'components/ChatBot/ChatBot';
import AnalyticsView from 'views/AnalyticsView';
export interface MSALProps {
    msalInstance: PublicClientApplication;
}

const App: FC<MSALProps> = ({ msalInstance }) => {
    const [state, dispatch] = useContext(Context);
    const snackbarOpen: boolean = state?.app?.snackbarOpen || false;
    const snackbarDataMessage: JSX.Element | string = state?.app?.snackbarData?.message || '';
    const snackbarDataRole: 'success' | 'warning' | 'error' | 'alert' | undefined =
        state?.app?.snackbarData?.role;

    const closeSnackbarClickHandler: () => void = () => {
        triggerSnackbar(dispatch, {
            open: false,
            data: null
        });
    };

    return (
        <MsalProvider instance={msalInstance}>
            <AppWrapper>
                <header>
                    <Nav />
                </header>
                <ViewWrapper>
                    <Layout>
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <Routes>
                                <Route
                                    path="/"
                                    element={<SecureAppWrapper msalInstance={msalInstance} />}
                                >
                                    <Route
                                        path=""
                                        element={<Dashboard msalInstance={msalInstance} />}
                                    />
                                    <Route path="customers" element={<CustomersView />} />
                                    <Route
                                        path="customers/:customerId"
                                        element={<CustomerDetail />}
                                    />
                                    <Route path="invitations" element={<Invitations />} />
                                    <Route path="developer" element={<DeveloperView />} />
                                    <Route path="invoices/:customerId" element={<InvoicesView />} />
                                    <Route
                                        path="invoice/:customerUid/:invoiceUid"
                                        element={<InvoiceDetailView />}
                                    />
                                    <Route
                                        path="analytics/:customerUid"
                                        element={<AnalyticsView />}
                                    />

                                    <Route path="payment" element={<Payment />} />
                                    <Route path="integrations" element={<IntegrationsView />} />
                                    <Route
                                        path="signup/:invitationId/accept"
                                        element={<SignUp msalInstance={msalInstance} />}
                                    />
                                </Route>
                                <Route path="/register" element={<Register />} />
                                <Route
                                    path="/signup/:invitationId"
                                    element={<SignUp msalInstance={msalInstance} />}
                                />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </Suspense>
                    </Layout>
                </ViewWrapper>
                <SnackbarContainer
                    open={snackbarOpen}
                    message={snackbarDataMessage}
                    role={snackbarDataRole}
                    closeHandler={closeSnackbarClickHandler}
                />
                <Footer />
                <ChatView />
            </AppWrapper>
        </MsalProvider>
    );
};

export default App;
