import { Alert } from '@mui/material';
import TextField from '@mui/material/TextField';
import { apiConfig, put } from 'api/api';
import { inputSxStyles } from 'assets/theme/style';
import { b2cPolicies } from 'lib/authConfig';
import { PrimaryButton } from 'components/common/buttons';
import { Link } from 'components/link/styled';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import Typography from 'components/typography';
import { _recaptchaKey } from 'config';
import { FC, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ResetPasswordFormData } from 'utils/interfaces/types/resetPasswordFormType';
import {
    FormStyled,
    ResetPasswordConfirmEmailContainer,
    ResetPasswordContainer,
    ResetPasswordFooterContainer,
    ResetPasswordHeadingContainer,
    TextFieldErrorMessage
} from './styled';
import { useForm } from 'react-hook-form';
import { RedirectRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useSearchParams } from 'react-router-dom'

export interface IContactForm {
    email: string;
}

const ResetPassword: FC = () => {
    const { instance } = useMsal();
    const [searchParams] = useSearchParams();
    const [recaptchaPassed, setRecaptchaPassed] = useState<boolean>(false);
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [resendCount, setResendCount] = useState<number>(0);
    const [showResendMesssage, setShowResendMesssage] = useState<boolean>(false);
    const [formData, setFormData] = useState<ResetPasswordFormData>({
        email: '',
        hostName: window.location.hostname
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<IContactForm>({ mode: 'onChange', reValidateMode: 'onChange' });

    useEffect(() => {
        if (searchParams.get('idTokenHint')) {
            const resetPasswordRequest: RedirectRequest = {
                authority: b2cPolicies.authorities.forgotPassword.authority,
                scopes: [],
                extraQueryParameters: {
                    id_token_hint: searchParams.get('idTokenHint') ?? '',
                    campaignId: window.location.hostname
                }
            };

            instance.loginRedirect(resetPasswordRequest);
        } else {
            const account = instance.getActiveAccount();
            if (account && account.idTokenClaims && account.idTokenClaims.email) {
                setFormData({ ...formData, email: account.idTokenClaims.email + '' })
            }
            setShowForm(true);
        }
    }, []);

    const resetPasswordClickHandler: () => void = () => {
        if (loading) return;
        setLoading(true);
        const putModel = {
            email: formData.email,
            redirectUrl: `${window.location.origin}/reset-password`
        };
        put(apiConfig.resetPassword(), putModel, '').then((res) => {
            if (res.ok) {
                setLoading(false);
                setSubmittedForm(true);
            } else {
                setError(true);
                setLoading(false);
            }
        });
    };

    const ResendClickHandler: () => void = () => {
        resetPasswordClickHandler();
        setShowResendMesssage(true);
        setTimeout(() => {
            setShowResendMesssage(false);
        }, 3000);
    };

    const renderResetPasswordView: () => JSX.Element = () => (
        <>
            <ResetPasswordHeadingContainer>
                <Typography tag="h1" fontWeight="regular">
                    Reset your G2F password
                </Typography>
                <Typography tag="subheading1">
                    Enter your email below to reset your G2F password
                </Typography>
                {error && (
                    <Alert severity="error">
                        Something went wrong submitting this form. Please contact us.
                    </Alert>
                )}
            </ResetPasswordHeadingContainer>
            <FormStyled
                id="reset-password-form"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(resetPasswordClickHandler)();
                }}
            >
                <TextField
                    value={formData.email}
                    sx={inputSxStyles}
                    id="finecta-register-email"
                    label="Email Address"
                    inputProps={register('email', {
                        required: true,
                        pattern:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({ ...formData, email: event.target.value });
                    }}
                    error={Boolean(errors['email'])}
                />
                {!!Object.keys(errors).length
                    ? errors.email && (
                          <TextFieldErrorMessage>Email is a required field.</TextFieldErrorMessage>
                      )
                    : ''}
                <ReCAPTCHA sitekey={_recaptchaKey} onChange={(val) => setRecaptchaPassed(!!val)} />
                <PrimaryButton
                    disabled={
                        !recaptchaPassed ||
                        loading ||
                        !formData.email
                    }
                    type="submit"
                >
                    {loading ? <LoaderInPage /> : 'Reset Password'}
                </PrimaryButton>
            </FormStyled>
        </>
    );

    const renderSubmittedView: () => JSX.Element = () => (
        <ResetPasswordConfirmEmailContainer>
            <ResetPasswordHeadingContainer>
                <Typography tag="h1" fontWeight="regular">
                    Confirm <span style={{ fontWeight: 700 }}>your email</span>
                </Typography>
                <Typography tag="h4" textColor="grey">
                    We have sent an email to {formData.email} with a <br /> reset password link. Click
                    the link to continue.
                </Typography>
            </ResetPasswordHeadingContainer>
            <ResetPasswordFooterContainer>
                <Typography tag="body" fontWeight="bold">
                    Email not received?
                </Typography>
                <Typography tag="body" textColor="grey">
                    Check your spam folder or{' '}
                    <Link onClick={ResendClickHandler}>click here to resend email.</Link>
                </Typography>

                <Typography tag="body1" textColor="grey">
                    {showResendMesssage &&
                        `We have resent the email to ${formData.email}. Please check your inbox and
                        spam folder.`}
                </Typography>
            </ResetPasswordFooterContainer>
        </ResetPasswordConfirmEmailContainer>
    );

    return (
        <ResetPasswordContainer>
            {
                !showForm &&
                <ResetPasswordConfirmEmailContainer>
                    <Typography tag="body" fontWeight="bold">
                        Loading
                    </Typography>
                </ResetPasswordConfirmEmailContainer>
            }
            {
                showForm &&
                <>
                    {submittedForm ? renderSubmittedView() : renderResetPasswordView()}
                </>
            }

            {}
        </ResetPasswordContainer>
    );
};

export default ResetPassword;
