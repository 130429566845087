import { FC } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { FullPageErrorContainer, FullPageErrorHeading, FullPageErrorText } from './styled';

interface ErrorFullPageProps {
    detail?: string;
}

const ErrorFullPage: FC<ErrorFullPageProps> = ({ detail }) => (
    <FullPageErrorContainer>
        <FullPageErrorHeading>
            <ErrorIcon color="warning" />
        </FullPageErrorHeading>
        <FullPageErrorText>{detail || `Error`}</FullPageErrorText>
    </FullPageErrorContainer>
);

export default ErrorFullPage;
