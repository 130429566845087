import { Dialog } from '@mui/material';
import { FC } from 'react';
import FullScreenDialogAppBar from 'components/app-bars/FullScreenDialogAppBar';

interface FullViewDialogProps {
    open: boolean;
    clickHandler: () => void;
    dialogContent: JSX.Element;
}

const FullViewDialog: FC<FullViewDialogProps> = ({ open, clickHandler, dialogContent }) => (
    <Dialog style={{ width: '100vw' }} fullScreen open={open} onClose={clickHandler}>
        <FullScreenDialogAppBar clickHandler={clickHandler} />
        {dialogContent}
    </Dialog>
);

export default FullViewDialog;
