import { DateTime } from 'luxon';

export const formatDateTime: (val: string | undefined, format?: string) => string = (
    val,
    format = 'yyyy-LL-dd HH:mm'
) => {
    if (!val) return '';
    return DateTime.fromISO(`${val ? val : ''}`).toFormat(format);
};

export const dateFormatForIso: (date: string) => string = (date) => {
    const [d, m, y] = date.split('-');
    return `${y}-${m}-${d}`;
};
