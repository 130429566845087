import loader from 'assets/lottie-animations/loading-circle.json';
import Lottie from 'lottie-react';
import { FC } from 'react';

const LoaderCircle: FC = () => (
    <Lottie
        style={{
            height: '90px',
            width: '90px',
            position: 'absolute',
            right: '-32px',
            top: '-32px'
        }}
        loop
        animationData={loader}
    />
);

export default LoaderCircle;
