import { themeColors, device } from 'assets/theme/style';
import styled from 'styled-components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ListItem from "@mui/material/ListItem";

export const NavigationMobileContainer = styled.div`
  display: none;
  @media ${device.mobileOnly} {
    display: flex;
  }
`;

export const NavigationMenuIconContainer = styled.button`
  color: ${themeColors.body};
  background: none;
  border: none;
  cursor: pointer;
`;

export const ProfileListItem = styled(ListItem)`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const SwipeableDrawerContainer = styled(SwipeableDrawer)`

  .MuiDrawer-root{
    z-index: 999999999;
  }

  .MuiPaper-root{
    width: 70%;
    min-width: 200px;
  }

`;


