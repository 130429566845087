/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import {
    AnalyticsChartSalesTitle,
    AnalyticsChartSalesContainer,
    AnalyticsChartSalesWrapper,
    AnalyticsChartSalesDetailsContainer,
    AnalyticsChartSalesDetailBoxContainer,
    AnalyticsChartSalesDetailBox,
    AnalyticsChartSalesDetailTitle,
    AnalyticsChartSalesDetailsWrapper,
    AnalyticsChartSalesChartContainer,
    AnalyticsChartSalesContainerSubtitle,
    AnalyticsChartSalesRowContainer,
    AnalyticsChartSalesHeader,
    TooltipWrapper,
    TooltipDescription,
    TooltipTitle
} from './styled';
import { formatNumberToShortString } from 'lib/helpers/formatters/convertNumberToShortString';
import { themeColors } from 'assets/theme/style';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import { IAnalyticsSalesData } from 'utils/interfaces/analytics.interface';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import IconTooltip from 'components/tooltip/IconTooltip';

interface ISalesViewProps {
    salesData: IAnalyticsSalesData | null;
}

const SalesView: FC<ISalesViewProps> = ({ salesData }) => {
    const [data, setData] = useState<IAnalyticsSalesData | null>(null);
    const [barChartLabels, setBarChartLabels] = useState<number[]>([]);
    const [barChartData, setBarChartData] = useState<number[]>([]);

    ChartJS.register(
        CategoryScale,
        ChartDataLabels,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    useEffect(() => {
        if (salesData) {
            setData(salesData);
            if (salesData.lastThreeYears && salesData.lastThreeYears.length > 0) {
                setBarChartLabels(salesData.lastThreeYears.map((item) => item.year));
                setBarChartData(salesData.lastThreeYears.map((item) => parseInt(item.value, 10)));
            }
        }
    }, [salesData]);

    const chartData = {
        labels: data?.lastThreeYears ? data?.lastThreeYears.map((item) => item.year) : [],
        datasets: [
            {
                labels: barChartLabels,
                data: barChartData,
                backgroundColor: ['#48ADDC', '#738AC8', '#3FB39F']
            }
        ]
    };

    const options = {
        type: 'bar',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                stacked: false,
                grid: {
                    drawBorder: false,
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: 35000,
                ticks: {
                    labelOffset: 4,
                    maxTicksLimit: 6,
                    align: 'start' as const,
                    z: 1,
                    min: 0,
                    stepSize: 100,
                    callback: (value: any) => {
                        return value === 0 ? value : `£${formatNumberToShortString(value || 0)}`;
                    }
                }
            }
        },
        plugins: {
            datalabels: {
                anchor: 'center' as const,
                color: themeColors.body,
                labels: {
                    title: {
                        font: {
                            weight: 'bold' as const
                        }
                    }
                },
                formatter: (value: any) =>
                    value > 0 ? `£${formatNumberToShortString(value || 0)}` : ''
            },
            legend: {
                display: false
            },
            title: {
                text: '',
                display: false,
                align: 'start' as any
            }
        }
    };

    return (
        <AnalyticsChartSalesWrapper>
            <AnalyticsChartSalesHeader>
                <AnalyticsChartSalesTitle>SALES</AnalyticsChartSalesTitle>
                <IconTooltip
                    tooltipText={
                        <TooltipWrapper>
                            {/* <TooltipTitle>Title</TooltipTitle> */}
                            <TooltipDescription>
                                We have summarised the main data points from the receivable
                                invoices. We show the sum total amounts of all receivables for the
                                last three calendar years and then details from the last 12 months.
                                The average invoice value is over all time.
                            </TooltipDescription>
                        </TooltipWrapper>
                    }
                />
            </AnalyticsChartSalesHeader>
            <AnalyticsChartSalesContainer>
                <AnalyticsChartSalesRowContainer>
                    <AnalyticsChartSalesChartContainer>
                        <Bar title="" options={options} data={chartData} />
                    </AnalyticsChartSalesChartContainer>
                    <AnalyticsChartSalesDetailsWrapper>
                        <AnalyticsChartSalesContainerSubtitle>
                            Last 12 months
                        </AnalyticsChartSalesContainerSubtitle>
                        <AnalyticsChartSalesDetailsContainer>
                            <AnalyticsChartSalesDetailBoxContainer>
                                <AnalyticsChartSalesDetailBox>
                                    {Math.floor(data?.growth || 0).toLocaleString()}
                                </AnalyticsChartSalesDetailBox>
                                <AnalyticsChartSalesDetailTitle>
                                    % Growth
                                </AnalyticsChartSalesDetailTitle>
                            </AnalyticsChartSalesDetailBoxContainer>
                            <AnalyticsChartSalesDetailBoxContainer>
                                <AnalyticsChartSalesDetailBox>
                                    £{formatNumber(data?.growthLastTwelveMonthsValue || 0, 2)}
                                </AnalyticsChartSalesDetailBox>
                                <AnalyticsChartSalesDetailTitle>
                                    Growth
                                </AnalyticsChartSalesDetailTitle>
                            </AnalyticsChartSalesDetailBoxContainer>
                        </AnalyticsChartSalesDetailsContainer>
                        <AnalyticsChartSalesDetailsContainer>
                            <AnalyticsChartSalesDetailBoxContainer>
                                <AnalyticsChartSalesDetailBox>
                                    {data?.lastTwelveMonthsVolume || '0'}
                                </AnalyticsChartSalesDetailBox>
                                <AnalyticsChartSalesDetailTitle>
                                    Num. Invoices​
                                </AnalyticsChartSalesDetailTitle>
                            </AnalyticsChartSalesDetailBoxContainer>
                            <AnalyticsChartSalesDetailBoxContainer>
                                <AnalyticsChartSalesDetailBox>
                                    £{formatNumber(data?.lastTwelveMonthsValue || 0, 2)}
                                </AnalyticsChartSalesDetailBox>
                                <AnalyticsChartSalesDetailTitle>
                                    Value
                                </AnalyticsChartSalesDetailTitle>
                            </AnalyticsChartSalesDetailBoxContainer>
                        </AnalyticsChartSalesDetailsContainer>
                        <AnalyticsChartSalesContainerSubtitle>
                            Yearly Averages
                        </AnalyticsChartSalesContainerSubtitle>
                        <AnalyticsChartSalesDetailsContainer>
                            <AnalyticsChartSalesDetailBoxContainer>
                                <AnalyticsChartSalesDetailBox>
                                    {data?.averageVolume || '0'}
                                </AnalyticsChartSalesDetailBox>
                                <AnalyticsChartSalesDetailTitle>
                                    Invoices
                                </AnalyticsChartSalesDetailTitle>
                            </AnalyticsChartSalesDetailBoxContainer>
                            <AnalyticsChartSalesDetailBoxContainer>
                                <AnalyticsChartSalesDetailBox>
                                    £{formatNumber(data?.averageYearlyValue || 0, 2)}
                                </AnalyticsChartSalesDetailBox>
                                <AnalyticsChartSalesDetailTitle>
                                    Invoiced Amount
                                </AnalyticsChartSalesDetailTitle>
                            </AnalyticsChartSalesDetailBoxContainer>
                        </AnalyticsChartSalesDetailsContainer>
                        <AnalyticsChartSalesContainerSubtitle>
                            Average Invoice Value
                        </AnalyticsChartSalesContainerSubtitle>
                        <AnalyticsChartSalesDetailBoxContainer>
                            <AnalyticsChartSalesDetailBox>
                                £{formatNumber(data?.averageValue || 0, 2)}
                            </AnalyticsChartSalesDetailBox>
                        </AnalyticsChartSalesDetailBoxContainer>
                    </AnalyticsChartSalesDetailsWrapper>
                </AnalyticsChartSalesRowContainer>
            </AnalyticsChartSalesContainer>
        </AnalyticsChartSalesWrapper>
    );
};

export default SalesView;
