/* eslint-disable react-hooks/exhaustive-deps */

import { FC } from 'react';
import {
    AnalyticsChartConfidenceFactorTitle,
    AnalyticsChartConfidenceFactorContainer,
    AnalyticsChartConfidenceFactorWrapper,
    AnalyticsChartConfidenceFactorDetailBox,
    AnalyticsChartConfidenceFactorDetailTitle,
    AnalyticsChartConfidenceFactorDetailsWrapper,
    AnalyticsChartConfidenceFactorDetailsOneColumnContainer,
    AnalyticsChartConfidenceFactorBarsContainer,
    AnalyticsChartConfidenceFactorBar,
    AnalyticsChartConfidenceFactorBarLabel,
    AnalyticsChartConfidenceFactorBarValue,
    AnalyticsChartConfidenceFactorBarContainer,
    AnalyticsChartFactorContainerSubtitle,
    AnalyticsChartConfidenceFactorRowContainer,
    AnalyticsChartConfidenceHeader,
    TooltipWrapper,
    TooltipTitle,
    TooltipDescription
} from './styled';
import { themeColors } from 'assets/theme/style';
import GaugeComponent from 'react-gauge-component';
import { IAnalyticsData } from 'utils/interfaces/analytics.interface';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import IconTooltip from 'components/tooltip/IconTooltip';

interface ConfidenceViewProps {
    confidenceData: IAnalyticsData | null;
}

const ConfidenceFactorView: FC<ConfidenceViewProps> = ({ confidenceData }) => {
    const transAnalysed = confidenceData
        ? confidenceData?.transactions.salesVolume + confidenceData?.transactions.expensesVolume
        : '-';

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const chartData = {
        labels: ['Invoices', 'Bills'],
        datasets: [
            {
                labels: ['Invoices', 'Bills'],
                data: [
                    confidenceData?.transactions.salesVolume,
                    confidenceData?.transactions.expensesVolume
                ],
                backgroundColor: ['#e27c7c', '#a86464'],
                color: '#ffffff'
            }
        ]
    };

    const options = {
        type: 'bar',
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y' as const,
        scales: {
            x: {
                display: false,
                beginAtZero: true,
                stacked: false
            },
            y: {
                grid: {
                    drawBorder: false,
                    borderWidth: 1,
                    borderColor: themeColors.white,
                    display: false
                },
                beginAtZero: true,
                ticks: {
                    align: 'center' as const
                }
            }
        },
        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: false
            }
        }
    };

    return (
        <AnalyticsChartConfidenceFactorWrapper>
            <AnalyticsChartConfidenceHeader>
                <AnalyticsChartConfidenceFactorTitle>
                    CONFIDENCE FACTOR
                </AnalyticsChartConfidenceFactorTitle>
                <IconTooltip
                    tooltipText={
                        <TooltipWrapper>
                            {/* <TooltipTitle>Title</TooltipTitle> */}
                            <TooltipDescription>
                                We look at the number of transactions analysed as a whole. The more
                                transactions that are available, the more confidence we have in the
                                data.
                            </TooltipDescription>
                        </TooltipWrapper>
                    }
                />
            </AnalyticsChartConfidenceHeader>
            <AnalyticsChartConfidenceFactorContainer>
                <AnalyticsChartFactorContainerSubtitle>
                    Scope of Transactions
                </AnalyticsChartFactorContainerSubtitle>
                <AnalyticsChartConfidenceFactorRowContainer>
                    <AnalyticsChartConfidenceFactorBarsContainer>
                        <Bar title="" options={options} data={chartData} />
                    </AnalyticsChartConfidenceFactorBarsContainer>

                    <AnalyticsChartConfidenceFactorDetailsWrapper>
                        <GaugeComponent
                            value={confidenceData?.confidence}
                            style={{ width: '100%' }}
                            type="semicircle"
                            labels={{
                                valueLabel: {
                                    hide: true
                                },

                                tickLabels: {
                                    defaultTickValueConfig: {
                                        style: { fontSize: 14 }
                                    },
                                    ticks: [
                                        {
                                            value: 0
                                        },
                                        {
                                            value: 20
                                        },
                                        {
                                            value: 40
                                        },
                                        {
                                            value: 60
                                        },
                                        {
                                            value: 80
                                        },
                                        {
                                            value: 100
                                        }
                                    ]
                                }
                            }}
                            arc={{
                                nbSubArcs: 5,
                                colorArray: ['#3c4e4b', '#5aa89c'],
                                padding: 0.03,
                                width: 0.1,
                                subArcs: [
                                    {
                                        limit: 20,
                                        showTick: true
                                    },
                                    {
                                        limit: 40,
                                        showTick: true
                                    },
                                    {
                                        limit: 60,
                                        showTick: true
                                    },
                                    {
                                        limit: 100,
                                        showTick: true
                                    }
                                ]
                            }}
                            pointer={{
                                elastic: true,
                                animationDelay: 0
                            }}
                        />
                        <AnalyticsChartConfidenceFactorDetailsOneColumnContainer>
                            <AnalyticsChartConfidenceFactorDetailBox
                                color={themeColors.lightBlue}
                                fontColor={themeColors.body}
                            >
                                {confidenceData?.confidence}%
                            </AnalyticsChartConfidenceFactorDetailBox>
                        </AnalyticsChartConfidenceFactorDetailsOneColumnContainer>
                        <AnalyticsChartConfidenceFactorDetailsOneColumnContainer>
                            <AnalyticsChartConfidenceFactorDetailTitle>
                                Confidence factor
                            </AnalyticsChartConfidenceFactorDetailTitle>
                        </AnalyticsChartConfidenceFactorDetailsOneColumnContainer>

                        <AnalyticsChartConfidenceFactorDetailsOneColumnContainer>
                            <AnalyticsChartConfidenceFactorDetailBox>
                                {transAnalysed}
                            </AnalyticsChartConfidenceFactorDetailBox>
                        </AnalyticsChartConfidenceFactorDetailsOneColumnContainer>
                        <AnalyticsChartConfidenceFactorDetailsOneColumnContainer>
                            <AnalyticsChartConfidenceFactorDetailTitle>
                                Trans analysed
                            </AnalyticsChartConfidenceFactorDetailTitle>
                        </AnalyticsChartConfidenceFactorDetailsOneColumnContainer>
                    </AnalyticsChartConfidenceFactorDetailsWrapper>
                </AnalyticsChartConfidenceFactorRowContainer>
            </AnalyticsChartConfidenceFactorContainer>
        </AnalyticsChartConfidenceFactorWrapper>
    );
};

export default ConfidenceFactorView;
