import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

export const FullPageErrorCenteringContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  flex: 1;
`;

export const FullPageErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  gap: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

export const FullPageErrorHeading = styled.h2`
  color: ${themeColors.body};
`;

export const FullPageErrorText = styled.p``;
