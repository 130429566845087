/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { apiConfig, get, put } from 'api/api';
import LoaderFullPage from 'components/LoaderFullPage';
import { authResult } from 'lib/authConfig';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
    CustomerDetailContainer,
    CustomerDetailHeader,
    CustomerDetailInformationButtonContainer,
    CustomerDetailInformationLabel,
    CustomerDetailInformationLeft,
    CustomerDetailInformationRight,
    CustomerDetailInformationRow,
    CustomerDetailInformationRowContainer,
    CustomerDetailInformationScheduleWrapper,
    CustomerDetailInformationValue,
    CustomerDetailInformationWrapper,
    CustomerDetailTitle,
    TooltipButtonContainer
} from './styled';
import _ from 'lodash';
import { PrimaryButton } from 'components/common/buttons';
import ConnectionBanner from 'components/ConnectionBanner';
import Select from 'components/forms/inputs/Select';
import { themeColors } from 'assets/theme/style';

export interface CustomerDetailType {
    connector: CustomerDetailConnectorType;
    createdDate: string;
    active: boolean;
    customerUid: string;
    name: string;
    registrationNumber: string;
    contactEmail: string;
    contactName: string;
}

export interface CustomerDetailConnectorType {
    connectorUid: string;
    name: string;
    status: string;
    type: string;
    lastActivityDate: string;
    schedule: string | null;
}

export interface ScheduleOptionsData {
    value: string;
    name: string;
}

const CustomerDetail: FC = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [editSchedule, setEditSchedule] = useState<any>(false);
    const [customerSchedule, setCustomerSchedule] = useState<any>(null);
    const [customerSelectedHour, setCustomerSelectedHour] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showScheduleHourSelectBox, setShowScheduleHourSelectBox] = useState<boolean>(false);
    const [customer, setCustomer] = useState<CustomerDetailType | null>(null);
    const [scheduleLoading, setScheduleLoading] = useState<any>(false);

    let count = 0;

    const scheduleOptions: ScheduleOptionsData[] = [
        { value: 'Daily', name: 'Daily' },
        { value: 'Hourly', name: 'Hourly' },
        { value: 'None', name: 'None' }
    ];

    const scheduleHourOptions: () => ScheduleOptionsData[] = () => {
        const hourOptions: ScheduleOptionsData[] = [];

        Array.from({ length: 24 }).forEach((_, i) => {
            const hour = (i < 10 ? '0' : '') + i;
            const cronValue = `0 0 ${i} * * *`;
            hourOptions.push({ value: cronValue, name: `${hour}:00` });
        });

        return hourOptions;
    };

    const cronToHour: (cronValue: string) => string | null = (cronValue) => {
        if (cronValue === null) return null;

        const cronParts = cronValue.split(' ');
        if (cronParts.length !== 6) {
            return null;
        }

        const hourPart = cronParts[2];
        const hour = parseInt(hourPart, 10);
        if (isNaN(hour) || hour < 0 || hour > 23) {
            return null;
        }
        const hourString = hour < 10 ? `0${hour}:00` : `${hour}:00`;

        return hourString;
    };

    const updateSchedule: () => void = () => {
        if (showScheduleHourSelectBox) {
            postSchedule(customerSelectedHour);
        } else {
            if (customerSchedule === 'None') {
                postSchedule(null);
            }
            if (customerSchedule === 'Hourly') {
                postSchedule('0 0 * * * *');
            }
        }
    };

    const postSchedule: (schedule: string | null) => void = async (schedule) => {
        setScheduleLoading(true);
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        await put(
            apiConfig.updateCustomerSchedule(`${params.customerId}`),
            {
                schedule: schedule
            },
            token
        ).then(() => {
            setScheduleLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        if (count === 0) {
            count++;
            getCustomer();
        }
    }, []);

    useEffect(() => {
        if (customer?.connector?.schedule === null) {
            setCustomerSchedule('None');
        } else if (customer?.connector?.schedule === '0 0 * * * *') {
            setCustomerSchedule('Hourly');
        } else {
            setCustomerSchedule('Daily');
            customer?.connector?.schedule && setCustomerSelectedHour(customer?.connector?.schedule);
        }
    }, [customer]);

    useEffect(() => {
        if (customerSchedule === 'Daily') {
            setShowScheduleHourSelectBox(true);
        } else {
            setShowScheduleHourSelectBox(false);
        }
    }, [customerSchedule]);

    const getCustomer: () => any = async () => {
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        get(apiConfig.customerDetail(`${params.customerId}`), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const {
                    connector,
                    createdDate,
                    active,
                    customerUid,
                    name,
                    registrationNumber,
                    contactEmail,
                    contactName
                }: CustomerDetailType = responseData.value[0];

                setCustomer({
                    connector,
                    createdDate,
                    active,
                    customerUid,
                    name,
                    registrationNumber,
                    contactEmail,
                    contactName
                });
                setLoading(false);
            });
    };

    const renderAnalyticsButton: () => JSX.Element = () => (
        <PrimaryButton
            disabled={!customer?.connector}
            width="fit-content"
            text="Go to Analytics"
            clickHandler={() => navigate(`/analytics/${params.customerId}`)}
        />
    );
    return (
        <CustomerDetailContainer>
            {loading ? (
                <LoaderFullPage />
            ) : (
                <>
                    <CustomerDetailHeader>
                        <CustomerDetailTitle>
                            Customer Name: {customer?.name || ''}
                        </CustomerDetailTitle>
                    </CustomerDetailHeader>
                    <CustomerDetailInformationWrapper>
                        <CustomerDetailInformationRowContainer>
                            <CustomerDetailInformationRow>
                                <CustomerDetailInformationRight>
                                    <CustomerDetailInformationLabel>
                                        Contact Name:
                                    </CustomerDetailInformationLabel>
                                    <CustomerDetailInformationValue>
                                        {customer?.contactName || ''}
                                    </CustomerDetailInformationValue>
                                </CustomerDetailInformationRight>
                            </CustomerDetailInformationRow>
                            <CustomerDetailInformationRow>
                                <CustomerDetailInformationLeft>
                                    <CustomerDetailInformationLabel>
                                        Contact Email Address:
                                    </CustomerDetailInformationLabel>
                                    <CustomerDetailInformationValue>
                                        {customer?.contactEmail || ''}
                                    </CustomerDetailInformationValue>
                                </CustomerDetailInformationLeft>
                                <CustomerDetailInformationRight>
                                    <CustomerDetailInformationLabel>
                                        Date Created:
                                    </CustomerDetailInformationLabel>
                                    <CustomerDetailInformationValue>
                                        {formatDateTime(customer?.createdDate || '', 'dd/MM/yyyy')}
                                    </CustomerDetailInformationValue>
                                </CustomerDetailInformationRight>
                            </CustomerDetailInformationRow>
                            <CustomerDetailInformationRow>
                                <CustomerDetailInformationRight>
                                    <CustomerDetailInformationLabel>
                                        Schedule:
                                    </CustomerDetailInformationLabel>
                                    <CustomerDetailInformationScheduleWrapper>
                                        {customerSchedule && (
                                            <Select
                                                background={themeColors.white}
                                                menuItems={scheduleOptions}
                                                name={customerSchedule}
                                                value={customerSchedule}
                                                placeholder="Select a schedule"
                                                changeHandler={(event: any) => {
                                                    setEditSchedule(true);
                                                    setCustomerSchedule(event.target.value);
                                                }}
                                            />
                                        )}
                                        {showScheduleHourSelectBox && (
                                            <Select
                                                background={themeColors.white}
                                                menuItems={scheduleHourOptions()}
                                                name={cronToHour(customerSelectedHour)}
                                                value={customerSelectedHour}
                                                placeholder="Select hour"
                                                changeHandler={(event: any) => {
                                                    setEditSchedule(true);
                                                    setCustomerSelectedHour(event.target.value);
                                                }}
                                            />
                                        )}
                                        <CustomerDetailInformationButtonContainer>
                                            <PrimaryButton
                                                disabled={scheduleLoading || !editSchedule}
                                                width="fit-content"
                                                text="Update"
                                                clickHandler={() => updateSchedule()}
                                            />
                                        </CustomerDetailInformationButtonContainer>
                                    </CustomerDetailInformationScheduleWrapper>
                                </CustomerDetailInformationRight>
                            </CustomerDetailInformationRow>
                        </CustomerDetailInformationRowContainer>
                        <CustomerDetailInformationButtonContainer>
                            <PrimaryButton
                                width="fit-content"
                                text="Go to Invoices"
                                clickHandler={() => navigate(`/invoices/${params.customerId}`)}
                            />
                            {!customer?.connector ? (
                                <Tooltip
                                    title={`There is no active connector for ${customer?.name}.`}
                                >
                                    <TooltipButtonContainer>
                                        {renderAnalyticsButton()}
                                    </TooltipButtonContainer>
                                </Tooltip>
                            ) : (
                                renderAnalyticsButton()
                            )}
                        </CustomerDetailInformationButtonContainer>
                    </CustomerDetailInformationWrapper>

                    {params.customerId && (
                        <ConnectionBanner customerId={params.customerId} fullWidth />
                    )}
                </>
            )}
        </CustomerDetailContainer>
    );
};

export default CustomerDetail;
