
import { boxShadow, device, size, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const InvoiceDetailContainer = styled.div`
  width: calc(100% - 64px);
  max-width: ${size.desktopM};
  display: flex;
  padding: 0 32px;
  flex-direction: column;
  gap: 20px;
`;

export const InvoiceDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
`;

export const InvoiceDetailTitle = styled.h1`
  margin: 0;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
`;

export const InvoiceDetailHeaderInformationWrapper = styled.div`
  display: flex;
  padding: 15px;
  background-color: ${themeColors.lightGrey};
  justify-content: space-between;
  align-items: baseline;
  gap: 8px;
  width: calc()(100% - 30px);
`;

export const InvoiceDetailHeaderInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InvoiceDetailHeaderInformationTitle = styled.p`
  margin: 0;
  font-size: ${fontSizes.small};
`;

export const InvoiceDetailHeaderInformationValue = styled.h1`
  margin: 0;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
`;

export const InvoiceDetailTotalAmountTitle = styled.p`
  margin: 0;
  text-align: right;
  font-size: ${fontSizes.small};
`;

export const InvoiceDetailTotalTaxAmount = styled.p`
  margin: 0;
  text-align: right;
  color: ${themeColors.darkGrey};
  font-size: ${fontSizes.xsmall};
`;

export const InvoiceDetailTotalAmountValue = styled.h1`
  margin: 0;
  font-size: ${fontSizes.xLarge};
  font-weight: ${fontWeights.bold};
`;

export const InvoiceDetailInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: ${boxShadow};
  background-color: ${themeColors.white};
  border-radius: 8px;
  padding: 32px;
  @media ${device.mobileOnly} {
      padding: 16px;
  }
`;

export const InvoiceDetailInformationRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.mobileOnly} {
      width: calc(100% - 32px);
  }
`;

export const InvoiceDetailInformationRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  @media ${device.mobileOnly} {
      grid-template-columns: 1fr;
      gap: 16px;
  }
  margin-bottom: 16px;
`;

export const InvoiceDetailParticipantsInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;


export const InvoiceDetailInformationRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${device.mobileOnly} {
      flex-direction: column;
  }
`;

export const InvoiceDetailInformationLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${device.mobileOnly} {
      flex-direction: column;
  }
`;

export const InvoiceDetailInformationLabel = styled.p`
  display: flex;
  ${typography.bodyRegular};
  text-align: right;
  @media ${device.mobileOnly} {
      text-align: left;
  }
`;

export const InvoiceDetailInformationAddress = styled.div`
  display: flex;
  ${typography.bodyRegular};
  text-align: right;
  flex-direction: column;
  gap: 8px;
`;

export const InvoiceDetailExtensionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`;

export const InvoiceDetailExtensionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
`;

export const InvoiceDetailExtensionsHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;
`;

export const InvoiceDetailExtensionsData = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;
`;


export const InvoiceDetailExtensionsHeaderTitle = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  ${typography.tableHeader};
  background-color: ${themeColors.lightGrey};
`;

export const InvoiceDetailExtensionsValue = styled.div`
  display: flex;
  ${typography.captionRegular};
  padding: 8px;
  width: 100%;
`;

export const InvoiceDetailInformationTitle = styled.p`
  display: flex;
  ${typography.bodyBold};
  text-align: right;
  @media ${device.mobileOnly} {
      text-align: left;
  }
`;

export const InvoiceDetailInformationValue = styled.p`
  display: flex;
  ${typography.bodySemiBold};
  text-align: right;
`;

export const InvoiceDetailLineItemsTotalWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const InvoiceDetailLineItemsTotalConatiner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;
  width: 60%;
  gap: 8px;
`;

export const InvoiceDetailBackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typography.bodyBold};
  cursor: pointer;
`;
