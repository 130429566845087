/* eslint-disable react-hooks/exhaustive-deps */

import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Chip } from '@mui/material';
import { apiConfig, put } from 'api/api';
import { themeColors } from 'assets/theme/style';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { PrimaryButton } from 'components/common/buttons';
import SecondaryButton from 'components/common/buttons/SecondaryButton';
import TextInput from 'components/forms/inputs/TextInput';
import { authResult } from 'lib/authConfig';
import Context from 'context';
import { triggerSnackbar } from 'context/action/app';
import { FC, useEffect, useState, useContext } from 'react';
import {
    CustomerInvitationViewButtonContainer,
    CustomerInvitationViewButtonText,
    CustomerInvitationViewButtonWrapper,
    CustomerInvitationViewContainer,
    CustomerInvitationViewDescription,
    CustomerInvitationViewFooter,
    CustomerInvitationViewMessageError,
    CustomerInvitationViewMessageSent,
    CustomerInvitationViewMessageWarning,
    CustomerInvitationViewTitle,
    CustomerInvitationViewWrapper
} from './styled';

interface CustomerInvitationViewProps {
    closeHandler: () => void;
}

interface IEmailsValidate {
    email: string;
    valid: boolean;
}

interface InvitationStatus {
    error: boolean;
    warning: boolean;
    status: boolean;
}

const CustomerInvitationView: FC<CustomerInvitationViewProps> = ({ closeHandler }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [emails, setEmails] = useState<string>('');
    const [emailTags, setEmailTags] = useState<IEmailsValidate[]>([]);
    const [allEmailsValid, setAllEmailsValid] = useState<boolean>(false);
    const [state, dispatch] = useContext(Context);
    const [messages, setMessages] = useState<InvitationStatus>({
        error: false,
        warning: false,
        status: false
    });
    const [loading, setLoading] = useState<boolean>(false);
    const resetStatus: () => void = async () => {
        setMessages({ error: false, warning: false, status: false });
    };

    const sendEmailsClickHandler: (email: string) => void = async (email) => {
        resetStatus();
        setEmails(email);

        const tags = emailTags.map((e) => e.email).join(',');
        const emailStr = email.trim();

        if (tags !== emailStr) setAllEmailsValid(false);
    };

    const inviteCustomersClickHandler: () => void = async () => {
        setLoading(true);

        const mappedEmails: any = emailTags.map(({ email }) => {
            return {
                email,
                code: '',
                name: ''
            };
        });

        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        mappedEmails.length > 0 &&
            (await put(
                apiConfig.inviteCustomers(),
                {
                    sendEmail: true,
                    invites: mappedEmails
                },
                token
            ).then((response: any) => {
                response.status === 200
                    ? setMessages({ ...messages, status: true })
                    : setMessages({ ...messages, error: true });
            }));
        setEmails('');
        setLoading(false);
    };

    const handleDelete: (e: IEmailsValidate) => void = (e) => {
        const tagIndex = emailTags.map((tag) => tag.email).indexOf(e.email);

        if (tagIndex > -1) {
            const arr = [...emailTags];
            arr.splice(tagIndex, 1);

            setEmailTags(arr);
            setAllEmailsValid(arr.map((v) => v.valid).filter((d) => !d).length === 0);
        }
    };

    const validateEmails: () => void = () => {
        const tmpArr = [];
        const emailArr = emails.trim().split(',');
        for (const index in emailArr) {
            if (emailTags.findIndex((e) => e.email === emailArr[index]) < 0) {
                tmpArr.push(emailArr[index]);
            }
        }
        const validatedData: IEmailsValidate[] = tmpArr.map((email) => ({
            email,
            valid: isEmailValid(email)
        }));

        setEmails('');
        setEmailTags((tags) => [...tags, ...validatedData]);
        setAllEmailsValid(
            [...emailTags, ...validatedData].map((v) => v.valid).filter((d) => !d).length === 0
        );
    };

    const isEmailValid: (email: string) => boolean = (email) => {
        const regex: RegExp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email.trim());
    };

    useEffect(() => {
        if (messages.status) {
            triggerSnackbar(dispatch, {
                open: true,
                data: {
                    message: (
                        <CustomerInvitationViewMessageSent>
                            <CheckCircleOutlineIcon /> Invitation(s) sent
                        </CustomerInvitationViewMessageSent>
                    )
                }
            });
            resetStatus();
            setEmails('');
            setEmailTags([]);
        }
        if (messages.error) {
            triggerSnackbar(dispatch, {
                open: true,
                data: {
                    role: 'error',
                    message: (
                        <CustomerInvitationViewMessageError>
                            <ErrorOutlineIcon /> Sorry, your invitation(s) failed to send. Please
                            try again later.
                            <br />
                            If the issue persists please contact us at support@finecta.com
                        </CustomerInvitationViewMessageError>
                    )
                }
            });
            resetStatus();
        }
        if (messages.warning) {
            triggerSnackbar(dispatch, {
                open: true,
                data: {
                    role: 'warning',
                    message: (
                        <CustomerInvitationViewMessageWarning>
                            <ErrorOutlineIcon /> Invalid emails
                        </CustomerInvitationViewMessageWarning>
                    )
                }
            });
        }
    }, [messages]);

    return (
        <CustomerInvitationViewWrapper>
            <CustomerInvitationViewContainer>
                <CustomerInvitationViewTitle>
                    Invite your customers to connect
                </CustomerInvitationViewTitle>
                <CustomerInvitationViewDescription>
                    Enter one or more emails separated by a comma, and we'll invite them to connect
                    and share their data with you
                </CustomerInvitationViewDescription>
                <TextInput
                    defaultValue={emails}
                    multiline
                    changeHandler={(event) => sendEmailsClickHandler(event.target.value)}
                    customProps={{
                        startAdornment: emailTags.map((e, i) => (
                            <Chip
                                key={i}
                                style={{
                                    backgroundColor: e.valid
                                        ? themeColors.success
                                        : themeColors.error,
                                    marginRight: '4px',
                                    marginBottom: '8px'
                                }}
                                label={e.email}
                                onDelete={() => handleDelete(e)}
                            />
                        ))
                    }}
                />

                <CustomerInvitationViewFooter>
                    <CustomerInvitationViewButtonWrapper>
                        <CustomerInvitationViewButtonContainer>
                            <SecondaryButton clickHandler={closeHandler}>
                                <CustomerInvitationViewButtonText>
                                    Close
                                </CustomerInvitationViewButtonText>
                            </SecondaryButton>
                        </CustomerInvitationViewButtonContainer>

                        <CustomerInvitationViewButtonContainer>
                            {allEmailsValid && !loading && (
                                <PrimaryButton
                                    clickHandler={inviteCustomersClickHandler}
                                    disabled={loading}
                                    width="180px"
                                >
                                    <CustomerInvitationViewButtonText>
                                        Invite Customer​
                                    </CustomerInvitationViewButtonText>
                                </PrimaryButton>
                            )}
                            {!allEmailsValid && !loading && (
                                <PrimaryButton
                                    clickHandler={validateEmails}
                                    disabled={!emails}
                                    width="180px"
                                >
                                    <CustomerInvitationViewButtonText>
                                        Validate emails​
                                    </CustomerInvitationViewButtonText>
                                </PrimaryButton>
                            )}
                            {loading && (
                                <PrimaryButton
                                    clickHandler={() => console.log('test')}
                                    width="180px"
                                    backgroundColor={themeColors.warning}
                                >
                                    <>
                                        <CustomerInvitationViewButtonText>
                                            sending
                                        </CustomerInvitationViewButtonText>
                                        <LoaderInPage />
                                    </>
                                </PrimaryButton>
                            )}
                        </CustomerInvitationViewButtonContainer>
                    </CustomerInvitationViewButtonWrapper>
                </CustomerInvitationViewFooter>
            </CustomerInvitationViewContainer>
        </CustomerInvitationViewWrapper>
    );
};

export default CustomerInvitationView;
