export const convertNumberToShortString: (val: number, decimalPlaces: number) => string = (
  val: number,
  decimalPlaces: number
) =>
{
  if (val < 1000) {
    return val.toFixed(decimalPlaces);
  } else if (val < 1000000) {
    return (val / 1000).toFixed(decimalPlaces) + 'K';
  } else if (val < 1000000000) {
    return (val / 1000000).toFixed(decimalPlaces) + 'M';
  } else {
    return (val / 1000000000).toFixed(decimalPlaces) + 'B';
  }
};


export const formatNumberToShortString: (value: number) => string = (
  value: number
) =>{
  if (Math.abs(value) >= 1e6) {

    const formattedValue = (value / 1e6).toFixed(2);
    return formattedValue.replace(/\.00$/, '') + 'M';
  } else if (Math.abs(value) >= 1e3) {

    const formattedValue = (value / 1e3).toFixed(2);
    return formattedValue.replace(/\.00$/, '') + 'K';
  } else {
    return value.toFixed(2);
  }
};

