import Context from 'context';
import { initialState, mainReducer } from 'context/reducers';
import { FC, ReactNode, useReducer } from 'react';

type MainContextProps = { children: ReactNode };

const MainContext: FC<MainContextProps> = ({ children }) => {
    const store = useReducer(mainReducer, initialState);
    return <Context.Provider value={store}>{children}</Context.Provider>;
};

export default MainContext;
