/* eslint-disable react-hooks/exhaustive-deps */

import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { apiConfig, get, post } from 'api/api';
import DataGrid from 'components/DataGrid';
import LoaderFullPage from 'components/LoaderFullPage';
import CustomerMenu from 'components/customerMenu';
import { authResult } from 'lib/authConfig';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import { Dispatch, FC, SetStateAction, useEffect, useState, useContext } from 'react';
import Context from 'context';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import Select from 'components/forms/inputs/Select';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    AccordionStyled,
    CustomerIconContainer,
    CustomerViewContainer,
    CustomerViewFilterContainer,
    CustomerViewFilterWrapper,
    CustomerViewTableAction,
    CustomerViewTableContainer,
    CustomerViewTableCustomerName,
    SelectLabel
} from './styled';
import { Link } from 'react-router-dom';
import { isMobile } from 'lib/helpers/mobile';
import { themeColors } from 'assets/theme/style';
import SnackbarContainer from 'components/snackbar/Snackbar';

const fieldDictionary = {
    customer: 'name',
    contact: 'contactEmail',
    connectors: 'connector/name',
    connectorStatus: 'connector/status',
    lastImport: 'connector/lastActivityDate',
    schedule: 'connector/schedule'
};

const CustomersView: FC = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const customerStatus: string = searchParams.get('status') || '';
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [loading, setLoading] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(-1);
    const [data, setData]: [DataGridRowItem[], Dispatch<SetStateAction<DataGridRowItem[]>>] =
        useState<DataGridRowItem[]>([]);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<string>('connector/lastActivityDate desc, name asc');
    const [orderDirection, setOrderDirection] = useState<string>('desc');
    const [status, setStatus] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const [authenticationEmailLoading, setAuthenticationEmailLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [state, dispatch] = useContext(Context);
    const snackbarDataRole: 'success' | 'warning' | 'error' | 'alert' | undefined =
        state?.app?.snackbarData?.role;

    const customerTableHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem('customerUid', 'customerUid', {
            display: false
        }),
        new DataGridHeaderItem('Customer', 'customer', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any, tableMeta: any) => (
                <CustomerViewTableCustomerName
                    onClick={() => navigate(`/customers/${tableMeta.rowData[0] || value}`)}
                >
                    {value}
                </CustomerViewTableCustomerName>
            )
        }),

        new DataGridHeaderItem(
            'Last Import',
            'lastImport',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem('Contact', 'contact', new DataGridHeaderOptions(false, true, true)),
        new DataGridHeaderItem('Schedule', 'schedule', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any) => (
                <p>
                    {value === '' && 'None'}
                    {value === '0 0 * * * *' && 'Hourly'}
                    {value !== '0 0 * * * *' && value !== '' && `Daily at ${cronToHour(value)}`}
                </p>
            )
        }),

        new DataGridHeaderItem(
            'Connectors',
            'connectors',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem(
            'Status',
            'connectorStatus',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem('', '', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any, tableMeta: any) => (
                <CustomerIconContainer>
                    {isMobile() ? (
                        <>
                            <Link to={`/invoices/${tableMeta.rowData[0] || value}`}>
                                Invoices / Bills |{' '}
                            </Link>
                            <Link to={`/customers/${tableMeta.rowData[0] || value}`}>
                                Customer Details
                            </Link>
                            <Link to={`/analytics/${tableMeta.rowData[0] || value}`}>
                                | Analytics
                            </Link>
                            {tableMeta.rowData[6] === 'Requires Reconnection' && (
                                <p
                                    onClick={() =>
                                        rowClickEmailHandler(tableMeta.rowData[0] || value)
                                    }
                                >
                                    Send Re-authenticate Email
                                </p>
                            )}
                        </>
                    ) : (
                        <CustomerMenu
                            customerUid={tableMeta.rowData[0] || value}
                            status={tableMeta.rowData[6]}
                            clickHandler={rowClickEmailHandler}
                        />
                    )}
                </CustomerIconContainer>
            )
        })
    ];

    const rowClickEmailHandler: (id: string) => void = async (id) => {
        setAuthenticationEmailLoading(true);
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        post(apiConfig.sendReAuthenticationEmail(`${id}`), null, token).then((response: any) => {
            response.status === 200
                ? setSnackbarMessage('Email sent')
                : setSnackbarMessage('Email failed to send');
            setAuthenticationEmailLoading(false);
            setSnackbarOpen(true);
            var millisecondsToWait = 2000;
            setTimeout(function () {
                setSnackbarOpen(false);
                setSnackbarMessage('');
            }, millisecondsToWait);
        });
    };

    const getValueFromStatus: (status: string) => string = (status) => {
        switch (status) {
            case 'requiresReconnection':
                return 'Expired Connections';
            case 'active':
                return 'Active Connections';
            case 'connections':
                return 'Any Connections';
            case 'inactive':
                return 'InActive Connections';
            case 'allCustomers':
                return 'Everything';
            default:
                return '';
        }
    };

    useEffect(() => {
        setLoading(true);
        setPage(0);
        return () => {
            setPage(0);
        };
    }, []);

    useEffect(() => {
        if (page > -1) getCustomers();
    }, [orderBy, page, rowsPerPage, searchValue]);

    useEffect(() => {
        setStatus(customerStatus);
        !searchValue && setSearchValue(customerStatus);
    }, [customerStatus]);

    const cronToHour: (cronValue: string) => string | null = (cronValue) => {
        if (cronValue === null) return null;

        const cronParts = cronValue.split(' ');
        if (cronParts.length !== 6) {
            return null;
        }

        const hourPart = cronParts[2];
        const hour = parseInt(hourPart, 10);
        if (isNaN(hour) || hour < 0 || hour > 23) {
            return null;
        }
        const hourString = hour < 10 ? `0${hour}:00` : `${hour}:00`;

        return hourString;
    };

    const getCustomers: () => any = async () => {
        setTableLoading(true);
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        let filter: string = `&$filter=connector/status eq 'Active'`;

        if (status === 'requiresReconnection' || searchValue === 'requiresReconnection') {
            filter = `&$filter=connector/status eq 'Reauthenticate'`;
        }
        if (status === 'allCustomers' || searchValue === 'allCustomers') {
            filter = ``;
        }
        if (status === 'connections' || searchValue === 'connections') {
            filter = `&$filter=connector ne null`;
        }
        if (status === 'active' || searchValue === 'active') {
            filter = `&$filter=connector/status eq 'Active'`;
        }
        if (status === 'inactive' || searchValue === 'inactive') {
            filter = `&$filter=connector/status eq 'Inactive'`;
        }

        get(
            apiConfig.customers(
                `?$count=true&$expand=connector&$top=${rowsPerPage}${filter}&$orderby=${orderBy}&$skip=${
                    page === -1 ? 0 : rowsPerPage * page
                }`
            ),
            token
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const mappedData = responseData.value.map((d: any) => ({
                    customerUid: d.customerUid,
                    customer: d.name,
                    lastImport: d.connector
                        ? formatDateTime(
                              d.connector?.lastActivityDate || '',
                              'dd MMM yyyy HH:mm:ss'
                          )
                        : '-',
                    contact: d.contactEmail || '',
                    schedule: d.connector?.schedule || '',
                    connectors: d.connector
                        ? d.connector.type.charAt(0).toUpperCase() + d.connector.type.slice(1)
                        : 'No active connector',
                    connectorStatus: d.connector
                        ? d.connector.status === 'Reauthenticate'
                            ? 'Requires Reconnection'
                            : d.connector.status
                        : ''
                }));

                setTotalRecordCount(responseData['@odata.count']);
                setData([...mappedData]);
                setLoading(false);
                setTableLoading(false);
            });
    };

    // const rowClickHandler: (row: any) => void = (row) => navigate(`/customers/${row[0]}`);

    const columnSortChangeClickHandler = (col: any) => {
        const odataField: string | undefined = fieldDictionary[col];
        const sortable = Boolean(odataField);
        if (!sortable) return;
        const direction = orderDirection === 'asc' ? 'desc' : 'asc';
        setOrderDirection(direction);
        setOrderBy(`${fieldDictionary[col]} ${direction}`);
    };

    const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) =>
        setPage(tableState.page);

    const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) =>
        setRowsPerPage(numberOfRows);

    const customerStatusData: any = [
        { value: 'active', name: 'Active Connections' },
        { value: 'inactive', name: 'InActive Connections' },
        { value: 'requiresReconnection', name: 'Expired Connections' },
        { value: 'connections', name: 'Any Connections' },
        { value: 'allCustomers', name: 'Everything' }
    ];

    const updateSearchValue: (value: string) => void = (value) => {
        setSearchValue(value);
        searchParams.delete('status');
        setSearchParams(searchParams);
        setPage(0);
    };

    const renderLoading = () =>
        loading ? <LoaderFullPage /> : <LoaderFullPage detail="Email is sending..." />;

    return (
        <CustomerViewContainer>
            {loading || authenticationEmailLoading ? (
                renderLoading()
            ) : (
                <>
                    <CustomerViewFilterContainer>
                        <AccordionStyled disableGutters defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                FILTER OPTIONS
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingBottom: 0 }}>
                                <CustomerViewFilterWrapper>
                                    <SelectLabel>Customers With:</SelectLabel>
                                    <Select
                                        background={themeColors.white}
                                        menuItems={customerStatusData}
                                        value={searchValue || 'active'}
                                        name={getValueFromStatus(searchValue || 'active')}
                                        placeholder="Select connections status"
                                        changeHandler={(event: any) =>
                                            updateSearchValue(event.target.value)
                                        }
                                    />
                                </CustomerViewFilterWrapper>
                            </AccordionDetails>
                        </AccordionStyled>
                    </CustomerViewFilterContainer>
                    <CustomerViewTableAction>
                        You have {totalRecordCount} registered customers
                    </CustomerViewTableAction>
                    <CustomerViewTableContainer>
                        <DataGrid
                            loading={tableLoading}
                            headers={customerTableHeaders}
                            data={data}
                            ariaLabel="Customer table"
                            ariaCaption="A table that shows customers."
                            rowsPerPage={rowsPerPage}
                            totalRows={totalRecordCount}
                            fixedHeader
                            tableChangeHandler={tableChangeHandler}
                            serverSide
                            changeRowsPerPageHandler={rowsPerPageHandler}
                            columnSortChangeClickHandler={columnSortChangeClickHandler}
                            page={page === -1 ? 0 : page}
                            lastChildDiv
                            showFooter={totalRecordCount > 20 ? true : false}
                        />
                    </CustomerViewTableContainer>
                </>
            )}
            <SnackbarContainer
                open={snackbarOpen}
                message={snackbarMessage}
                role={snackbarDataRole}
            />
        </CustomerViewContainer>
    );
};

export default CustomersView;
