import { themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';
import { G2FSSContainer } from 'components/common/styled';
import styled from 'styled-components';

export const ResetPasswordContainer = styled(G2FSSContainer)``;

export const ResetPasswordHeadingContainer = styled(G2FSSContainer)`
    margin-bottom: 24px;
`;

export const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TextFieldErrorMessage = styled.div`
  display: flex;
  font-size: ${fontSizes.xsmall};
  color: ${themeColors.error};
  margin-top: -12px;
`;

export const ResetPasswordFooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  height: 100px;
`;

export const ResetPasswordConfirmEmailContainer = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 300px);
  position: relative;
`;