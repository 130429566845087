import logo from 'assets/logos/finecta-logo.png';
import Typography from 'components/typography';
import { FC } from 'react';
import { FooterContainer, FooterLogo, FooterTextContainer, FooterWrapper } from './styled';

const Footer: FC = () => {
    return (
        <FooterWrapper>
            <FooterContainer>
                <FooterTextContainer>
                    <Typography tag="subheading1">Powered By</Typography>
                </FooterTextContainer>
                <FooterLogo src={logo} alt="finecta logo" />
            </FooterContainer>
        </FooterWrapper>
    );
};

export default Footer;
