import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { ButtonStyled } from 'components/common/buttons/styled';
import { themeColors } from 'assets/theme/style';

export const SecondaryButtonStyled = styled(ButtonStyled)<ButtonProps>`
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
    :disabled {
        background-color: ${themeColors.lightGrey};
        border-color: ${themeColors.darkGrey};
        color: ${themeColors.darkGrey};
    }
`;
