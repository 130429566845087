import styled from 'styled-components';
import { device, themeColors } from 'assets/theme/style';
import { fontSizes } from 'assets/theme/typography';

export const FooterWrapper = styled.div`
  height: 50px;
  background-color: ${themeColors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: flex-end;
    background-color: ${themeColors.dark};
    gap: 10px;
    @media ${device.mobileOnly} {
      justify-content: center;
    }
`;

export const FooterTextContainer = styled.div`
    h6{
      color: ${themeColors.white};
      font-size: ${fontSizes.small};
    }
`;

export const FooterLogo = styled.img`
    height: 12px;
    margin-right: 32px;
    @media ${device.mobileOnly} {
      margin-right: 0;
    }
`;

