import { device, themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';
import { OutlinedInputProps } from './OutlinedInput';
import OutlinedInput from '@mui/material/OutlinedInput';

export const OutlinedInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OutlinedInputStyled = styled(OutlinedInput)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background-color: ${themeColors.white};

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${themeColors.body};
      -webkit-box-shadow: 0 0 0px 1000px ${themeColors.white} inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :disabled {
    background-color: ${themeColors.lightGrey};
    color: ${themeColors.darkGrey} !important;
  }
  .MuiOutlinedInput-root {
    ${typography.bodyRegular};
    @media ${device.mobileOnly} {
      font-size: ${fontSizes.small};
    }
  }
  .MuiOutlinedInput-input {
    height: 36px;
    border-radius: 6px;
    font-size: ${fontSizes.regular};
    padding: 0 6px 0 12px;
    border: none;
    
    &.Mui-disabled {
      background-color: ${themeColors.darkGrey};
      color: ${themeColors.lightGrey} !important;
      -webkit-text-fill-color: ${themeColors.lightGrey} !important;
    }
    @media ${device.mobileOnly} {
      font-size: ${fontSizes.small};
      padding: 0 6px;
      height: 28px;
    }
  }
`;

export const OutlinedInputLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  ${typography.bodySemiBold};
  margin-bottom: 4px;
`;

export const OutlinedInputMessage = styled.span<OutlinedInputProps>`
  color: ${({ errorMessage }) => errorMessage && themeColors.error};
  ${typography.captionRegular};
`;
