import { Alert, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import TextField from '@mui/material/TextField';
import { apiConfig, post } from 'api/api';
import { inputSxStyles, themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import { Link } from 'components/link/styled';
import { useNavigate } from 'react-router-dom';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import Typography from 'components/typography';
import { _recaptchaKey } from 'config';
import { FC, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RegisterFormData } from 'utils/interfaces/types/registerFormType';
import {
    FormStyled,
    RegisterConfirmEmailContainer,
    RegisterContainer,
    RegisterFooterContainer,
    RegisterHeadingContainer,
    TextFieldErrorMessage,
    FormControlLabelContainer
} from './styled';
import { SubmitHandler, useForm } from 'react-hook-form';

export interface IContactForm {
    email: string;
    fullName: string;
    companyName: string;
}

const Register: FC = () => {
    const navigate = useNavigate();
    const [recaptchaPassed, setRecaptchaPassed] = useState<boolean>(false);
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [resendCount, setResendCount] = useState<number>(0);
    const [showResendMesssage, setShowResendMesssage] = useState<boolean>(false);
    const [formData, setFormData] = useState<RegisterFormData>({
        email: '',
        fullName: '',
        companyName: '',
        termsAndConditions: false,
        marketingOptIn: false,
        hostName: window.location.hostname
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<IContactForm>({ mode: 'onChange', reValidateMode: 'onChange' });

    const signUpClickHandler: () => void = () => {
        if (loading) return;
        setLoading(true);
        const postModel = {
            email: formData.email,
            displayName: formData.fullName,
            termsAndConditionsAgree: formData.termsAndConditions,
            companyName: formData.companyName,
            marketingEmailsOptIn: formData.marketingOptIn,
            redirectUrl: `${window.location.origin}/signup`
        };
        post(apiConfig.register(), postModel, '').then((res) => {
            if (res.ok) {
                setLoading(false);
                setSubmittedForm(true);
            } else {
                setError(true);
                setLoading(false);
            }
        });
    };

    const ResendClickHandler: () => void = () => {
        signUpClickHandler();
        setShowResendMesssage(true);
        setTimeout(() => {
            setShowResendMesssage(false);
        }, 3000);
    };

    const renderRegisterView: () => JSX.Element = () => (
        <>
            <RegisterHeadingContainer>
                <Typography tag="h1" fontWeight="regular">
                    Register for <span style={{ fontWeight: 700 }}>your free G2F trial</span>
                </Typography>
                <Typography tag="subheading1">
                    Free trial lasts for 30 days. During that time you may connect up to 5
                    customers.
                </Typography>
                {error && (
                    <Alert severity="error">
                        Something went wrong submitting this form. Please contact us.
                    </Alert>
                )}
            </RegisterHeadingContainer>
            <FormStyled
                id="register-form"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(signUpClickHandler)();
                }}
            >
                <TextField
                    value={formData.email}
                    sx={inputSxStyles}
                    id="finecta-register-email"
                    label="Email Address"
                    inputProps={register('email', {
                        required: true,
                        pattern:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({ ...formData, email: event.target.value });
                    }}
                    error={Boolean(errors['email'])}
                />
                {!!Object.keys(errors).length
                    ? errors.email && (
                          <TextFieldErrorMessage>Email is a required field.</TextFieldErrorMessage>
                      )
                    : ''}

                <TextField
                    value={formData.fullName}
                    sx={inputSxStyles}
                    id="finecta-register-name"
                    label="Full Name"
                    inputProps={register('fullName', { required: true })}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({ ...formData, fullName: event.target.value });
                    }}
                    error={Boolean(errors['fullName'])}
                />
                {!!Object.keys(errors).length
                    ? errors.fullName && (
                          <TextFieldErrorMessage>
                              Full Name is a required field.
                          </TextFieldErrorMessage>
                      )
                    : ''}
                <TextField
                    value={formData.companyName}
                    sx={inputSxStyles}
                    id="finecta-register-company"
                    inputProps={register('companyName', { required: true })}
                    label="Legal Company / Organisation Name"
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({ ...formData, companyName: event.target.value });
                    }}
                    error={Boolean(errors['companyName'])}
                />
                {!!Object.keys(errors).length
                    ? errors.companyName && (
                          <TextFieldErrorMessage>
                              Legal Company / Organisation Name is a required field.
                          </TextFieldErrorMessage>
                      )
                    : ''}
                <FormGroup>
                    <FormControlLabelContainer>
                        <Checkbox
                            checked={formData.termsAndConditions}
                            sx={{
                                color: themeColors.secondary,
                                '&.Mui-checked': {
                                    color: themeColors.primary
                                },
                                '&:hover': {
                                    backgroundColor: `${themeColors.primary}20`
                                }
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFormData({
                                    ...formData,
                                    termsAndConditions: event.target.checked
                                });
                            }}
                        />
                        <Typography tag="p" textColor="grey">
                            I agree to abide by Finecta’s terms and conditions of operation found{' '}
                            <Link
                                onClick={() =>
                                    window.open(
                                        'https://www.finecta.io/termsandconditions',
                                        '_BLANK'
                                    )
                                }
                            >
                                here
                            </Link>
                            .
                        </Typography>
                    </FormControlLabelContainer>
                    <FormControlLabelContainer>
                        <Checkbox
                            checked={formData.marketingOptIn}
                            sx={{
                                color: themeColors.secondary,
                                '&.Mui-checked': {
                                    color: themeColors.primary
                                },
                                '&:hover': {
                                    backgroundColor: `${themeColors.primary}20`
                                }
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFormData({
                                    ...formData,
                                    marketingOptIn: event.target.checked
                                });
                            }}
                        />
                        <Typography tag="p" textColor="grey">
                            Check this box to opt in to marketing emails
                        </Typography>
                    </FormControlLabelContainer>
                </FormGroup>
                <ReCAPTCHA sitekey={_recaptchaKey} onChange={(val) => setRecaptchaPassed(!!val)} />
                <PrimaryButton
                    disabled={
                        !recaptchaPassed ||
                        loading ||
                        !formData.termsAndConditions ||
                        !formData.fullName ||
                        !formData.email ||
                        !formData.companyName
                    }
                    type="submit"
                >
                    {loading ? <LoaderInPage /> : 'Sign Up'}
                </PrimaryButton>
            </FormStyled>
            <Typography tag="body1" fontWeight="bold">
                Already signed up? <Link onClick={() => navigate('/')}>Click here to sign in.</Link>
            </Typography>
        </>
    );

    const renderSubmittedView: () => JSX.Element = () => (
        <RegisterConfirmEmailContainer>
            <RegisterHeadingContainer>
                <Typography tag="h1" fontWeight="regular">
                    Confirm <span style={{ fontWeight: 700 }}>your email</span>
                </Typography>
                <Typography tag="h4" textColor="grey">
                    We have sent an email to {formData.email} with a <br /> confirmation link. Click
                    the link to continue.
                </Typography>
            </RegisterHeadingContainer>
            <RegisterFooterContainer>
                <Typography tag="body" fontWeight="bold">
                    Email not received?
                </Typography>
                <Typography tag="body" textColor="grey">
                    Check your spam folder or{' '}
                    <Link onClick={ResendClickHandler}>click here to resend email.</Link>
                </Typography>

                <Typography tag="body1" textColor="grey">
                    {showResendMesssage &&
                        `We have resent the email to ${formData.email}. Please check your inbox and
                        spam folder.`}
                </Typography>
            </RegisterFooterContainer>
        </RegisterConfirmEmailContainer>
    );

    return (
        <RegisterContainer>
            {submittedForm ? renderSubmittedView() : renderRegisterView()}
        </RegisterContainer>
    );
};

export default Register;
