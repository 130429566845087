
import styled from 'styled-components';
import { device, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';

export const IntroDashboardViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 12px 32px 32px 32px;
  @media ${device.mobileOnly} {
    padding: 0;
  }
`;

export const IntroDashboardViewHeaderTitle = styled.h2`
  margin: 0;
  font-size: ${fontSizes.xLarge};
  font-weight: ${fontWeights.bold};
`;

export const IntroDashboardViewContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;


export const IntroDashboardViewContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

export const IntroDashboardViewContentVideo = styled.div`
  display: flex;
  width: 80%;

  .video-react .video-react-big-play-button {
    top: 45%;
    left: 45%;
    @media ${device.mobileOnly} {
      top: 30%;
      left: 30%;
    }
  }
  @media ${device.mobileOnly} {
    width: 100%;
  }
`;

export const IntroDashboardViewButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;

  @media ${device.mobileOnly} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
  }
`;

export const IntroDashboardViewButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  flex-direction: column;
  gap: 10px;
`;

export const IntroDashboardViewContentStepsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
`;

export const IntroDashboardViewContentTitle = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const IntroDashboardViewContentSteps = styled.p`
  margin: 0;
  ${typography.captionRegular};
`;

export const IntroDashboardViewButtonText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const IntroDashboardViewTextBold = styled.span`
  font-weight: ${fontWeights.bold};
`;

export const CheckIconContainer = styled.div`
    .MuiSvgIcon-root {
        width: 30px;
        height: 30px;
        color: ${themeColors.success};
    }
`;

export const IntroDashboardViewHeaderIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
