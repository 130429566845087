import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import List from '@mui/material/List';
import { useMsal } from '@azure/msal-react';
import {
    NavigationMobileContainer,
    NavigationMenuIconContainer,
    ProfileListItem,
    SwipeableDrawerContainer
} from './styled';
import { navItems } from 'lib/navigationData';
import { useNavigate } from 'react-router';
import { Collapse, Divider } from '@mui/material';
import { ExpandLess, ExpandMore, Logout } from '@mui/icons-material';

const MobileNav: FC = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);
    const [openCollapse, setOpenCollapse] = useState<boolean>(false);

    const toggleDrawer: (open: boolean) => void = (open) => {
        setIsMobileMenu(open);
    };

    const handleOpenSettings: () => void = () => {
        setOpenCollapse(!openCollapse);
    };

    const navigateToPath: (path: string) => void = (path) => {
        navigate(path);
        toggleDrawer(false);
    };

    const logout: () => void = () => {
        instance.logoutRedirect();
    };

    const changePassword: () => void = () => {
        navigate('/reset-password');
    };

    return (
        <NavigationMobileContainer>
            <NavigationMenuIconContainer onClick={() => toggleDrawer(true)}>
                <MenuIcon />
            </NavigationMenuIconContainer>

            <SwipeableDrawerContainer
                anchor="right"
                open={isMobileMenu}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
            >
                <Box role="presentation">
                    <List>
                        {navItems.map((item, index) => (
                            <React.Fragment key={`${item.text}-${index}`}>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            item.submenu && item.submenu?.length > 0
                                                ? handleOpenSettings()
                                                : item.externalPath
                                                ? window.open(item.externalPath, '_BLANK')
                                                : navigateToPath(`${item.path}`);
                                        }}
                                    >
                                        <ListItemText primary={item.text} />
                                        {item.submenu &&
                                            item.submenu?.length > 0 &&
                                            (openCollapse ? <ExpandLess /> : <ExpandMore />)}
                                    </ListItemButton>
                                </ListItem>
                                {item.submenu && item.submenu?.length > 0 && (
                                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item.submenu.map((subItem, i) => (
                                                <ListItemButton
                                                    sx={{ pl: 4 }}
                                                    key={`${subItem.text}-${i}`}
                                                    onClick={() =>
                                                        subItem.externalPath
                                                            ? window.open(
                                                                  subItem.externalPath,
                                                                  '_BLANK'
                                                              )
                                                            : navigateToPath(`${subItem.path}`)
                                                    }
                                                >
                                                    <ListItemText primary={subItem.text} />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                    <List>
                        <ProfileListItem onClick={changePassword}>
                            <ManageAccountsIcon fontSize="small" /> Change Password
                        </ProfileListItem>
                        <Divider />

                        <ProfileListItem onClick={() => navigateToPath('/support')}>
                            <HelpOutlineIcon fontSize="small" />
                            Support
                        </ProfileListItem>
                        <ProfileListItem onClick={logout}>
                            <Logout fontSize="small" />
                            Logout
                        </ProfileListItem>
                    </List>
                </Box>
            </SwipeableDrawerContainer>
        </NavigationMobileContainer>
    );
};

export default MobileNav;
