import { boxShadow, device, themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { fontSizes, typography } from 'assets/theme/typography';

interface IAnalyticsChartProps {
  colour?: string;
  fontColor?: string;
  width?: string;
}

export const AnalyticsChartSalesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  flex: 1px;
  @media ${device.mobileOnly} {
    width: 100%;
  }
`;

export const AnalyticsChartSalesRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const AnalyticsChartSalesContainerSubtitle = styled.p`
  color: ${themeColors.body};
  text-align: center;
  font-weight: bold;
`;

export const AnalyticsChartSalesContainer = styled.div`
  display: flex;
  width: calc(100% - 32px);
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${themeColors.white};
  box-shadow: ${boxShadow};
`;

export const AnalyticsChartSalesChartContainer = styled.div`
  height: 200px;
  width: 100%;
`;

export const AnalyticsChartSalesHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AnalyticsChartSalesTitle = styled.h2`
  ${typography.bodyBold};
`;

export const AnalyticsChartSalesDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AnalyticsChartSalesDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
`;

export const AnalyticsChartSalesDetailBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

export const AnalyticsChartSalesDetailBox = styled.div<IAnalyticsChartProps>`
  display: flex;
  border-radius: 8px;
  font-size: ${fontSizes.small};
  padding: 8px;
  justify-content: center;
  width: calc(100% - 16px);
  min-width: 50px;
  color: ${({ fontColor }) => fontColor || themeColors.white};
  background-color: ${({ color }) => color || `${themeColors.body}95`};
`;

export const AnalyticsChartSalesDetailTitle = styled.p`
  text-align: center;
  font-size: ${fontSizes.small};
  padding: 0 16px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TooltipTitle = styled.p`
  ${typography.captionBold};
`;

export const TooltipDescription= styled.p`
  ${typography.captionRegular};
`;