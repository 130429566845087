export const apiConfig = {
  apiKeys: (query?: string) => `apikeys${query || ''}`,
  apiKey: (id: string) => `apikeys/${id}`,
  customersSync: (query?: string) => `syncs${query || ''}`,
  customers: (query?: string) => `customers${query || ''}`,
  getCustomersSync: (query?: string) => `syncs/${query || ''}`,
  postCustomersSync: (customerUid?: string) => `syncs/${customerUid || ''}`,
  customerDetail: (customerUid: string) =>
    `customers?$expand=connector&$filter=customerUid eq '${customerUid}'`,
  customerInvites: (query?: string) => `customerinvites${query || ''}`,
  inviteCustomers: (query?: string) => `customerinvites${query || ''}`,
  registerByInvite: (inviteUid: string) => `register/${inviteUid}`,
  getConnectors: (query?: string) => `ConnectorConfigurations/${query || ''}`,
  postConnectorConfiguration: () => `ConnectorConfigurations`,
  putConnectorConfiguration: (connectorId?: string) => `ConnectorConfigurations/${connectorId}`,
  deleteConnectorConfiguration: (connectorId?: string) => `ConnectorConfigurations/${connectorId}`,
  getConnectorType: (connectorType?: string) => `ConnectorTypes/${connectorType}`,
  billing: () => `billing/session`,
  billingPortalSession: () => `billing/portal-session`,
  profile: () => `profile`,
  invoices: (query?: string) => `invoices${query || ''}`,
  invoiceDetail: (customerUid: string, invoiceUid: string) =>
    `invoices?$expand=lineItems,extensions,buyer,supplier&$filter=customerUid eq '${customerUid}' and invoiceUid eq '${invoiceUid}' `,
  payables: `api/invoices?$filter=Type eq 'AP'`,
  partners: 'funderlink/partners',
  register: () => `register`,
  resetPassword: () => `register/resetpassword`,
  deleteCustomerData: (customerUid?: string) => `customers/${customerUid || ''}/delete-data`,
  resetCustomerDates: (customerUid?: string) => `customers/${customerUid || ''}/reset`,
  updateCustomerSchedule: (customerUid?: string) => `customers/${customerUid || ''}/schedule`,
  sendReAuthenticationEmail: (customerUid?: string) =>
    `customers/${customerUid || ''}/reauthenticate`,
  postFinectAi: (message?: string) => `FinectAi/invoke/${message || ''}`,
  healthCheckReport: (customerUid: string) => `reports/${customerUid}/HealthCheck`,
  companiesHouse: (customerUid: string) => `CompaniesHouse/${customerUid}`,
  companiesHouseImport: (customerUid: string) => `Syncs/CompaniesHouse/${customerUid}`
};

export async function get(endpoint: any, authenticationParameters: any) {
  return await send(endpoint, 'GET', null, authenticationParameters);
}

export async function post(endpoint: any, body: any, authenticationParameters: any) {
  return await send(endpoint, 'POST', body, authenticationParameters);
}

export async function put(endpoint: any, body: any, authenticationParameters: any) {
  return await send(endpoint, 'PUT', body, authenticationParameters);
}

export async function remove(endpoint: any, authenticationParameters: any) {
  return await send(endpoint, 'DELETE', null, authenticationParameters);
}

async function send(endpoint: any, method: string, body: null, authenticationParameters: any) {
  const headers = new Headers();

  headers.append('Authorization', `Bearer ${authenticationParameters}`);
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  const options = {
    method: method,
    body: body && JSON.stringify(body),
    headers: headers
  };

  return await fetch(`${window.spaSettings.partnerUrl}/${endpoint}`, options);
}
