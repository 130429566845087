import { boxShadow, device, size, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import { Accordion } from '@mui/material';
import styled from 'styled-components';

export const CustomerDetailContainer = styled.div`
  width: calc(100% - 64px);
  max-width: ${size.desktopM};
  display: flex;
  padding: 0 32px;
  flex-direction: column;
  gap: 20px;
  height: 77vh;
`;

export const CustomerDetailTitle = styled.h1`
  margin: 0;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
`;

export const CustomerDetailInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: ${boxShadow};
  background-color: ${themeColors.white};
  border-radius: 8px;
  padding: 32px;
  @media ${device.mobileOnly} {
      padding: 16px;
  }
`;

export const CustomerDetailInformationRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.mobileOnly} {
      width: calc(100% - 32px);
  }
`;

export const CustomerDetailInformationRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  @media ${device.mobileOnly} {
      grid-template-columns: 1fr;
      gap: 16px;
  }
  margin-bottom: 16px;
`;

export const CustomerDetailInformationRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${device.mobileOnly} {
      flex-direction: column;
  }
`;

export const CustomerDetailInformationScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CustomerDetailInformationLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${device.mobileOnly} {
      flex-direction: column;
  }
`;

export const CustomerDetailInformationLabel = styled.p`
  display: flex;
  ${typography.bodyRegular};
  text-align: right;
  @media ${device.mobileOnly} {
      text-align: left;
  }
`;

export const CustomerDetailInformationValue = styled.p`
  display: flex;
  ${typography.bodySemiBold};
  text-align: right;
`;

export const CustomerDetailInformationButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const CustomerDetailHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
      background-color: transparent !important;
      box-shadow: none;

      ::before {
          display: none;
      }

      .MuiAccordionSummary-root {
          width: 100%;
          padding: 8px 32px;
          align-items: center;
          ${typography.bodyBold};
          background-color: ${themeColors.lightGrey};
          &.Mui-expanded {
              margin: 0;
          }
      }
  }

  .MuiAccordionSummary-content {
      margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
      color: ${themeColors.body};
      transform: rotate(-90deg);
      &.Mui-expanded {
          transform: rotate(360deg);
      }
  }
  .MuiAccordionSummary-root {
      &.Mui-disabled {
          opacity: 1;
      }
  }
  &.Mui-disabled {
      background-color: transparent !important;
  }
`;

export const TooltipButtonContainer = styled.span``;

