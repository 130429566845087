import { boxShadow, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights } from 'assets/theme/typography';
import styled from 'styled-components';
import { CustomerViewContainer } from 'views/CustomersView/styled';

export const DeveloperViewContainer = styled(CustomerViewContainer)``;

export const DeveloperViewTitle = styled.h1`
    margin: 0;
    font-size: ${fontSizes.large};
    font-weight: ${fontWeights.bold};
`;

export const GenerateApiKeysTableContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    background-color: transparent!important;
    td {
        vertical-align: middle;
    }
    .MuiTextField-root{
      width: 100%;
    }
`;

export const GenerateApiKeysContainer = styled.div`
    width: 300px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const GenerateApiKeysButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const DeveloperViewDocumentationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 50px;
`;
