import { Select } from '@mui/material';
import { device, themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface SelectMessageProps {
  errorMessage?: string;
}

interface SelectStyledProps {
  disabled?: boolean;
  background?: string;
}

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectPlaceholder = styled.span`
  color: rgba(0, 0, 0, 0.4);
  ${typography.bodyRegular};
  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small};
  }
  
`;

export const SelectStyled = styled(Select)<SelectStyledProps>`
  display: flex;
  width: 100%;
  height: 36px;
  font-size: ${fontSizes.regular} !important;
  box-sizing: border-box;
  border-radius: 6px !important;
  background: ${({ disabled, background }) => setBackground(background, disabled)};
  overflow: ${({ disabled }) => disabled && 'hidden'};
  .MuiSelect-select {
    @media ${device.mobileOnly} {
      padding: 0;
    } 
  }
  .MuiOutlinedInput-root {
    border: 1px solid ${themeColors.lightGrey};
  }
  .MuiSelect-icon {
    color: ${themeColors.body};
  }
  @media ${device.mobileOnly} {
    font-size: ${fontSizes.small} !important;
    padding: 0 6px;
    height: 28px;
  }
`;

export const SelectLabel = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  ${typography.bodySemiBold};
  margin-bottom: 4px;
  @media ${device.mobileOnly} {
    ${typography.bodySmallSemiBold};
  }
`;

export const SelectMessage = styled.span<SelectMessageProps>`
  color: ${({ errorMessage }) => errorMessage && themeColors.error};
  ${typography.captionRegular};
`;

const setBackground: (
  backgroundColor: string | undefined,
  disabled: boolean | undefined
) => string = (backgroundColor, disabled) => {
  if (disabled) return themeColors.lightGrey;
  if (backgroundColor) return backgroundColor;
  return '';
};
