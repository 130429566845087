import { boxShadow, size, device, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import { Accordion } from '@mui/material';
import styled from 'styled-components';

type ConnectedStatusType = {
  status: boolean;
};

export const IntegrationsViewContainer = styled.div`
    width: calc(100% - 64px);
    max-width: ${size.desktopM};
    display: flex;
    padding: 0 32px;
    flex-direction: column;
    gap: 20px;
`;

export const IntegrationsViewPageTitle = styled.h1`
    margin: 0;
    font-size: ${fontSizes.large};
    font-weight: ${fontWeights.bold};
`;

export const IntegrationsViewDescription = styled.div`
  line-height: ${fontSizes.large};
`;

export const IntegrationsViewDataContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
      width: 18px;
      height: 18px;
    }
`;

export const IntegrationsViewConnectedStatus= styled.div<ConnectedStatusType>`
    display: flex;
    background-color: ${({ status }) => status ? themeColors.success : themeColors.darkGrey};
    color: ${themeColors.white};
    font-size: ${fontSizes.xsmall};
    border-radius: 8px;
    width: 60px;
    justify-content: center;
    padding: 5px;
    height: fit-content;
`;

export const IntegrationsViewLeftTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const IntegrationsViewLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    min-height: 40px;
`;

export const IntegrationsViewText = styled.p`
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
`;

export const IntegrationsViewAccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 100px;
`;

export const AccordionStyled = styled(Accordion)`
    &.MuiPaper-root {
      box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
        ::before {
            display: none;
        }

        .MuiAccordionDetails-root {
          padding: 8px 32px 32px;
        }

        .MuiAccordionSummary-root {
            width: 100%;
            padding: 8px 32px;
            align-items: center;
            ${typography.bodyBold};
            &.Mui-expanded {
                margin: 0;
            }
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;
    }
    .MuiAccordionSummary-expandIconWrapper {
        color: ${themeColors.body};
        transform: rotate(-90deg);
        &.Mui-expanded {
            transform: rotate(360deg);
        }
    }
    .MuiAccordionSummary-root {
        &.Mui-disabled {
            opacity: 1;
        }
    }
    &.Mui-disabled {
        background-color: transparent !important;
    }
`;

export const LoaderInCircleContainer = styled.div`
    display: block;
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.body};
    position: relative;
    width: 26px;
    height: 26px;
`;

export const IntegrationsViewStepsContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
`;

export const IntegrationsViewSteps = styled.div`
    display: flex;
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
    justify-content: space-between;
    gap: 5px;
`;

export const IntegrationsViewClientWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const IntegrationsViewClientContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @media ${device.mobileOnly} {
      flex-direction: column;
    }
`;

export const IntegrationsViewConfigurationMappingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
`;

export const IntegrationsViewLinksContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    gap: 20px;
    @media ${device.mobileOnly} {
      flex-direction: column;
      align-items: flex-start;
    }
`;

export const IntegrationsViewButtonTextWrapper = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const IntegrationsViewButtonContainer = styled.div`
  display: flex;
  margin-bottom: 64px;
  justify-content: flex-end;
`;

export const IntegrationsViewButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 16px;

    .MuiSvgIcon-root {
        width: 26px;
        height: 26px;
    }

    .MuiSwitch-root {
        .MuiButtonBase-root {
            &.Mui-checked + .MuiSwitch-track {
                background-color: ${themeColors.success}!important;
                box-shadow: none !important;
            }

            .MuiSwitch-thumb {
                background-color: ${themeColors.darkGrey}!important;
                box-shadow: none !important;
            }
            &.Mui-checked {
                .MuiSwitch-thumb {
                    background-color: ${themeColors.success}!important;
                }
            }
        }
        .MuiSwitch-track {
            background-color: ${themeColors.darkGrey}!important;
        }
        .Mui-checked {
                color:${themeColors.success}!important;
        }
    }
`;

export const IntegrationsViewConfigurationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;

export const IntegrationsViewConfigurationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const IntegrationsViewConfigurationItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const IntegrationsViewConfigurationItemLabel = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
  background-color: ${themeColors.lightGrey};
  padding: 10px;
`;

export const IntegrationsViewConfigurationItemMappingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const IntegrationsViewConfigurationItemMappingFieldContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const IntegrationsViewFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${device.mobileOnly} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const IntegrationsConnectionLogo = styled.img`
  width: 40px;
`;

export const IntegrationsFieldLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    ${typography.bodySemiBold};
    margin-bottom: 4px;
`;

export const IntegrationsViewConfigurationTitle = styled.p`
  ${typography.bodySemiBold};
  text-align: center;
  background-color: ${themeColors.primary};
  padding: 10px;
`;

export const AccordionCSVDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const CustomerMappingDialogWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

export const CustomerMappingDialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    min-width: 400px;
    @media ${device.mobileOnly} {
        min-width: auto;
        width: 80%;
    }
`;

export const CustomerMappingDialogTitle = styled.h2`
    margin: 0;
    font-size: ${fontSizes.xLarge};
    font-weight: ${fontWeights.bold};
    color: ${themeColors.body};
    margin-bottom: 32px;
`;

export const CustomerMappingDialogInfo = styled.p`
  margin-bottom: 20px;
`;

export const CustomerMappingDialogSelectWrapper =  styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
`;

export const SelectLabel =  styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const IntegrationsCustomersViewTableContainer =  styled.div`
  width: 100%;
  display: flex;
  background-color: transparent!important;
  margin-top: 50px;
`;

export const CustomerIconContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const ConnectorCsvMappingWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
`;

export const IntegrationsFieldCsvLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    ${typography.bodySemiBold};
    margin: 0 0 4px 32px;
`;

export const MappingErrorContainer = styled.p`
    color: ${themeColors.error};
`;

export const MappingCheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const MappingCheckboxLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    ${typography.bodySemiBold};
    margin-bottom: 4px;
`;

export const IntegrationsViewCheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      padding: 0;
      height: 36px;
    }
`;