/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import {
    IntegrationsViewConfigurationContainer,
    IntegrationsViewConfigurationItemContainer,
    IntegrationsViewConfigurationItemLabel,
    IntegrationsViewConfigurationItemMappingContainer,
    IntegrationsViewConfigurationItemMappingFieldContainer,
    IntegrationsViewConfigurationWrapper,
    MappingCheckboxContainer,
    MappingCheckboxLabel,
    MappingErrorContainer
} from '../styled';
import TextInput from 'components/forms/inputs/TextInput';
import { IntegrationItem } from 'utils/interfaces/IntegrationItem';
import JSONInput from 'react-json-editor-ajrm';
import { light_vscode_tribute, localeEn } from 'utils/jsonEditor';
import { Checkbox } from '@mui/material';
import { themeColors } from 'assets/theme/style';

export interface IConnectorCsvMappingProps {
    data: IntegrationItem;
    updateConfigurationHandler: (
        type: string,
        id: string,
        key: string,
        mappingKey: string,
        value: string | number,
        selectedCustomer?: string
    ) => void;
    register: any;
    errors: any;
    selectedCustomer?: string;
    isCustomer?: boolean;
}

const ConnectorCsvMapping: FC<IConnectorCsvMappingProps> = ({
    data,
    updateConfigurationHandler,
    register,
    errors,
    selectedCustomer = '',
    isCustomer = false
}) => {
    const [mappingError, setMappingError] = useState<any>({
        externalCustomerMapping: '',
        invoiceMapping: '',
        paymentMapping: '',
        purchaseOrderMapping: ''
    });
    const getLabelFromConnection: (item: string) => string = (item) => {
        switch (item) {
            case 'externalCustomerMapping':
                return 'External Customer Mapping';
            case 'invoiceMapping':
                return 'Invoice Mapping';
            case 'paymentMapping':
                return 'Payment Mapping';
            case 'purchaseOrderMapping':
                return 'Purchase Order Mapping';

            default:
                return '';
        }
    };

    const checkMappingValue: (data: any) => string = (data) => {
        if (selectedCustomer) {
            if (data && data.customers && data.customers[selectedCustomer]) {
                return JSON.parse(data.customers[selectedCustomer]);
            } else {
                return {};
            }
        } else {
            if (data && data.default) {
                return JSON.parse(data.default);
            } else {
                return {};
            }
        }
    };

    const updateMappingData: (value: any, key: string, data: IntegrationItem) => void = (
        value,
        key,
        data
    ) => {
        if (value?.error === false) {
            setMappingError({ ...mappingError, [key]: '' });
            selectedCustomer
                ? updateConfigurationHandler(
                      data.connectorType,
                      'customers',
                      key,
                      value.json,
                      selectedCustomer
                  )
                : updateConfigurationHandler(
                      data.connectorType,
                      data.connectorId || '',
                      'default',
                      key,
                      value.json,
                      selectedCustomer
                  );
        } else {
            setMappingError({ ...mappingError, [key]: value?.error });
        }
    };

    return (
        <IntegrationsViewConfigurationWrapper>
            <IntegrationsViewConfigurationContainer>
                {data?.configuration &&
                    Object.keys(data.configuration).map((key) => (
                        <IntegrationsViewConfigurationItemContainer key={`${key}-csv`}>
                            <IntegrationsViewConfigurationItemLabel>
                                {getLabelFromConnection(key)}
                            </IntegrationsViewConfigurationItemLabel>
                            <IntegrationsViewConfigurationItemMappingContainer>
                                <IntegrationsViewConfigurationItemMappingFieldContainer>
                                    <TextInput
                                        label="Delimiter"
                                        placeholder="Delimiter"
                                        defaultValue={
                                            data.configuration && data.configuration[key]
                                                ? data.configuration[key].delimiter
                                                : ''
                                        }
                                        disabled={selectedCustomer ? true : false}
                                        changeHandler={(event: any) =>
                                            updateConfigurationHandler(
                                                data.connectorType,
                                                data.connectorId || '',
                                                'delimiter',
                                                key,
                                                event.target.value
                                            )
                                        }
                                        errorMessage={
                                            !!Object.keys(errors).length && !isCustomer
                                                ? errors[`${key}-delimiter`] &&
                                                  'Delimiter is a required field'
                                                : ''
                                        }
                                        register={register(`${key}-delimiter`, {
                                            required: !isCustomer
                                        })}
                                    />
                                    <MappingCheckboxContainer>
                                        <MappingCheckboxLabel>Ignore Header</MappingCheckboxLabel>
                                        <Checkbox
                                            disableRipple
                                            disabled={selectedCustomer ? true : false}
                                            checked={
                                                data.configuration && data.configuration[key]
                                                    ? data.configuration[key].hasHeader
                                                    : false
                                            }
                                            sx={{
                                                color: 'rgba(0, 0, 0, 0.23)',
                                                '&.MuiCheckbox-root': {
                                                    justifyContent: 'flex-start'
                                                },
                                                '&.Mui-checked': {
                                                    color: themeColors.primary
                                                },
                                                '&.Mui-disabled': {
                                                    color: themeColors.darkGrey
                                                },
                                                '&:hover': { bgcolor: 'transparent' }
                                            }}
                                            onChange={(event: any) =>
                                                updateConfigurationHandler(
                                                    data.connectorType,
                                                    data.connectorId || '',
                                                    'hasHeader',
                                                    key,
                                                    event.target.checked
                                                )
                                            }
                                        />
                                    </MappingCheckboxContainer>
                                </IntegrationsViewConfigurationItemMappingFieldContainer>

                                <JSONInput
                                    id={`${key}-csv-mapping`}
                                    placeholder={
                                        data.configuration
                                            ? checkMappingValue(data.configuration[key])
                                            : {}
                                    }
                                    onBlur={(value: any) => updateMappingData(value, key, data)}
                                    locale={localeEn}
                                    colors={light_vscode_tribute}
                                    onKeyPressUpdate = {false}
                                    height="300px"
                                    width="100%"
                                    style={{
                                        body: {
                                            fontSize: '14px',
                                            fontFamily: `'Source Sans Pro', sans-serif`,
                                            lineHeight: '24px'
                                        }
                                    }}
                                    confirmGood={false}
                                />
                                {mappingError[key] && (
                                    <MappingErrorContainer>
                                        {mappingError[key]?.reason}
                                    </MappingErrorContainer>
                                )}
                            </IntegrationsViewConfigurationItemMappingContainer>
                        </IntegrationsViewConfigurationItemContainer>
                    ))}
            </IntegrationsViewConfigurationContainer>
        </IntegrationsViewConfigurationWrapper>
    );
};

export default ConnectorCsvMapping;
