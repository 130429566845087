import { device, themeColors } from 'assets/theme/style';
import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';


interface ConnectionSyncProps {
  isChildStep: boolean;
}

export const ConnectionSyncStepsWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
`;

export const ConnectionSyncStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  min-width: 400px;
  gap: 30px;
  @media ${device.mobileOnly} {
      min-width: auto;
      width: 80%;
  }
`;

export const ConnectionSyncChildStepsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  margin: 0 auto;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const ConnectionSyncChildStepsContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: column;
  height: 240px; 
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ConnectionSyncChildStepsContentNestedContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ConnectionSyncChildStepsContentRow = styled.div<ConnectionSyncProps>`
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px;
  padding-left: ${({ isChildStep }) => isChildStep ? '20px' : '0'};
  flex-direction: column;
  margin-bottom: 10px;
`;

export const ConnectionSyncChildStepsContentContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const ConnectionSyncChildStepsContentLabel = styled.span`
  margin: 0
  ${typography.bodySemiBold};
`;

export const ConnectionSyncChildStepsContentValue = styled.span`
  margin: 0
`;


export const ConnectionSyncChildStepsTitle = styled.p`
  margin: 0
  ${typography.bodySemiBold};
`;

export const ConnectionSyncChildStepsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: ${themeColors.lightGrey};

`;

export const ConnectionSyncStepsHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ConnectionSyncStepsHeaderTextWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const ConnectionSyncStepsHeaderText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;