import { NavigationItem } from 'utils/interfaces/route/navigation/navigation.interface';

export const navItems: NavigationItem[] = [
    {
        text: 'Home',
        path: '/',
        submenu: []
    },
    {
        text: 'Customers',
        path: '/customers',
        submenu: []
    },
    // {
    //     text: 'Developer',
    //     submenu: [
    //         {
    //             text: 'Developer Documentation​',
    //             externalPath:
    //                 'https://finecta.stoplight.io/docs/trade-api'
    //         },
    //         {
    //             text: 'Generate API keys​​​​',
    //             path: '/generate'
    //         }
    //     ]
    // },
    {
        text: 'Invitations',
        path: '/invitations',
        submenu: []
    },
    {
        text: 'Integrations',
        path: '/integrations',
        submenu: []
    },
    {
        text: 'Developer',
        path: '/developer',
        submenu: []
    }
];
