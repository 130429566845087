import { boxShadow, device, themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { fontSizes, typography } from 'assets/theme/typography';

interface IAnalyticsChartProps {
  colour?: string;
  fontColor?: string;
  width?: string;
}

export const AnalyticsChartCustomerWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  @media ${device.mobileOnly} {
    width: 100%;
  }
`;

export const AnalyticsChartCustomerRowContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  justify-content: space-between;
    @media ${device.desktopL} {
    flex-direction: row;
    align-items: center;
  }
`;

export const AnalyticsChartCustomerCardContainer = styled.div`
  display: flex;
  width: calc(100% - 32px);
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: ${themeColors.white};
  box-shadow: ${boxShadow};
`;

export const AnalyticsChartCustomerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16px;
`;

export const AnalyticsChartCustomerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AnalyticsChartCustomerGraphContainer = styled.div`
  position: relative;
  height: 20vh;
  width: 20vh;
  max-height: 400px;
  max-width: 400px;
`;

export const AnalyticsChartCustomerGraphWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  @media ${device.desktopL} {
    align-items: left;
  }
`;

export const AnalyticsChartCustomerLegendIcon = styled.div`
    display: flex;
    height: 10px;
    width: 10px;
    background-color: black;
`;

export const AnalyticsChartCustomerLegendWrapper= styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AnalyticsChartCustomerLegendContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

export const AnalyticsChartCustomerLegendTitle = styled.h2`
width: 100%;
font-size: ${fontSizes.small};
`;

export const AnalyticsChartCustomerTitle = styled.h2`
  ${typography.bodyBold};
`;

export const AnalyticsChartCustomerCardSubtitle = styled.p`
  color: ${themeColors.body};
  font-weight: bold;
`;

export const AnalyticsChartCustomerCardHeading = styled.h2`
  text-align: left;
  font-size: ${fontSizes.small};
`;

export const AnalyticsChartCustomerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 0;
`;

export const AnalyticsChartCustomerDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  padding: 16px;
  gap: 8px;
  width: calc(100% - 32px);
  border-radius: 8px;
`;

export const AnalyticsChartCustomerDetailsCardContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

export const AnalyticsChartCustomerDetailBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

export const AnalyticsChartCustomerDetailBox = styled.div<IAnalyticsChartProps>`
  display: flex;
  border-radius: 8px;
  font-size: ${fontSizes.small};
  padding: 8px;
  justify-content: center;
  width: calc(100% - 16px);
  min-width: 50px;
  color: ${({ fontColor }) => fontColor || themeColors.white};
  background-color: ${({ color }) => color || `${themeColors.body}95`};
`;

export const AnalyticsChartCustomerDetailTitle = styled.p`
  text-align: center;
  font-size: ${fontSizes.small};
  padding: 0 16px;
`;

export const AnalyticsChartCustomerChartContainer = styled.div`
  display: flex;
  padding: 1rem;
  height: auto;
  width: auto;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TooltipTitle = styled.p`
  ${typography.captionBold};
`;

export const TooltipDescription= styled.p`
  ${typography.captionRegular};
`;
