/* eslint-disable react-hooks/exhaustive-deps */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Dialog, IconButton, Slide, SlideProps, Snackbar, TextField } from '@mui/material';
import { apiConfig, get, post, put } from 'api/api';
import { inputDenseSxStyles, inputSxStyles, themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import DataGrid from 'components/DataGrid';
import LoaderFullPage from 'components/LoaderFullPage';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import Typography from 'components/typography';
import Context from 'context';
import { FC, useContext, useEffect, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { authResult } from 'lib/authConfig';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Link } from 'components/link/styled';
import {
    GenerateApiKeysButtonContainer,
    GenerateApiKeysContainer,
    GenerateApiKeysTableContainer,
    DeveloperViewContainer,
    DeveloperViewTitle,
    DeveloperViewDocumentationWrapper
} from './styled';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

const DeveloperView: FC = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [apiKeys, setApiKeys] = useState<any[]>([]);
    const [addKeyDialogOpen, setAddKeyDialogOpen] = useState<boolean>(false);
    const [showUpdateButton, setShowUpdateButton] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [keyName, setKeyName] = useState<string>('');
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [state] = useContext(Context);
    const stepIntegrations: boolean = state?.app?.stepIntegrations || false;

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        getCustomerApiKeys();
    }, []);

    useEffect(() => {
        if (snackbarOpen) {
            setTimeout(() => setSnackbarOpen(false), 5000);
        }
    }, [snackbarOpen]);

    const getCustomerApiKeys: () => any = async () => {
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        setLoadingMessage('Getting your keys...');
        get(apiConfig.apiKeys(), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const mappedData = responseData.value.map((d: any) => ({
                    name: d.name,
                    id: d.id,
                    value: '*********************************',
                    actions: false,
                    loading: false
                }));
                setApiKeys(mappedData);
            })
            .finally(() => {
                setLoading(false);
                setLoadingMessage('');
            });
    };

    const updateApiName: (id: string, value: string | boolean) => void = (id, value) => {
        value === '' ? setShowUpdateButton(false) : setShowUpdateButton(true);

        const updatedKeys = apiKeys.map((item: any) =>
            item.id === id ? { ...item, ['name']: value } : item
        );

        setApiKeys(updatedKeys);
    };

    const generateApiKeysTableHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem('Name', 'name', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (_data: any, meta: any) => {
                return meta.rowData[0];
            }
        }),
        new DataGridHeaderItem('Api Key', 'id', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (_data: any, meta: any) => {
                return (
                    <TextField
                        type={meta.rowData[3] ? 'text' : 'password'}
                        sx={{
                            ...inputDenseSxStyles,
                            '& .MuiTextField-root': {
                                width: '100%'
                            },
                            '& .MuiOutlinedInput-root': {
                                input: { minWidth: '20rem' }
                            }
                        }}
                        variant="standard"
                        size="small"
                        value={meta.rowData[2]}
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                );
            }
        }),
        new DataGridHeaderItem('', 'value', {
            display: false
        }),
        new DataGridHeaderItem('Actions', 'actions', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (_data: any, meta: any) => (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                    <IconButton
                        aria-label="Show API key"
                        title="Show API key"
                        onClick={() => visibilityClickHandler(meta.rowData)}
                        edge="end"
                    >
                        {!meta.rowData[meta.columnIndex] ? (
                            <VisibilityOff />
                        ) : (
                            <Visibility style={{ color: themeColors.error }} />
                        )}
                    </IconButton>
                    <IconButton
                        aria-label="Copy API key"
                        title="Copy API key"
                        onClick={() => copyApiKey(meta.rowData)}
                        edge="end"
                    >
                        <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                        disabled={!showUpdateButton}
                        aria-label="Regenerate API key"
                        title="Regenerate API key"
                        onClick={() => updateKeyClickHandler(meta.rowData[1])}
                        edge="end"
                    >
                        <AutorenewIcon />
                    </IconButton>

                    {meta.rowData[4] && <LoaderInPage />}
                </div>
            )
        }),
        new DataGridHeaderItem('', 'loading', {
            display: false
        })
    ];

    const visibilityClickHandler: (meta: [string, string, string, boolean]) => void = async (
        meta
    ) => {
        const id: string = meta[1];
        const key: string = meta[2];
        const visible: boolean = meta[3];
        const newArr = [...apiKeys];
        const matchedIndex: number = newArr.findIndex((a) => a.id === id);

        if (matchedIndex === -1) return;

        if (visible || !key.includes('**')) {
            newArr[matchedIndex].actions = !newArr[matchedIndex].actions;
            setApiKeys(newArr);
            return;
        }
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);

        newArr[matchedIndex].loading = true;
        setApiKeys(newArr);

        get(apiConfig.apiKey(newArr[matchedIndex].id), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                newArr[matchedIndex].value = responseData.key;
                newArr[matchedIndex].actions = true;
                newArr[matchedIndex].loading = false;
                setApiKeys([...newArr]);
            });
    };

    const generateKeyClickHandler: () => void = async () => {
        setLoading(true);
        setAddKeyDialogOpen(false);
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        setLoadingMessage(`Saving your key: ${keyName}`);
        await post(apiConfig.apiKeys(), { name: keyName }, token).then(() =>
            getCustomerApiKeys()
        );
    };

    const updateKeyClickHandler: (id: string) => void = async (id) => {
        setLoading(true);
        setAddKeyDialogOpen(false);
        const keyName = apiKeys.filter((item: any) => item.id === id)[0]?.name;
        if (keyName) {
            const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
            setLoadingMessage(`Updating your key: ${keyName}`);
            await put(apiConfig.apiKey(id), { name: keyName }, token).then(() =>
                getCustomerApiKeys()
            );
        }
    };

    const saveHandleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void = (event) => {
        if (event.key === 'Enter') {
            generateKeyClickHandler();
        }
    };

    const copyApiKey: (meta: string[]) => void = async (meta) => {
        const key: string = meta[2];
        const id: string = meta[1];
        const newArr = [...apiKeys];
        const matchedIndex: number = newArr.findIndex((a) => a.id === id);

        if (!key.includes('**')) {
            await navigator.clipboard.writeText(key);
            setSnackbarOpen(true);
            return;
        }

        newArr[matchedIndex].loading = true;
        setApiKeys(newArr);

        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);

        get(apiConfig.apiKey(id), token)
            .then((response: any) => response.json())
            .then(async (responseData: any) => {
                if (matchedIndex === -1) return;
                newArr[matchedIndex].value = responseData.key;
                newArr[matchedIndex].loading = false;
                await navigator.clipboard.writeText(responseData.key);
                setSnackbarOpen(true);
                setApiKeys([...newArr]);
            });
    };

    const toggleDialog: () => void = () => setAddKeyDialogOpen((value) => !value);

    return loading ? (
        <LoaderFullPage detail={loadingMessage} />
    ) : (
        <DeveloperViewContainer>
            <DeveloperViewTitle>Generate API Keys</DeveloperViewTitle>
            <GenerateApiKeysTableContainer>
                <DataGrid
                    headers={generateApiKeysTableHeaders}
                    data={apiKeys}
                    ariaLabel="Api key table"
                    ariaCaption="A table that shows api keys generated."
                    fixedHeader
                    totalRows={apiKeys.length}
                    rowsPerPage={25}
                    showFooter={apiKeys.length > 25 ? true : false}
                />
            </GenerateApiKeysTableContainer>
            {apiKeys.length < 2 && (
                <GenerateApiKeysButtonContainer>
                    <PrimaryButton
                        width="fit-content"
                        text="Generate Key"
                        disabled={!stepIntegrations}
                        clickHandler={toggleDialog}
                    />
                </GenerateApiKeysButtonContainer>
            )}
            <Dialog onClose={toggleDialog} open={addKeyDialogOpen}>
                <GenerateApiKeysContainer>
                    <Typography tag={'h3'}>Name your key</Typography>
                    <TextField
                        label="Key Name"
                        sx={inputSxStyles}
                        value={keyName}
                        onChange={(e: any) => setKeyName(e.target.value)}
                        onKeyDown={(event: any) => saveHandleKeyDown(event)}
                        autoFocus
                    />

                    <GenerateApiKeysButtonContainer>
                        <PrimaryButton
                            width="fit-content"
                            text="Generate Key"
                            disabled={!stepIntegrations}
                            clickHandler={generateKeyClickHandler}
                        />
                    </GenerateApiKeysButtonContainer>
                </GenerateApiKeysContainer>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackbarOpen}
                TransitionComponent={SlideTransition}
                message="API key copied to clipboard!"
                key={`copied-${new Date().toISOString()}`}
            />
            <DeveloperViewDocumentationWrapper>
                <DeveloperViewTitle>Developer Documentation</DeveloperViewTitle>
                <Typography tag="p">
                    <Link
                        onClick={() =>
                            window.open('https://finecta.stoplight.io/docs/trade-api', '_BLANK')
                        }
                    >
                        Click here
                    </Link>
                    {''} to access essential resources and information for developers.
                </Typography>
            </DeveloperViewDocumentationWrapper>
        </DeveloperViewContainer>
    );
};

export default DeveloperView;
