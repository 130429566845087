/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from 'react';
import {
    AnalyticsViewContainer,
    AnalyticsHeaderContainer,
    AnalyticsChartsWrapper,
    AnalyticsChartsLeftWrapper,
    AnalyticsChartsRightWrapper,
    AnalyticsChartsRightContainer,
    AnalyticsChartsRowContainer,
    AnalyticsChartsEmptyMessage,
    AnalyticsChartsEmptyWrapper,
    AnalyticsButtonContainer,
    AnalyticsChartsEmptyMessageContainer,
    AnalyticsChartsLastReportDate,
    AnalyticsChartsNewReportContainer,
    AnalyticsHeaderWrapper,
    AnalyticsHeaderActionContainer,
    AnalyticsHeaderActionButtonContainer,
    AnalyticsChartsNotificationContainer,
    TabCardContainer,
    TabCard,
    TabOuterContainer,
    TabUpdateButton
} from './styled';
import { useParams, useNavigate } from 'react-router-dom';
import ConnectionBanner from 'components/ConnectionBanner';
import { PrimaryButton } from 'components/common/buttons';
import SummaryView from './SummaryView';
import SalesView from './SalesView';
import CustomersView from './CustomersView';
import ExpensesView from './ExpensesView';
import { authResult } from 'lib/authConfig';
import { apiConfig, get, post, put } from 'api/api';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import ConfidenceFactorView from './ConfidenceFactorView';
import {
    IAnalyticsCustomersData,
    IAnalyticsData,
    IAnalyticsExpensesData,
    IAnalyticsSalesData,
    ICompaniesHouseData
} from 'utils/interfaces/analytics.interface';
import LoaderFullPage from 'components/LoaderFullPage';
import SecondaryButton from 'components/common/buttons/SecondaryButton';
import { convertKeysToLowerCase } from 'utils/convertKeysToLowerCase';
import SettingsIcon from '@mui/icons-material/Settings';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import { DateTime } from 'luxon';
import { triggerSnackbar } from 'context/action/app';
import Context from 'context';
import { CustomerDetailConnectorType } from 'views/CustomersView/CustomerDetail/CustomerDetail';
import { Tabs } from 'components/Tabs';
import creditSafe from 'assets/pdf/credit-safe.pdf';
import wiserFunding from 'assets/pdf/wf-credit-report.pdf';
import DunnAndBrad from 'assets/pdf/dunnAndBrad.pdf';
import creditSafeOpenBanking from 'assets/pdf/credit-safe-open-banking.pdf';
import UpdateIcon from '@mui/icons-material/Update';
import Skeleton from 'components/Skeleton';

export interface CustomerDetailType {
    connector: CustomerDetailConnectorType;
    createdDate: string;
}

const AnalyticsView: FC = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [state, dispatch] = useContext(Context);
    const customerUid: string = params?.customerUid || '';
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [loading, setLoading] = useState<boolean>(false);
    const [generateLoading, setGenerateLoading] = useState<boolean>(false);
    const [importLoading, setImportLoading] = useState<boolean>(false);
    const [analyticsData, setAnalyticsData] = useState<IAnalyticsData | null>(null);
    const [customerAnalyticsData, setCustomerAnalyticsData] =
        useState<IAnalyticsCustomersData | null>(null);
    const [salesAnalyticsData, setSalesAnalyticsData] = useState<IAnalyticsSalesData | null>(null);
    const [expensesAnalyticsData, setExpensesAnalyticsData] =
        useState<IAnalyticsExpensesData | null>(null);
    const [companiesHouseData, setCompaniesHouseData] = useState<ICompaniesHouseData | null>(null);
    const [isNewCustomer, setIsNewCustomer] = useState<boolean | null>(null);
    const [disabledReportButton, setDisabledReportButton] = useState<boolean>(false);
    const [dataMessage, setDataMessage] = useState<string>('');
    const lastReportDate = DateTime.fromISO(analyticsData?.reportCreated || '');
    const daysDifferent = DateTime.now().diff(lastReportDate, 'days').days;
    const isNewReportAvailable = daysDifferent > 7;

    const generateHealthCheckReport: (updateReport?: boolean) => void = async (
        updateReport = false
    ) => {
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        const generateResponse = await put(apiConfig.healthCheckReport(customerUid), null, token);
        if (generateResponse.ok) {
            setGenerateLoading(true);
            setDisabledReportButton(true);
            if (updateReport) {
                triggerSnackbar(dispatch, {
                    open: true,
                    data: {
                        role: 'success',
                        message: (
                            <AnalyticsChartsNotificationContainer>
                                Your report is being generated. Refresh the page after receiving the
                                notification email to view the latest version.
                            </AnalyticsChartsNotificationContainer>
                        )
                    }
                });
            }
        } else {
            setDataMessage('Something went wrong while generating the report. Please try again.');

            updateReport &&
                triggerSnackbar(dispatch, {
                    open: true,
                    data: {
                        role: 'error',
                        message: (
                            <AnalyticsChartsNotificationContainer>
                                Something went wrong while generating the report. Please try again.
                            </AnalyticsChartsNotificationContainer>
                        )
                    }
                });
        }
    };

    const getCustomer: () => any = async () => {
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        get(apiConfig.customerDetail(`${customerUid}`), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const { createdDate }: CustomerDetailType = responseData.value[0];

                setIsNewCustomer(checkNewCustomer(createdDate));
                checkNewCustomer(createdDate)
                    ? setDataMessage(
                          'Analytic data is generated each week on a Sunday. New customer data may not be available for a few days.'
                      )
                    : setDataMessage(`No analytics data. Click 'Generate Report' to show.`);
                setLoading(false);
            });
    };

    const getHealthCheckReport: () => void = async () => {
        setLoading(true);

        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        try {
            const reportResponse = await get(apiConfig.healthCheckReport(customerUid), token);

            if (!reportResponse.ok) {
                throw new Error('Failed to generate report');
            }

            const reportResponseJson = await reportResponse.json();

            if (reportResponseJson.data) {
                const data: IAnalyticsData = JSON.parse(reportResponseJson.data);
                const covertedData = convertKeysToLowerCase(data);
                setAnalyticsData(covertedData);
                setCustomerAnalyticsData(covertedData?.customers);
                setSalesAnalyticsData(covertedData?.sales);
                setExpensesAnalyticsData(covertedData?.expenses);
                setLoading(false);
            } else {
                setLoading(false);
                setDataMessage('No available analytics data.');
            }
        } catch (error) {
            getCustomer();
        }
    };

    const checkNewCustomer: (date: string) => boolean = (date) => {
        const registerDate = DateTime.fromISO(date);
        const currentDate = DateTime.now();

        const weeksDifference = currentDate.diff(registerDate, 'weeks').weeks;

        if (weeksDifference >= 1) {
            return false;
        } else {
            return true;
        }
    };

    const getCompaniesHouseData: () => void = async () => {
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        try {
            const data = await get(apiConfig.companiesHouse(customerUid), token);

            if (!data.ok) {
                throw new Error('Failed to get data');
            }

            const response = await data.json();

            if (response) {
                setCompaniesHouseData(response);
                setImportLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // USED FOR IMPORTING COMPANIES HOUSE DATA
    const importCompaniesHouseData: () => void = async () => {
        setImportLoading(true);

        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        const body = {
            id: customerUid
        };

        try {
            const data = await post(apiConfig.companiesHouseImport(customerUid), body, token);

            if (!data.ok) {
                throw new Error('Failed to import data');
            }

            const response = await data.json();

            if (response) {
                getCompaniesHouseData();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const renderEmptyAnalyticsView: () => JSX.Element = () => (
        <AnalyticsChartsEmptyWrapper>
            <AnalyticsChartsEmptyMessageContainer>
                {generateLoading ? (
                    <>
                        <AnalyticsChartsEmptyMessage>
                            Your report is in progress. Feel free to continue with your tasks.
                            <br></br>
                            Refresh the page periodically for updates. We'll notify you when it's
                            done.
                        </AnalyticsChartsEmptyMessage>
                    </>
                ) : (
                    <AnalyticsChartsEmptyMessage>{dataMessage}</AnalyticsChartsEmptyMessage>
                )}
            </AnalyticsChartsEmptyMessageContainer>
            <AnalyticsButtonContainer justifyContent="center" direction="column">
                <PrimaryButton
                    disabled={generateLoading || (isNewCustomer !== null && isNewCustomer)}
                    width="fit-content"
                    text="Generate Report"
                    clickHandler={() => generateHealthCheckReport()}
                />
                <SecondaryButton
                    width="fit-content"
                    text="Go to Invoices"
                    clickHandler={() => navigate(`/invoices/${customerUid}`)}
                />
            </AnalyticsButtonContainer>
        </AnalyticsChartsEmptyWrapper>
    );

    const renderAnalyticsChartsView: () => JSX.Element = () => (
        <AnalyticsChartsWrapper>
            <AnalyticsChartsLeftWrapper>
                <SummaryView data={analyticsData} />
            </AnalyticsChartsLeftWrapper>
            <AnalyticsChartsRightWrapper>
                <AnalyticsChartsRightContainer>
                    <AnalyticsChartsRowContainer>
                        <SalesView salesData={salesAnalyticsData} />
                        <ExpensesView expensesData={expensesAnalyticsData} />
                    </AnalyticsChartsRowContainer>
                    <AnalyticsChartsRowContainer>
                        <ConfidenceFactorView confidenceData={analyticsData} />
                        <CustomersView customersData={customerAnalyticsData} />
                    </AnalyticsChartsRowContainer>
                </AnalyticsChartsRightContainer>
            </AnalyticsChartsRightWrapper>
        </AnalyticsChartsWrapper>
    );

    useEffect(() => {
        getHealthCheckReport();
        getCompaniesHouseData();
    }, []);

    return loading ? (
        <LoaderFullPage />
    ) : (
        <AnalyticsViewContainer>
            <AnalyticsHeaderWrapper>
                <AnalyticsHeaderContainer>
                    <ConnectionBanner customerId={customerUid} />
                </AnalyticsHeaderContainer>
                {analyticsData && !loading && (
                    <AnalyticsHeaderActionContainer>
                        <AnalyticsChartsLastReportDate>
                            <SettingsIcon color="action" />
                            Last report date:{' '}
                            {formatDateTime(analyticsData?.reportCreated || '', 'F')}
                        </AnalyticsChartsLastReportDate>

                        <AnalyticsHeaderActionButtonContainer>
                            {isNewReportAvailable && (
                                <AnalyticsChartsNewReportContainer>
                                    <PrimaryButton
                                        disabled={disabledReportButton}
                                        width="fit-content"
                                        text="New Report Available!"
                                        clickHandler={() => generateHealthCheckReport(true)}
                                    />
                                </AnalyticsChartsNewReportContainer>
                            )}

                            <SecondaryButton
                                width="fit-content"
                                text="Go to Invoices"
                                clickHandler={() => navigate(`/invoices/${customerUid}`)}
                            />
                        </AnalyticsHeaderActionButtonContainer>
                    </AnalyticsHeaderActionContainer>
                )}
            </AnalyticsHeaderWrapper>
            <Tabs initialTab="Accounting Analytics">
                <div title="Accounting Analytics">
                    {analyticsData ? renderAnalyticsChartsView() : renderEmptyAnalyticsView()}
                </div>
                <div title="Companies House">
                    {companiesHouseData ? (
                        <TabOuterContainer>
                            <TabUpdateButton>
                                {importLoading ? (
                                    <div>
                                        <PrimaryButton text="Importing..." />
                                    </div>
                                ) : (
                                    <div className="update-button-container">
                                        <div
                                            onClick={() => {
                                                importCompaniesHouseData();
                                            }}
                                        >
                                            <PrimaryButton text="Update" />
                                        </div>

                                        <div className="import-label">
                                            Import Date:{' '}
                                            {formatDateTime(
                                                companiesHouseData.updatedDate,
                                                'dd/MM/yyyy, HH:mm:ss'
                                            )}
                                        </div>
                                    </div>
                                )}
                            </TabUpdateButton>

                            <TabCard>
                                <TabCardContainer>
                                    <h2>Company</h2>
                                    {importLoading ? (
                                        <Skeleton />
                                    ) : (
                                        <div>
                                            <p>
                                                <span>Company Name:</span>{' '}
                                                {companiesHouseData?.companyName}
                                            </p>
                                            <p>
                                                <span>Company Number:</span>{' '}
                                                {companiesHouseData?.companyNumber}
                                            </p>
                                            <p>
                                                <span>Company Status:</span>{' '}
                                                {companiesHouseData?.companyStatus}
                                            </p>
                                            <p>
                                                <span>Company Type:</span>{' '}
                                                {companiesHouseData?.companyType}
                                            </p>
                                            <p>
                                                <span>Company Creation Date:</span>{' '}
                                                {formatDateTime(
                                                    companiesHouseData?.companyCreationDate,
                                                    'dd/MM/yyyy'
                                                )}
                                            </p>

                                            <p>
                                                {' '}
                                                <span>
                                                    Confirmation Statement Last Made Up To:
                                                </span>{' '}
                                                {formatDateTime(
                                                    companiesHouseData?.confirmStatementLastMadeUpTo,
                                                    'dd/MM/yyyy'
                                                )}
                                            </p>
                                            <p>
                                                <span>Confirmation Statement Next Made Up To:</span>{' '}
                                                {formatDateTime(
                                                    companiesHouseData?.confirmStatementNextMadeUpTo,
                                                    'dd/MM/yyyy'
                                                )}
                                            </p>
                                            <p>
                                                <span>Confirmation Statement Next Due:</span>{' '}
                                                {formatDateTime(
                                                    companiesHouseData?.confirmStatementNextDue,
                                                    'dd/MM/yyyy'
                                                )}
                                            </p>
                                            <p>
                                                <span>Confirmation Statement Overdue:</span>{' '}
                                                {companiesHouseData?.confirmStatementOverdue
                                                    ? 'Yes'
                                                    : 'No'}
                                            </p>
                                            <p>
                                                <span> Has Been Liquidated:</span>{' '}
                                                {companiesHouseData?.hasBeenLiquidated
                                                    ? 'Yes'
                                                    : 'No'}
                                            </p>
                                            <p>
                                                <span>Has Charges:</span>{' '}
                                                {companiesHouseData?.hasCharges ? 'Yes' : 'No'}
                                            </p>
                                            <p>
                                                <span>Has Insolvency History:</span>{' '}
                                                {companiesHouseData?.hasInsolvencyHistory
                                                    ? 'Yes'
                                                    : 'No'}
                                            </p>
                                            <p>
                                                <span>Is Community Interest Company:</span>{' '}
                                                {companiesHouseData?.isCommunityInterestCompany
                                                    ? 'Yes'
                                                    : 'No'}
                                            </p>
                                            <p>
                                                <span>Jurisdiction:</span>{' '}
                                                {companiesHouseData?.jurisdiction}
                                            </p>
                                            {companiesHouseData?.previousCompanyNames &&
                                                companiesHouseData?.previousCompanyNames?.length >
                                                    0 && (
                                                    <>
                                                        <div className="divider"></div>
                                                        <div>
                                                            <h2>Previous Company Names:</h2>{' '}
                                                            {companiesHouseData?.previousCompanyNames.map(
                                                                (company, index) => {
                                                                    return (
                                                                        <div key={company.name}>
                                                                            <p>
                                                                                <span>Name:</span>{' '}
                                                                                {company.name}
                                                                            </p>
                                                                            <p>
                                                                                <span>
                                                                                    Effective From:
                                                                                </span>{' '}
                                                                                {formatDateTime(
                                                                                    company.effectiveFrom,
                                                                                    'dd/MM/yyyy'
                                                                                )}
                                                                            </p>
                                                                            <p>
                                                                                <span>
                                                                                    Ceased On:
                                                                                </span>{' '}
                                                                                {formatDateTime(
                                                                                    company.ceasedOn,
                                                                                    'dd/MM/yyyy'
                                                                                )}
                                                                            </p>
                                                                            {index > 1 && (
                                                                                <div className="divider small"></div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                    )}
                                </TabCardContainer>
                                <TabCardContainer>
                                    <h2>Accounts</h2>
                                    {importLoading ? (
                                        <Skeleton />
                                    ) : (
                                        <div>
                                            <div>
                                                <p>
                                                    <span>Account Reference Date:</span>{' '}
                                                    {
                                                        companiesHouseData?.accounts
                                                            .accountingReferenceDate
                                                    }
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Last Accounts Type:</span>{' '}
                                                    {companiesHouseData?.accounts.lastAccountsType}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Last Period Start:</span>{' '}
                                                    {formatDateTime(
                                                        companiesHouseData?.accounts
                                                            .lastPeriodStart,
                                                        'dd/MM/yyyy'
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Last Period End:</span>{' '}
                                                    {formatDateTime(
                                                        companiesHouseData?.accounts.lastPeriodEnd,
                                                        'dd/MM/yyyy'
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Last Made Up To:</span>{' '}
                                                    {formatDateTime(
                                                        companiesHouseData?.accounts.lastMadeUpTo,
                                                        'dd/MM/yyyy'
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Overdue:</span>{' '}
                                                    {companiesHouseData?.accounts.overdue
                                                        ? 'Yes'
                                                        : 'No'}
                                                </p>
                                            </div>
                                            <div className="divider small"></div>
                                            <div>
                                                <p>
                                                    <span>Next Period Start:</span>{' '}
                                                    {formatDateTime(
                                                        companiesHouseData?.accounts
                                                            .nextPeriodStart,
                                                        'dd/MM/yyyy'
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Next Period End:</span>{' '}
                                                    {formatDateTime(
                                                        companiesHouseData?.accounts.nextPeriodEnd,
                                                        'dd/MM/yyyy'
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Next Made Up To:</span>{' '}
                                                    {formatDateTime(
                                                        companiesHouseData?.accounts.nextMadeUpTo,
                                                        'dd/MM/yyyy'
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Next Due:</span>{' '}
                                                    {formatDateTime(
                                                        companiesHouseData?.accounts.nextDue,
                                                        'dd/MM/yyyy'
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span>Next Overdue:</span>{' '}
                                                    {companiesHouseData?.accounts.nextOverdue
                                                        ? 'Yes'
                                                        : 'No'}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </TabCardContainer>
                                <TabCardContainer>
                                    <h2>Registered Office Address</h2>
                                    {importLoading ? (
                                        <Skeleton />
                                    ) : (
                                        <div>
                                            <p>
                                                <span>Address Line 1:</span>{' '}
                                                {companiesHouseData?.registeredOfficeAddress.line1}
                                            </p>
                                            <p>
                                                <span>Address Line 2:</span>{' '}
                                                {companiesHouseData?.registeredOfficeAddress.line2}
                                            </p>
                                            <p>
                                                <span>Locality:</span>{' '}
                                                {
                                                    companiesHouseData?.registeredOfficeAddress
                                                        .locality
                                                }
                                            </p>
                                            <p>
                                                <span>Region:</span>{' '}
                                                {companiesHouseData?.registeredOfficeAddress.region}
                                            </p>
                                            <p>
                                                <span>Country:</span>{' '}
                                                {
                                                    companiesHouseData?.registeredOfficeAddress
                                                        .country
                                                }
                                            </p>
                                            <p>
                                                <span>Post Code:</span>{' '}
                                                {
                                                    companiesHouseData?.registeredOfficeAddress
                                                        .postalCode
                                                }
                                            </p>
                                            <p>
                                                <span>Premises:</span>{' '}
                                                {
                                                    companiesHouseData?.registeredOfficeAddress
                                                        .premises
                                                }
                                            </p>
                                            <p>
                                                <span>Care Of:</span>{' '}
                                                {companiesHouseData?.registeredOfficeAddress.careOf}
                                            </p>
                                            <p>
                                                <span>PO Box:</span>{' '}
                                                {companiesHouseData?.registeredOfficeAddress.poBox}
                                            </p>
                                            <p>
                                                <span>Registered Offices In Dispute:</span>{' '}
                                                {companiesHouseData?.registeredOfficesInDispute
                                                    ? 'Yes'
                                                    : 'No'}
                                            </p>
                                        </div>
                                    )}
                                </TabCardContainer>
                                <TabCardContainer>
                                    <h2>SIC Codes</h2>
                                    {importLoading ? (
                                        <Skeleton />
                                    ) : (
                                        <div>
                                            {companiesHouseData?.sicCodes.map((sicCode) => {
                                                return (
                                                    <div key={sicCode.code}>
                                                        <p>
                                                            <span>Code: </span>
                                                            {sicCode.code}
                                                        </p>
                                                        <p>
                                                            <span>Description:</span>{' '}
                                                            {sicCode.description}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </TabCardContainer>
                            </TabCard>
                        </TabOuterContainer>
                    ) : (
                        <AnalyticsChartsEmptyWrapper>
                            <AnalyticsChartsEmptyMessageContainer>
                                <>
                                    {' '}
                                    {importLoading ? (
                                        <AnalyticsChartsEmptyMessage>
                                            Importing...
                                        </AnalyticsChartsEmptyMessage>
                                    ) : (
                                        <AnalyticsButtonContainer
                                            justifyContent="center"
                                            direction="column"
                                        >
                                            <PrimaryButton
                                                clickHandler={() => importCompaniesHouseData()}
                                                width="fit-content"
                                                text="Import Companies House Data"
                                            />
                                        </AnalyticsButtonContainer>
                                    )}
                                </>
                            </AnalyticsChartsEmptyMessageContainer>
                        </AnalyticsChartsEmptyWrapper>
                    )}
                </div>
                <div title="Bank Analytics">
                    <iframe
                        src={creditSafeOpenBanking}
                        title="credit-safe"
                        width="100%"
                        height="1500"
                    ></iframe>
                </div>
                <div title="Credit Safe">
                    <iframe
                        src={creditSafe}
                        title="credit-safe"
                        width="100%"
                        height="1500"
                    ></iframe>
                </div>
                <div title="Wiser Funding">
                    <iframe
                        src={wiserFunding}
                        title="wiser-funding"
                        width="100%"
                        height="1500"
                    ></iframe>
                </div>
                <div title="Dunn and Bradstreet">
                    <iframe
                        src={DunnAndBrad}
                        title="dunn-and-brad"
                        width="100%"
                        height="1500"
                    ></iframe>
                </div>
            </Tabs>
        </AnalyticsViewContainer>
    );
};

export default AnalyticsView;
