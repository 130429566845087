import { FC, ReactNode } from 'react';
import { SecondaryButtonStyled } from './styled';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { themeColors } from 'assets/theme/style';

export interface SecondaryButtonProps extends ButtonProps {
    children?: ReactNode;
    testingTagLocation?: string;
}

const SecondaryButton: FC<SecondaryButtonProps> = ({
    text = '',
    clickHandler = (_e?: any) => {},
    textColor = themeColors.accent,
    borderColor = themeColors.accent,
    backgroundColor = 'transparent',
    disabled = false,
    children,
    padding,
    borderRadius,
    gap,
    minWidth,
    height,
    type,
    testingTagLocation,
    width
}) => (
    <SecondaryButtonStyled
        data-testid={`finecta-secondary-button${testingTagLocation || ''}`}
        onClick={(e) => clickHandler(e)}
        textColor={textColor}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        disabled={disabled}
        padding={padding}
        borderRadius={borderRadius}
        gap={gap}
        minWidth={minWidth}
        height={height}
        type={type}
        width={width}
    >
        {children || text}
    </SecondaryButtonStyled>
);

export default SecondaryButton;
