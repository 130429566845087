import { themeColors } from 'assets/theme/style';
import styled from 'styled-components';

type CardProps = {
    minHeight?: string;
    maxHeight?: string;
    height?: string;
    width?: string;
    bgColor?: string;
    boxShadow?: string;
};

export const CardContainer = styled.div<CardProps>`
    padding: 16px;
    border-radius: 12px;
    border: 2px solid ${themeColors.accent};
    min-height: ${({ minHeight }) => minHeight || ''};
    max-height: ${({ maxHeight }) => maxHeight || ''};
    height: ${({ height }) => height || 'fit-content'};
    width: ${({ width }) => width || ''};
    background-color: ${({ bgColor }) => bgColor || ''};
    box-shadow: ${({ boxShadow }) => boxShadow || ''};
`;
