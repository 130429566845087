import { Snackbar } from '@mui/material';
import { themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights } from 'assets/theme/typography';
import styled from 'styled-components';

interface StyleType {
  role?: 'success' | 'warning' | 'error' | 'alert' | undefined;
} 

export const SnackbarWrapper = styled(Snackbar)<StyleType>`
  .MuiSnackbarContent-root{
    background-color:${({ role }) => `${color(role)}`};
  }
`;

const color: (type: 'success' | 'warning' | 'error' | 'alert' | undefined) => string = (type) => {
  switch (type) {
    case 'error':
      return themeColors.error;
    case 'warning':
      return themeColors.warning;
    default:
      return themeColors.success;
  }
};

export const SnackBarContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: column;
`;

export const SnackBarTitle = styled.p`
font-weight: ${fontWeights.bold};
font-size: ${fontSizes.regular};
`;

export const SnackBarBody = styled.p`
font-weight: ${fontWeights.regular};
font-size: ${fontSizes.small};
`;