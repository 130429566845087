import styled from 'styled-components';
import { device } from 'assets/theme/style';

export const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: space-between;
    @media ${device.mobileOnly} {
        gap: 16px;
    }
`;

export const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 204px);
    @media ${device.mobileOnly} {
        min-height: calc(100vh - 171px);
    }
`;

export const AppLoaderContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
