import { FC } from 'react';
import { DialogStyled } from './styled';
interface BasicDialogProps {
    open: boolean;
    clickHandler?: () => void;
    dialogContent: JSX.Element;
}

const BasicDialog: FC<BasicDialogProps> = ({ open, clickHandler, dialogContent }) => (
    <DialogStyled open={open} onClose={clickHandler}>
        {dialogContent}
    </DialogStyled>
);

export default BasicDialog;
