import { boxShadow, device, themeColors } from 'assets/theme/style';
import styled from 'styled-components';
import { fontSizes, typography } from 'assets/theme/typography';

interface IAnalyticsChartProps {
  color?: string;
  fontColor?: string;
  width?: string;
}

export const AnalyticsChartConfidenceFactorWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  @media ${device.mobileOnly} {
    width: 100%;
  }
`;

export const AnalyticsChartFactorContainerSubtitle = styled.p`
  color: ${themeColors.body};
  font-weight: bold;
`;

export const AnalyticsChartConfidenceFactorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
  border-radius: 8px;
  background-color: ${themeColors.white};
  box-shadow: ${boxShadow};
`;

export const AnalyticsChartConfidenceFactorRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
    @media ${device.desktopL} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const AnalyticsChartConfidenceHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AnalyticsChartConfidenceFactorTitle = styled.h2`
  ${typography.bodyBold};
`;

export const AnalyticsChartConfidenceFactorDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(100% - 32px);
  justify-content: center;
  @media ${device.desktopL} {
    width: calc(50% - 32px);
  }
`;

export const AnalyticsChartConfidenceFactorDetailsContainer = styled.div`
  display: flex;
  gap: 32px;
`;

export const AnalyticsChartConfidenceFactorDetailsOneColumnContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AnalyticsChartConfidenceFactorDetailBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const AnalyticsChartConfidenceFactorDetailBox = styled.div<IAnalyticsChartProps>`
  display: flex;
  border-radius: 8px;
  font-size: ${fontSizes.small};
  padding: 8px;
  justify-content: center;
  width: ${({ width }) => width || '100%'};
  min-width: 50px;
  color: ${({ fontColor }) => fontColor || themeColors.white};
  background-color: ${({ color }) => color || `${themeColors.body}95`};
`;

export const AnalyticsChartConfidenceFactorDetailTitle = styled.p`
  text-align: center;
  font-size: ${fontSizes.small};
  padding: 0 16px;
`;

export const AnalyticsChartConfidenceFactorBarsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 16px);
  height: 120px;
  @media ${device.desktopL} {
    width: calc(50% - 16px);
  }
`;

export const AnalyticsChartConfidenceFactorBar = styled.div`
  display: flex;
  gap: 8px;
`;

export const AnalyticsChartConfidenceFactorBarLabel = styled.span`
  width: 60px;
`;

export const AnalyticsChartConfidenceFactorBarContainer = styled.span`
  width: 100px;
  display: flex;
`;

export const AnalyticsChartConfidenceFactorBarValue = styled.span<IAnalyticsChartProps>`
  width: ${({ width }) => width || '100%'};
  background-color: ${({ color }) => color || themeColors.lightBlue};
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TooltipTitle = styled.p`
  ${typography.captionBold};
`;

export const TooltipDescription= styled.p`
  ${typography.captionRegular};
`;