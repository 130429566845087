/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import {
    AnalyticsChartCustomerTitle,
    AnalyticsChartCustomerContainer,
    AnalyticsChartCustomerWrapper,
    AnalyticsChartCustomerDetailsContainer,
    AnalyticsChartCustomerDetailBoxContainer,
    AnalyticsChartCustomerDetailBox,
    AnalyticsChartCustomerDetailTitle,
    AnalyticsChartCustomerDetailsWrapper,
    AnalyticsChartCustomerChartContainer,
    AnalyticsChartCustomerCardContainer,
    AnalyticsChartCustomerCardSubtitle,
    AnalyticsChartCustomerGraphContainer,
    AnalyticsChartCustomerCardHeading,
    AnalyticsChartCustomerDetailsCardContainer,
    AnalyticsChartCustomerRowContainer,
    AnalyticsChartCustomerLegendContainer,
    AnalyticsChartCustomerGraphWrapper,
    AnalyticsChartCustomerLegendIcon,
    AnalyticsChartCustomerLegendWrapper,
    AnalyticsChartCustomerLegendTitle,
    AnalyticsChartCustomerHeader,
    TooltipWrapper,
    TooltipTitle,
    TooltipDescription
} from './styled';
import { themeColors } from 'assets/theme/style';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import {
    IAnalyticsCustomersCustomerData,
    IAnalyticsCustomersData
} from 'utils/interfaces/analytics.interface';
import _ from 'lodash';
import IconTooltip from 'components/tooltip/IconTooltip';

interface ICustomersViewProps {
    customersData: IAnalyticsCustomersData | null;
}

const CustomersView: FC<ICustomersViewProps> = ({ customersData }) => {
    const [topCustomers, setTopCustomers] = useState<IAnalyticsCustomersCustomerData[]>([]);
    const [chartLabels, setChartLabels] = useState<string[]>([]);
    const [chartData, setChartData] = useState<number[]>([]);

    ChartJS.register(
        ArcElement,
        CategoryScale,
        ChartDataLabels,
        Legend,
        LinearScale,
        Title,
        Tooltip
    );

    useEffect(() => {
        customersData?.customers &&
            customersData?.customers.length > 0 &&
            setTopCustomers(customersData?.customers);
    }, [customersData]);

    useEffect(() => {
        if (topCustomers.length > 0) {
            setChartLabels(topCustomers.map((item) => item.customerName));
            setChartData(topCustomers.map((item) => item.percentValue));
        }
    }, [topCustomers]);

    const colors: string[] = [`#48B5C4`, `#A6D75B`, `#76C68F`];

    const capitalizeCustomerName: (name: string) => string = (name) => {
        return name
            .toLowerCase()
            .split(' ')
            .map((n) => {
                return n === 'plc' || n === 'ltl'
                    ? n.toUpperCase()
                    : n.charAt(0).toUpperCase() + n.slice(1).toLowerCase();
            })
            .join(' ');
    };

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: '',
                data: chartData,
                backgroundColor: colors,
                borderWidth: 1
            }
        ]
    };

    const options = {
        type: 'pie',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    title: (value: any) => capitalizeCustomerName(value[0].label),
                    label: (context: any) => context.formattedValue && `${context.formattedValue}%`
                }
            },
            legend: {
                display: false
            },
            datalabels: {
                color: themeColors.body,
                labels: {
                    title: {
                        font: {
                            weight: 'bold' as const
                        }
                    }
                },
                formatter: (value: any) => value + `%`
            },
            title: {
                align: 'start' as const,
                position: 'top' as const,
                text: ''
            }
        }
    };

    return (
        <AnalyticsChartCustomerWrapper>
            <AnalyticsChartCustomerHeader>
                <AnalyticsChartCustomerTitle>CUSTOMERS</AnalyticsChartCustomerTitle>
                <IconTooltip
                    tooltipText={
                        <TooltipWrapper>
                            {/* <TooltipTitle>Title</TooltipTitle> */}
                            <TooltipDescription>
                                We show the top two customers by invoice value then the remaining
                                invoices. Generally, the smaller the top two customers are, the more
                                robust the company appears. It means that they are not necessarily
                                at risk from any one customer being lost.
                            </TooltipDescription>
                        </TooltipWrapper>
                    }
                />
            </AnalyticsChartCustomerHeader>
            <AnalyticsChartCustomerCardContainer>
                <AnalyticsChartCustomerCardSubtitle>
                    Customer concentration - last 12 months
                </AnalyticsChartCustomerCardSubtitle>

                <AnalyticsChartCustomerRowContainer>
                    <AnalyticsChartCustomerGraphWrapper>
                        <AnalyticsChartCustomerGraphContainer>
                            <Pie title="" options={options} data={data} />
                        </AnalyticsChartCustomerGraphContainer>
                        <AnalyticsChartCustomerLegendWrapper>
                            {topCustomers.map((customer, index) => (
                                <AnalyticsChartCustomerLegendContainer
                                    key={`${customer.customerName}-legend-${index}`}
                                >
                                    <AnalyticsChartCustomerLegendIcon
                                        style={{ backgroundColor: `${colors[index]}` }}
                                    ></AnalyticsChartCustomerLegendIcon>
                                    <AnalyticsChartCustomerLegendTitle>
                                        {capitalizeCustomerName(customer?.customerName) || ''}
                                    </AnalyticsChartCustomerLegendTitle>
                                </AnalyticsChartCustomerLegendContainer>
                            ))}
                        </AnalyticsChartCustomerLegendWrapper>
                    </AnalyticsChartCustomerGraphWrapper>

                    <AnalyticsChartCustomerDetailsWrapper>
                        {topCustomers
                            .filter((c) => c.customerName !== 'Remaining Customers')
                            .map((customer, index) => (
                                <AnalyticsChartCustomerDetailsContainer
                                    key={`${customer.customerName}-${index}`}
                                >
                                    <AnalyticsChartCustomerCardHeading>
                                        {`${
                                            capitalizeCustomerName(customer?.customerName || '') ||
                                            ''
                                        } accounts for ${customer.percentValue}% of sales - ${
                                            customer.totalInvoices
                                        } Invoices`}
                                    </AnalyticsChartCustomerCardHeading>
                                    <AnalyticsChartCustomerDetailsCardContainer>
                                        <AnalyticsChartCustomerDetailBoxContainer>
                                            <AnalyticsChartCustomerDetailBox
                                                color={`${themeColors.lightBlue}`}
                                                fontColor={`${themeColors.body}`}
                                            >
                                                {customer?.averageDaysPaidLate || 0}
                                            </AnalyticsChartCustomerDetailBox>
                                            <AnalyticsChartCustomerDetailTitle>
                                                Av. days paid late
                                            </AnalyticsChartCustomerDetailTitle>
                                        </AnalyticsChartCustomerDetailBoxContainer>
                                        <AnalyticsChartCustomerDetailBoxContainer>
                                            <AnalyticsChartCustomerDetailBox
                                                color={`${themeColors.lightBlue}`}
                                                fontColor={`${themeColors.body}`}
                                            >
                                                {customer?.averagePercentPaid || 0}
                                            </AnalyticsChartCustomerDetailBox>
                                            <AnalyticsChartCustomerDetailTitle>
                                                Av % paid
                                            </AnalyticsChartCustomerDetailTitle>
                                        </AnalyticsChartCustomerDetailBoxContainer>
                                    </AnalyticsChartCustomerDetailsCardContainer>
                                </AnalyticsChartCustomerDetailsContainer>
                            ))}
                    </AnalyticsChartCustomerDetailsWrapper>
                </AnalyticsChartCustomerRowContainer>
            </AnalyticsChartCustomerCardContainer>
        </AnalyticsChartCustomerWrapper>
    );
};

export default CustomersView;
