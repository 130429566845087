/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useContext } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import {
    IntegrationsViewContainer,
    IntegrationsViewLeft,
    IntegrationsViewLeftTextContainer,
    IntegrationsViewText,
    IntegrationsViewDataContainer,
    IntegrationsViewClientWrapper,
    IntegrationsViewButtonTextWrapper,
    IntegrationsViewButtonWrapper,
    IntegrationsViewLinksContainer,
    IntegrationsViewClientContainer,
    IntegrationsViewFooter,
    IntegrationsViewPageTitle,
    IntegrationsViewDescription,
    AccordionStyled,
    IntegrationsViewAccordionWrapper,
    IntegrationsConnectionLogo,
    IntegrationsViewConnectedStatus,
    IntegrationsViewConfigurationContainer,
    IntegrationsFieldLabel,
    AccordionCSVDetails,
    CustomerMappingDialogContainer,
    CustomerMappingDialogTitle,
    CustomerMappingDialogWrapper,
    CustomerMappingDialogSelectWrapper,
    SelectLabel,
    CustomerMappingDialogInfo,
    IntegrationsCustomersViewTableContainer,
    CustomerIconContainer,
    ConnectorCsvMappingWrapper,
    IntegrationsFieldCsvLabel,
    IntegrationsViewButtonContainer
} from './styled';
import Switch from '@mui/material/Switch';
import Context from 'context';
import Typography from 'components/typography';
import { Link } from 'components/link/styled';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrimaryButton } from 'components/common/buttons';
import {
    ClientItem,
    ConfigurationZohoTypes,
    ConnectorTypes,
    IntegrationItem
} from 'utils/interfaces/IntegrationItem';

import { apiConfig, get, post, put } from 'api/api';
import { authResult } from 'lib/authConfig';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { integrationInitialData } from 'lib/data/integrationsData';
import { updateIntroSteps } from 'context/action/app';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import ConnectorClientView from './ConnectorClientView';
import FullViewDialog from 'components/dialog/FullViewDialog';
import Select from 'components/forms/inputs/Select';
import { themeColors } from 'assets/theme/style';
import DataGrid from 'components/DataGrid';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import ConnectorCsvMapping from './ConnectorCsvMapping';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BasicDialog from 'components/dialog/BasicDialog';
import ConnectorConfirmationView from './ConnectorConfirmationView';

const label = { inputProps: { 'aria-label': 'Switch integration status' } };

export interface CustomerData {
    value: string;
    name: string;
}

const IntegrationsView: FC = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [state, dispatch] = useContext(Context);
    const [connectorTypeDataModel, setConnectorTypeDataModel] = useState<ConnectorTypes[]>([]);
    const [integrationsInitialDataModel, setIntegrationsInitialDataModel] = useState<
        IntegrationItem[]
    >([]);
    const [integrationsInitialDataModelRaw, setIntegrationsInitialDataModelRaw] = useState<
        IntegrationItem[]
    >([]);
    const [integrationsDataModelRaw, setIntegrationsDataModelRaw] = useState<IntegrationItem[]>([]);
    const [integrationsDataModel, setIntegrationsDataModel] = useState<IntegrationItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<CustomerData[]>([{ value: '', name: '' }]);
    const [isDataModified, setIsDataModified] = useState<any>({
        xero: false,
        quickbooks: false,
        zohobooks: false,
        sage: false,
        csv: false
    });
    const [loadingClient, setLoadingCient] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [firstConnectorsTypeLoad, setFirstConnectorsTypeLoad] = useState<boolean>(false);
    const [customerMappingDialogOpen, setCustomerMappingDialogOpen] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<string>('');
    const [deletedCustomer, setDeletedCustomer] = useState<string>('');
    const [editCustomerMapping, setEditCustomerMapping] = useState<boolean>(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
        useState<boolean>(false);

    const {
        register,
        trigger,
        clearErrors,
        formState: { errors }
    } = useForm<any>({ mode: 'onChange', reValidateMode: 'onChange' });

    useEffect(() => {
        setLoading(true);
        trigger();
    }, []);

    useEffect(() => {
        setLoading(false);
        getConnectorType();
        getCustomers();
    }, []);

    useEffect(() => {
        firstConnectorsTypeLoad && getConnectors();
    }, [firstConnectorsTypeLoad]);

    const getIntegrationData = (type: string, dataModel: IntegrationItem[]): IntegrationItem[] => {
        const result = dataModel.filter((item) => item.connectorType === type);
        return result;
    };

    const getConnectors: () => void = async () => {
        setLoadingCient(true);
        try {
            const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
            await get(apiConfig.getConnectors(), token)
                .then((response: any) => response.json())
                .then((responseData: any) => {
                    if (responseData.value.length > 0) {
                        const mappedData: IntegrationItem[] = responseData.value.map((d: any) => ({
                            connectorId: d.connectorConfigId,
                            connectorType: d.connectorType,
                            clientId: d.clientId || '',
                            clientSecret: d.clientSecretHidden || '',
                            status: d.status,
                            configuration: d.configuration ? JSON.parse(d.configuration) : null,
                            includeLineItems: d.connectorType === 'zohobooks' ? true : false
                        }));
                        setIntegrationsDataModel(mappedData);

                        setIntegrationsDataModelRaw(mappedData);
                        setLoading(false);
                        setLoadingCient(false);
                        setIsDataModified({
                            xero: false,
                            quickbooks: false,
                            zohobooks: false,
                            sage: false,
                            csv: false
                        });
                        updateConnectorTypeStatus(mappedData);
                    } else {
                        setLoading(false);
                        setLoadingCient(false);
                        setIsDataModified({
                            xero: false,
                            quickbooks: false,
                            zohobooks: false,
                            sage: false,
                            csv: false
                        });
                    }
                });
        } catch (error: any) {
            console.error('Error:', error);
        }
    };

    const getConnectorType: () => void = async () => {
        setLoading(true);
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        await get(apiConfig.getConnectorType(''), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const mappedData: ConnectorTypes[] = responseData.value.map((d: any) => ({
                    type: d.type || '',
                    name: d.name || '',
                    integrationDocumentationLink: d.integrationDocumentationLink || '',
                    documentationLink: d.documentationLink || '',
                    iconDataUrl: d.iconDataUrl || '',
                    status: 'InActive'
                }));
                setConnectorTypeDataModel(mappedData);
                setIntegrationsInitialDataModel(integrationInitialData);
                setIntegrationsInitialDataModelRaw(integrationInitialData);
                setFirstConnectorsTypeLoad(true);
            });
    };

    const postConnector: (data: ClientItem) => Promise<void> = async (data) => {
        setLoadingCient(true);
        try {
            const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
            const connectorConfigurationResponse = await post(
                apiConfig.postConnectorConfiguration(),
                data,
                token
            );
            if (!connectorConfigurationResponse.ok) {
                throw new Error('Failed to connector configuration');
            }

            getConnectors();
            updateIntroSteps(dispatch, {
                stepIntegrations: true,
                stepInvitations: state?.app?.stepInvitations || false
            });
        } catch (error: any) {
            console.error('Error:', error);
        }
    };

    const putConnector: (data: ClientItem, id: string) => Promise<void> = async (data, id) => {
        setLoadingCient(true);

        try {
            const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
            const connectorConfigurationResponse = await put(
                apiConfig.putConnectorConfiguration(id),
                data,
                token
            );
            if (!connectorConfigurationResponse.ok) {
                throw new Error('Failed to connector configuration');
            }
            getConnectors();
        } catch (error: any) {
            console.error('Error:', error);
        }
    };

    const updateConnectorTypeStatus: (data: IntegrationItem[]) => void = async (data) => {
        const updatedArray = connectorTypeDataModel.map((connectorItem) => {
            const matchingItem = data.find(
                (IntegrationItem) => IntegrationItem.connectorType === connectorItem.type
            );

            if (matchingItem) {
                return { ...connectorItem, status: matchingItem.status };
            }

            return connectorItem;
        });
        setConnectorTypeDataModel(updatedArray);
    };

    const updateIntegrationsDataModel: (
        type: string,
        id: string,
        key: string,
        value: string | boolean | ConfigurationZohoTypes
    ) => void = (type, id, key, value) => {
        const updatedData = { [key]: value };

        const integrationsData: IntegrationItem[] = id
            ? integrationsDataModel.map((item: IntegrationItem) =>
                  item.connectorId === id ? { ...item, ...updatedData } : item
              )
            : integrationsInitialDataModel.map((item: IntegrationItem) =>
                  item.connectorType === type ? { ...item, ...updatedData } : item
              );

        id
            ? setIntegrationsDataModel(integrationsData)
            : setIntegrationsInitialDataModel(integrationsData);

        setDataModified(integrationsData, type);
        console.log('integrationsData', integrationsData);

        key === 'status' &&
            ['csv', 'edi', 'fides', 'tr'].indexOf(type) > -1 &&
            saveClickHandler(type);
    };

    const saveIntegrationsStatus: (type: string, id: string, key: string, value: string) => void = (
        type,
        id,
        key,
        value
    ) => {
        const updatedData = { [key]: value };

        const integrationsData: IntegrationItem[] = integrationsDataModel.map(
            (item: IntegrationItem) =>
                item.connectorId === id ? { ...item, ...updatedData } : item
        );
        setIntegrationsDataModel(integrationsData);

        setDataModified(integrationsData, type);

        saveStatus(value, id);
    };

    const updateConfigurationDataModel: (
        type: string,
        id: string,
        key: string,
        mappingKey: string,
        value: string | number
    ) => void = (type, id, key, mappingKey, value) => {
        const updatedData: IntegrationItem[] = id
            ? integrationsDataModel.map((item) => {
                  if (item.connectorType === type) {
                      return {
                          ...item,
                          configuration: item.configuration
                              ? {
                                    ...item.configuration,
                                    [mappingKey]: {
                                        ...item.configuration[mappingKey],
                                        [key]: value
                                    }
                                }
                              : null
                      };
                  }
                  return item;
              })
            : integrationsInitialDataModel.map((item) => {
                  if (item.connectorType === type) {
                      return {
                          ...item,
                          configuration: item.configuration
                              ? {
                                    ...item.configuration,
                                    [mappingKey]: {
                                        ...item.configuration[mappingKey],
                                        [key]: value
                                    }
                                }
                              : null
                      };
                  }
                  return item;
              });
        id ? setIntegrationsDataModel(updatedData) : setIntegrationsInitialDataModel(updatedData);
        setDataModified(updatedData, type);
    };

    const updateCustomerConfigurationDataModel: (
        type: string,
        key: string,
        mappingKey: string,
        value: string | number | boolean,
        customerID: string
    ) => void = (type, key, mappingKey, value, customerID) => {
        const mappedValue = { [customerID]: value };

        const updatedData = integrationsDataModel.map((item) => {
            if (item.connectorType === type) {
                return {
                    ...item,
                    configuration: item.configuration
                        ? {
                              ...item.configuration,
                              [mappingKey]: {
                                  ...item.configuration[mappingKey],
                                  [key]: {
                                      ...(item.configuration[mappingKey]?.[key] || {}),
                                      ...mappedValue
                                  }
                              }
                          }
                        : null
                };
            }
            return item;
        });

        setIntegrationsDataModel(updatedData);
        setDataModified(updatedData, type);
    };

    const updateSelectedCustomer: (value: string) => void = (value) => {
        setSelectedCustomer(value);
    };

    const checkCsvDataUpdate: () => boolean = () => {
        if (JSON.stringify(integrationsDataModelRaw) === JSON.stringify(integrationsDataModel)) {
            return false;
        } else {
            return true;
        }
    };

    const addNewCustomerMapping: () => void = () => {
        if (checkCsvDataUpdate()) {
            toggleConnectorConfirmationClickHandler();
        } else {
            toggleCustomerMappingClickHandler();
        }
    };

    const editCustomerMappingData: (value: string) => void = (value) => {
        if (checkCsvDataUpdate()) {
            toggleConnectorConfirmationClickHandler();
        } else {
            toggleEditCustomerMappingClickHandler(value);
        }
    };

    const checkCustomerMapping: (value: string) => void = (value) => {
        setDeletedCustomer(value);
        if (checkCsvDataUpdate()) {
            toggleConnectorConfirmationClickHandler();
        } else {
            toggleConnectorDeleteConfirmationClickHandler();
        }
    };

    const resetIntegrationsDataModal: () => void = () => {
        setIntegrationsDataModel(integrationsDataModelRaw);
        toggleConnectorConfirmationClickHandler();
        clearErrors();
    };

    const setDataModified: (data: IntegrationItem[], type: string) => void = (data, type) => {
        if (JSON.stringify(integrationsDataModelRaw) === JSON.stringify(data)) {
            const modifiedData: boolean[] = isDataModified;
            modifiedData[type] = false;
            setIsDataModified(modifiedData);
        } else {
            const modifiedData: boolean[] = isDataModified;
            modifiedData[type] = true;
            setIsDataModified(modifiedData);
        }
    };

    const saveHandleKeyDown: (
        event: React.KeyboardEvent<HTMLInputElement>,
        type: string
    ) => void = (event, type) => {
        if (event.key === 'Enter') {
            saveClickHandler(type);
        }
    };

    const checkEmptyField: (type: string) => boolean = (type) => {
        const integrationData: IntegrationItem[] = getIntegrationData(type, integrationsDataModel);
        const integrationInitialData: IntegrationItem[] = getIntegrationData(
            type,
            integrationsInitialDataModel
        );

        const data: IntegrationItem =
            integrationData.length > 0 ? integrationData[0] : integrationInitialData[0];

        const isDataEmpty = data.clientId === '' || data.clientSecret === '';

        return isDataEmpty;
    };

    const deleteCustomerMapping: (customerId: string | number) => void = (customerId) => {
        const type: string = 'csv';
        const csvIntegration: IntegrationItem | undefined = integrationsDataModel.find(
            (item) => item.connectorType === type
        );

        if (!csvIntegration || customerId === '') {
            return;
        }

        const { configuration } = csvIntegration;

        const updatedConfiguration = { ...configuration };

        for (const mappingType in updatedConfiguration) {
            const mapping = updatedConfiguration[mappingType];

            if (mapping && mapping.customers && customerId in mapping.customers) {
                delete mapping.customers[customerId];
            }
        }

        const updatedIntegrationsData = integrationsDataModel.map((item) => {
            if (item.connectorType === type) {
                return {
                    ...item,
                    configuration: updatedConfiguration
                };
            }
            return item;
        });

        setIntegrationsDataModel(updatedIntegrationsData as IntegrationItem[]);
        setDataModified(updatedIntegrationsData as IntegrationItem[], type);

        saveClickHandler(type);
        toggleConnectorDeleteConfirmationClickHandler();
    };

    const getCustomerName: (value: string) => string = (value) => {
        const getCustomer: CustomerData = customers.filter(
            (customer) => customer.value === value
        )[0];

        return getCustomer?.name || '';
    };

    const getCustomers: () => any = async () => {
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        get(apiConfig.customers(`?$count=true&$expand=connector`), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const mappedData = responseData.value
                    .map((d: any) => ({
                        value: d.customerUid,
                        name: d.name
                    }))
                    .sort((a: any, b: any) =>
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    );
                setCustomers(mappedData);
            });
    };

    const getIntegrations: (type: string) => JSX.Element = (type) => {
        const integrationData: IntegrationItem[] = getIntegrationData(type, integrationsDataModel);
        const integrationInitialData: IntegrationItem[] = getIntegrationData(
            type,
            integrationsInitialDataModel
        );

        const data: IntegrationItem[] =
            integrationData.length > 0 ? integrationData : integrationInitialData;

        return (
            <ConnectorClientView
                data={data}
                updateHandler={updateIntegrationsDataModel}
                saveHandler={saveHandleKeyDown}
            />
        );
    };

    const getNonAuthDetail: (type: string) => JSX.Element = (type) => {
        const integrationData: IntegrationItem[] = getIntegrationData(type, integrationsDataModel);
        const integrationInitialData: IntegrationItem[] = getIntegrationData(
            type,
            integrationsInitialDataModel
        );

        const data: IntegrationItem =
            integrationData.length > 0 ? integrationData[0] : integrationInitialData[0];

        return (
            <IntegrationsViewClientWrapper>
                <IntegrationsViewClientContainer key={`${data.connectorId}-Client`}>
                    <IntegrationsViewButtonWrapper>
                        <IntegrationsFieldLabel>InActive</IntegrationsFieldLabel>
                        <Switch
                            {...label}
                            color="success"
                            checked={data.status === 'Active' ? true : false}
                            onChange={() =>
                                integrationData.length > 0
                                    ? saveIntegrationsStatus(
                                          data.connectorType,
                                          data.connectorId || '',
                                          'status',
                                          data.status === 'Active' ? 'InActive' : 'Active'
                                      )
                                    : updateIntegrationsDataModel(
                                          data.connectorType,
                                          data.connectorId || '',
                                          'status',
                                          data.status === 'Active' ? 'InActive' : 'Active'
                                      )
                            }
                        />
                        <IntegrationsFieldLabel>Active</IntegrationsFieldLabel>
                    </IntegrationsViewButtonWrapper>
                </IntegrationsViewClientContainer>
            </IntegrationsViewClientWrapper>
        );
    };

    const saveStatus: (status: string, connectorId: string) => void = (status, connectorId) => {
        putConnector(
            {
                status: status
            },
            connectorId
        );
    };

    const saveClickHandler: (type: string) => void = (type) => {
        const integrationsDataByType: IntegrationItem = integrationsDataModel.filter(
            (item: IntegrationItem) => item.connectorType === type
        )[0];

        const integrationsDataRawByType: IntegrationItem = integrationsDataModelRaw.filter(
            (item: IntegrationItem) => item.connectorType === type
        )[0];

        const integrationsInitialDataByType: IntegrationItem = integrationsInitialDataModel.filter(
            (item: IntegrationItem) => item.connectorType === type
        )[0];

        console.log('integrationsDataByType', integrationsDataByType);

        integrationsDataByType
            ? integrationsDataByType.clientId === integrationsDataRawByType.clientId &&
              integrationsDataByType.clientSecret === integrationsDataRawByType.clientSecret &&
              integrationsDataByType.configuration === integrationsDataRawByType.configuration &&
              integrationsDataByType.connectorType !== 'csv'
                ? saveStatus(integrationsDataByType.status, integrationsDataByType.connectorId)
                : putConnector(
                      {
                          clientId: integrationsDataByType.clientId,
                          clientSecret: integrationsDataByType.clientSecret,
                          status: integrationsDataByType.status,
                          configuration: integrationsDataByType.configuration
                      },
                      integrationsDataByType.connectorId
                  )
            : postConnector({
                  connectorType: integrationsInitialDataByType.connectorType,
                  clientId: integrationsInitialDataByType.clientId,
                  clientSecret: integrationsInitialDataByType.clientSecret,
                  configuration: integrationsInitialDataByType.configuration,
                  status: integrationsInitialDataByType.status
              });
    };

    const handleExpandedChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
            console.log(event);
        };

    const customerTableHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem(
            'Customer Name',
            'name',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem(
            'Customer ID',
            'customerId',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem('', '', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any, tableMeta: any) => (
                <CustomerIconContainer>
                    <EditIcon
                        onClick={() => editCustomerMappingData(tableMeta.rowData[1] || value)}
                    />
                    <DeleteForeverIcon onClick={() => checkCustomerMapping(tableMeta.rowData[1])} />
                </CustomerIconContainer>
            )
        })
    ];

    const toggleCustomerMappingClickHandler: () => void = () => {
        setCustomerMappingDialogOpen(!customerMappingDialogOpen);
        if (customerMappingDialogOpen) {
            setEditCustomerMapping(false);
            setSelectedCustomer('');
            setIntegrationsDataModel(integrationsDataModelRaw);
        }
    };

    const toggleEditCustomerMappingClickHandler: (value: any) => void = (value) => {
        setCustomerMappingDialogOpen(!customerMappingDialogOpen);
        setEditCustomerMapping(true);
        setSelectedCustomer(value);
    };

    const toggleConnectorConfirmationClickHandler: () => void = () => {
        setConfirmationDialogOpen(!confirmationDialogOpen);
    };

    const toggleConnectorDeleteConfirmationClickHandler: () => void = () => {
        setDeleteConfirmationDialogOpen(!deleteConfirmationDialogOpen);
        deleteConfirmationDialogOpen && setDeletedCustomer('');
    };

    // customer mapping data - table
    const getDataCustomerMapping: (data: any) => any = (data) => {
        const uniqueCustomers: any[] = [];

        const getUniqueCustomers = (configurationData: any) => {
            for (const key in configurationData) {
                if (key === 'customers' && typeof configurationData[key] === 'object') {
                    for (const customerKey in configurationData[key]) {
                        const customerId = customerKey;
                        const name = getCustomerName(customerId);

                        let isCustomerExist = false;

                        for (const existingCustomer of uniqueCustomers) {
                            if (existingCustomer.customerId === customerId) {
                                isCustomerExist = true;
                                break;
                            }
                        }

                        if (!isCustomerExist) {
                            uniqueCustomers.push({ customerId, name });
                        }
                    }
                } else if (typeof configurationData[key] === 'object') {
                    getUniqueCustomers(configurationData[key]);
                }
            }
        };

        getUniqueCustomers(data);
        return uniqueCustomers;
    };

    //Render connector footer
    const renderFooterView: (item: ConnectorTypes, loadingClient: boolean) => JSX.Element = (
        item,
        loadingClient
    ) => {
        switch (item.type) {
            case 'csv':
                return (
                    <IntegrationsViewFooter>
                        {!loadingClient && (
                            <IntegrationsViewButtonWrapper>
                                <PrimaryButton
                                    disabled={
                                        !isDataModified[item.type] || Object.keys(errors).length > 0
                                    }
                                    clickHandler={() => saveClickHandler(item.type)}
                                >
                                    <IntegrationsViewButtonTextWrapper>
                                        Save
                                    </IntegrationsViewButtonTextWrapper>
                                </PrimaryButton>
                            </IntegrationsViewButtonWrapper>
                        )}
                    </IntegrationsViewFooter>
                );

            default:
                return (
                    <IntegrationsViewFooter>
                        <IntegrationsViewLinksContainer>
                            <Typography tag="body1" fontWeight="bold">
                                <Link
                                    onClick={() =>
                                        window.open(item.integrationDocumentationLink, '_BLANK')
                                    }
                                >
                                    See how to get your developer details
                                </Link>
                            </Typography>
                            <Typography tag="body1" fontWeight="bold">
                                <Link onClick={() => window.open(item.documentationLink, '_BLANK')}>
                                    {item.name} Documentation
                                </Link>
                            </Typography>
                        </IntegrationsViewLinksContainer>
                        {!loadingClient && (
                            <IntegrationsViewButtonWrapper>
                                <PrimaryButton
                                    disabled={
                                        !isDataModified[item.type] || checkEmptyField(item.type)
                                    }
                                    clickHandler={() => saveClickHandler(item.type)}
                                >
                                    <IntegrationsViewButtonTextWrapper>
                                        Save
                                    </IntegrationsViewButtonTextWrapper>
                                </PrimaryButton>
                            </IntegrationsViewButtonWrapper>
                        )}
                    </IntegrationsViewFooter>
                );
        }
    };

    //Render connector view
    const renderConnectorDetailView: (item: ConnectorTypes) => JSX.Element = (item) => {
        switch (item.type) {
            case 'csv':
                return renderDetailCSVView(item);

            case 'edi':
            case 'fides':
            case 'tr':
                return renderNonAuthView(item);

            default:
                return renderIntegrationsView(item);
        }
    };

    //Render CSV Customer Mapping Dialog
    const renderCustomerMappingDialog: (csvItem: IntegrationItem) => JSX.Element = (csvItem) => {
        return (
            <CustomerMappingDialogWrapper>
                <CustomerMappingDialogContainer>
                    <CustomerMappingDialogTitle>
                        {editCustomerMapping ? 'Edit Customer Mapping' : 'New Customer Mapping'}
                    </CustomerMappingDialogTitle>
                    {!selectedCustomer && !editCustomerMapping && (
                        <CustomerMappingDialogInfo>
                            Please select a customer to add mapping data.
                        </CustomerMappingDialogInfo>
                    )}

                    <CustomerMappingDialogSelectWrapper>
                        {editCustomerMapping ? (
                            <SelectLabel>Customer: {getCustomerName(selectedCustomer)}</SelectLabel>
                        ) : (
                            <>
                                <SelectLabel>Customers:</SelectLabel>
                                <Select
                                    background={themeColors.white}
                                    menuItems={customers}
                                    value={selectedCustomer}
                                    name={getCustomerName(selectedCustomer)}
                                    placeholder="Select a customer"
                                    changeHandler={(event: any) =>
                                        updateSelectedCustomer(event.target.value)
                                    }
                                />
                            </>
                        )}
                        {selectedCustomer && (
                            <ConnectorCsvMappingWrapper>
                                <ConnectorCsvMapping
                                    data={csvItem}
                                    updateConfigurationHandler={
                                        updateCustomerConfigurationDataModel
                                    }
                                    register={register}
                                    errors={errors}
                                    selectedCustomer={selectedCustomer}
                                    isCustomer
                                />
                                <IntegrationsViewButtonContainer>
                                    <IntegrationsViewButtonWrapper>
                                        <PrimaryButton
                                            disabled={Object.keys(errors).length > 0}
                                            clickHandler={() => {
                                                saveClickHandler(csvItem.connectorType);
                                                toggleCustomerMappingClickHandler();
                                            }}
                                        >
                                            <IntegrationsViewButtonTextWrapper>
                                                Save
                                            </IntegrationsViewButtonTextWrapper>
                                        </PrimaryButton>
                                    </IntegrationsViewButtonWrapper>
                                </IntegrationsViewButtonContainer>
                            </ConnectorCsvMappingWrapper>
                        )}
                    </CustomerMappingDialogSelectWrapper>
                </CustomerMappingDialogContainer>
            </CustomerMappingDialogWrapper>
        );
    };

    //Render CSV connector view
    const renderDetailCSVView: (item: ConnectorTypes) => JSX.Element = (item) => {
        const integrationData: IntegrationItem[] = getIntegrationData(
            item.type,
            integrationsDataModel
        );
        const integrationInitialData: IntegrationItem[] = getIntegrationData(
            item.type,
            integrationsInitialDataModel
        );

        const data: IntegrationItem =
            integrationData.length > 0 ? integrationData[0] : integrationInitialData[0];

        return (
            <IntegrationsViewClientWrapper>
                <BasicDialog
                    open={confirmationDialogOpen}
                    clickHandler={toggleConnectorConfirmationClickHandler}
                    dialogContent={
                        <ConnectorConfirmationView
                            title="Your changes are not saved."
                            description="Pressing ‘Continue’ will revert your changes."
                            buttonText="Continue"
                            confirmHandler={resetIntegrationsDataModal}
                            closeHandler={toggleConnectorConfirmationClickHandler}
                        />
                    }
                />
                <BasicDialog
                    open={deleteConfirmationDialogOpen}
                    clickHandler={toggleConnectorDeleteConfirmationClickHandler}
                    dialogContent={
                        <ConnectorConfirmationView
                            title="Are you sure you wish to delete this customer mapping?"
                            buttonText="Delete"
                            confirmHandler={() => deleteCustomerMapping(deletedCustomer)}
                            closeHandler={toggleConnectorDeleteConfirmationClickHandler}
                        />
                    }
                />

                <IntegrationsViewConfigurationContainer key={`configuration-csv`}>
                    <IntegrationsViewButtonWrapper>
                        <IntegrationsFieldCsvLabel>InActive</IntegrationsFieldCsvLabel>
                        <Switch
                            {...label}
                            color="success"
                            checked={data.status === 'Active' ? true : false}
                            onChange={() =>
                                checkCsvDataUpdate()
                                    ? toggleConnectorConfirmationClickHandler()
                                    : integrationData.length > 0
                                    ? saveIntegrationsStatus(
                                          data.connectorType,
                                          data.connectorId || '',
                                          'status',
                                          data.status === 'Active' ? 'InActive' : 'Active'
                                      )
                                    : updateIntegrationsDataModel(
                                          data.connectorType,
                                          data.connectorId || '',
                                          'status',
                                          data.status === 'Active' ? 'InActive' : 'Active'
                                      )
                            }
                        />
                        <IntegrationsFieldLabel>Active</IntegrationsFieldLabel>
                    </IntegrationsViewButtonWrapper>

                    <AccordionDetails>
                        <AccordionCSVDetails>
                            <AccordionStyled disableGutters>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    Default Mapping
                                </AccordionSummary>
                                <AccordionDetails>
                                    {!loadingClient ? (
                                        <ConnectorCsvMapping
                                            data={data}
                                            updateConfigurationHandler={
                                                updateConfigurationDataModel
                                            }
                                            register={register}
                                            errors={errors}
                                        />
                                    ) : (
                                        <LoaderInPage height="8vh" />
                                    )}
                                    {renderFooterView(item, loadingClient)}
                                </AccordionDetails>
                            </AccordionStyled>
                            {customers.length > 0 && (
                                <AccordionStyled disableGutters>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        Customer Mapping
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <IntegrationsViewConfigurationContainer>
                                            <IntegrationsViewButtonWrapper>
                                                <PrimaryButton clickHandler={addNewCustomerMapping}>
                                                    <IntegrationsViewButtonTextWrapper>
                                                        Add New Customer Mapping
                                                    </IntegrationsViewButtonTextWrapper>
                                                </PrimaryButton>
                                            </IntegrationsViewButtonWrapper>

                                            <FullViewDialog
                                                open={customerMappingDialogOpen}
                                                clickHandler={toggleCustomerMappingClickHandler}
                                                dialogContent={renderCustomerMappingDialog(data)}
                                            />

                                            <IntegrationsCustomersViewTableContainer>
                                                <DataGrid
                                                    headers={customerTableHeaders}
                                                    data={getDataCustomerMapping(
                                                        data.configuration
                                                    )}
                                                    ariaLabel="Customer table"
                                                    ariaCaption="A table that shows customers."
                                                    fixedHeader
                                                    serverSide
                                                    tableMobileWidth="50%"
                                                    showFooter={false}
                                                />
                                            </IntegrationsCustomersViewTableContainer>
                                        </IntegrationsViewConfigurationContainer>
                                    </AccordionDetails>
                                </AccordionStyled>
                            )}
                        </AccordionCSVDetails>
                        <IntegrationsViewFooter>
                            <IntegrationsViewLinksContainer>
                                <Typography tag="body1" fontWeight="bold">
                                    <Link
                                        onClick={() =>
                                            window.open(item.integrationDocumentationLink, '_BLANK')
                                        }
                                    >
                                        See how to configure your CSV connector
                                    </Link>
                                </Typography>
                            </IntegrationsViewLinksContainer>
                        </IntegrationsViewFooter>
                    </AccordionDetails>
                </IntegrationsViewConfigurationContainer>
            </IntegrationsViewClientWrapper>
        );
    };

    //Render view for non open auth connectors (no clientid/secret)
    const renderNonAuthView: (item: ConnectorTypes) => JSX.Element = (item) => {
        return (
            <AccordionDetails>
                {!loadingClient ? getNonAuthDetail(item.type) : <LoaderInPage height="8vh" />}
            </AccordionDetails>
        );
    };

    //Render connector view
    const renderIntegrationsView: (item: ConnectorTypes) => JSX.Element = (item) => {
        return (
            <AccordionDetails>
                {!loadingClient ? getIntegrations(item.type) : <LoaderInPage height="8vh" />}
                {renderFooterView(item, loadingClient)}
            </AccordionDetails>
        );
    };

    return (
        <IntegrationsViewContainer>
            <IntegrationsViewPageTitle>Integrations</IntegrationsViewPageTitle>
            <IntegrationsViewDescription>
                Select which integrations you wish to enable. You will be asked to configure each
                according to the accounting systems own requirements.
                <br /> <br />
                Once enabled they will be available to all of your customers.
            </IntegrationsViewDescription>
            <IntegrationsViewAccordionWrapper>
                {loading ? (
                    <LoaderInPage height="15vh" />
                ) : (
                    connectorTypeDataModel &&
                    _.orderBy(connectorTypeDataModel, 'status', 'asc').map(
                        (item: ConnectorTypes, index: number) => (
                            <AccordionStyled
                                key={`${index}-integration`}
                                expanded={expanded === item.type}
                                onChange={handleExpandedChange(item.type)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`integration-item${index}-control`}
                                    id={`integration-item${index}-header`}
                                >
                                    <IntegrationsViewDataContainer>
                                        <IntegrationsViewLeft>
                                            <IntegrationsConnectionLogo src={item.iconDataUrl} />

                                            <IntegrationsViewLeftTextContainer>
                                                <IntegrationsViewText>
                                                    {item.name}
                                                </IntegrationsViewText>
                                            </IntegrationsViewLeftTextContainer>
                                        </IntegrationsViewLeft>
                                        <IntegrationsViewConnectedStatus
                                            status={item.status === 'Active'}
                                        >
                                            {item.status}
                                        </IntegrationsViewConnectedStatus>
                                    </IntegrationsViewDataContainer>
                                </AccordionSummary>

                                {renderConnectorDetailView(item)}
                            </AccordionStyled>
                        )
                    )
                )}
            </IntegrationsViewAccordionWrapper>
            <IntegrationsViewDescription>
                If there is an integration you would like us to add please{' '}
                <Link onClick={() => window.open('mailto:hello@finecta.com')}>let us know. </Link>
            </IntegrationsViewDescription>
        </IntegrationsViewContainer>
    );
};

export default IntegrationsView;
