import { boxShadow, device, size, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import { Accordion } from '@mui/material';
import styled from 'styled-components';

type BackgroundColorType = {
    bgColor: string;
};

type ConnectionType = {
  showConnection?: boolean;
  fullWidth?: boolean;
};


export const ConnectionBannerContainer = styled.div<ConnectionType>`
    width: 100%;
    z-index: 999;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: ${({ fullWidth }) => fullWidth ? 'column' : 'row'};
    gap: 20px;
`;


export const ConnectionBannerCollapse = styled.div<ConnectionType>`
  display: flex;
  width: ${({ showConnection }) => showConnection ? '100%' : '350px'};
  @media ${device.mobileOnly} {
    width: 100%;
    min-height: ${({ showConnection }) => showConnection ? '220px' : 'fit-content'};
  }
`;

export const ConnectionBannerTitleName = styled.h1`
    margin: 0;
    font-size: ${fontSizes.medium};
    font-weight: ${fontWeights.bold};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    cursor: default;

    @media ${device.mobileOnly} {
        font-size: ${fontSizes.regular};
    }
`;

export const ConnectionBannerInformationRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    margin-bottom: 16px;
    @media ${device.mobileOnly} {
        grid-template-columns: 1fr;
        gap: 16px;
    }
    
`;

export const ConnectionBannerConnectionsContainer = styled.div<ConnectionType>`
    display: flex;
    flex-direction: ${({ fullWidth }) => fullWidth ? 'column' : 'row'};
    justify-content: space-between;
    gap: 10px;
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
    
    @media ${device.mobileOnly} {
      justify-content: flex-start;
      width: 100%;
    }  
`;

export const ConnectionBannerConnectionContainer = styled.div<ConnectionType>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ fullWidth }) => fullWidth ? '16px 32px' : '16px'};
    width: 100%;
    background-color: ${themeColors.white};
    border-radius: 8px;
    box-shadow: ${boxShadow};
    
    @media ${device.mobileOnly} {
        width:  calc(100% - 16px);
        flex-direction: ${({ showConnection }) => showConnection ? 'column' : 'row'};
    }
`;

export const ConnectionBannerConnectionsArrowContainer = styled.div<ConnectionType>`
    display: flex;
    margin-left: 20px;
    cursor: pointer;

    svg {
      transform: ${({ showConnection }) => showConnection ? 'rotate(180deg)' : 'rotate(360deg)'};
      width: 26px;
      height: 26px;
    }
    
    @media ${device.mobileOnly} {
      margin-left: 0;
      width: ${({ showConnection }) => showConnection ? '100%' : 'auto'};
      justify-content: center;

      svg {
        width: 26px;
        height: 26px;
        transform: ${({ showConnection }) => showConnection ? 'rotate(-90deg)' : 'rotate(90deg)'};
      }
    }
`;

export const ConnectionBannerConnectionLeftTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const ConnectionBannerConnectionTextConnected = styled.div<BackgroundColorType>`
    display: flex;
    background-color: ${({ bgColor }) => bgColor || themeColors.success};
    color: ${themeColors.white};
    font-size: ${fontSizes.xsmall};
    border-radius: 8px;
    width: fit-content;
    padding: 5px;
    height: fit-content;
`;

export const ConnectionBannerConnectionLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const ConnectionBannerConnectionRightTextContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
`;

export const ConnectionBannerConnectionText = styled.span`
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
`;

export const ConnectionBannerConnectionTextBold = styled.span`
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
    font-weight: ${fontWeights.bold};
`;

export const ConnectionBannerConnectionSettingsIcon = styled.div`
  display: flex;
  margin-left: 40px;
  cursor: pointer;

  @media ${device.mobileOnly} {
      margin-left: 0;
    }
`;


export const ConnectionBannerConnectionRight = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    min-width: 400px;
    gap: 10px;

    .MuiSvgIcon-root {
        width: 26px;
        height: 26px;
    }

    .MuiSwitch-root {
        .MuiButtonBase-root {
            &.Mui-checked + .MuiSwitch-track {
                background-color: ${themeColors.darkGrey}!important;
                box-shadow: none !important;
            }

            .MuiSwitch-thumb {
                background-color: ${themeColors.lightGrey}!important;
                box-shadow: none !important;
            }
            &.Mui-checked {
                .MuiSwitch-thumb {
                    background-color: ${themeColors.darkGrey}!important;
                }
            }
        }
        .MuiSwitch-track {
            background-color: ${themeColors.lightGrey}!important;
        }
    }

    @media ${device.mobileOnly} {
      min-width: auto;  
      flex-direction: column;
    }
`;

export const ConnectionBannerConnectionLogo = styled.img`
    width: 40px;
`;

export const AccordionStyled = styled(Accordion)`
    &.MuiPaper-root {
        background-color: transparent !important;
        box-shadow: none;

        ::before {
            display: none;
        }

        .MuiAccordionSummary-root {
            width: 100%;
            padding: 8px 32px;
            align-items: center;
            ${typography.bodyBold};
            background-color: ${themeColors.lightGrey};
            &.Mui-expanded {
                margin: 0;
            }
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;
    }
    .MuiAccordionSummary-expandIconWrapper {
        color: ${themeColors.body};
        transform: rotate(-90deg);
        &.Mui-expanded {
            transform: rotate(360deg);
        }
    }
    .MuiAccordionSummary-root {
        &.Mui-disabled {
            opacity: 1;
        }
    }
    &.Mui-disabled {
        background-color: transparent !important;
    }
`;

export const LoaderInCircleContainer = styled.div`
    display: block;
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.body};
    position: relative;
    width: 26px;
    height: 26px;
`;

export const ConnectionBannerSnackBarContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: column;
`;

export const ConnectionBannerSnackBarTitle = styled.p`
  font-weight: ${fontWeights.bold};
`;

export const ConnectionBannerConnectionMessage = styled.div`
    display: flex;
    width: 400px;
    line-height: ${fontSizes.medium};
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
`;

export const ConnectionBannerConnectionStepsContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    justify-content: flex-start;
    @media ${device.mobileOnly} {
      display: none;
    }
`;

export const ConnectionBannerConnectionSteps = styled.div`
    display: flex;
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
    justify-content: space-between;
    gap: 5px;
`;


