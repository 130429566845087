import { FC, ReactNode } from 'react';
import { PrimaryButtonStyled } from './styled';
import { ButtonProps } from 'utils/interfaces/button/button.interface';

export interface PrimaryButtonProps extends ButtonProps {
    children?: ReactNode;
    testingTagLocation?: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
    text = '',
    clickHandler = (_e?: any) => {},
    textColor,
    hoverColor,
    borderColor,
    backgroundColor,
    disabled = false,
    children,
    padding,
    borderRadius,
    gap,
    minWidth,
    height,
    type,
    testingTagLocation,
    width
}) => (
    <PrimaryButtonStyled
        data-testid={`finecta-primary-button${testingTagLocation || ''}`}
        onClick={(e) => clickHandler(e)}
        textColor={textColor}
        hoverColor={hoverColor}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        disabled={disabled}
        padding={padding}
        borderRadius={borderRadius}
        gap={gap}
        minWidth={minWidth}
        height={height}
        type={type}
        width={width}
    >
        {children || text}
    </PrimaryButtonStyled>
);

export default PrimaryButton;
