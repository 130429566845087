import styled from 'styled-components';

export const TabsContainer = styled.div`
  border-radius: 10px;
  margin-top: 1rem;
`;

export const TabsStyled = styled.div`
  list-style: none;
  display: flex;
  gap: 0.5rem;
  > li {
    cursor: pointer;
    padding: 1rem;
    background: #e1e1e1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    &:hover {
      background: #e9e9e9;
    }
    &.active {
      background: #fec70b;
      font-weight: bold;
    }
  }
`;

export const Content = styled.div``;
