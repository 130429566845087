import { AuthenticationResult, InteractionRequiredAuthError, InteractionStatus, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { _B2C_API_PATH } from 'config/constants';

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1A_PARTNER_SIGNUP_SIGNIN',
        forgotPassword: 'B2C_1A_REDEEMRESETPASSWORD'
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${window.spaSettings.b2cDomain}/${window.spaSettings.tenantId}/B2C_1A_PARTNER_SIGNUP_SIGNIN`
        },
        inviteRedeem: {
            authority: `https://${window.spaSettings.b2cDomain}/${window.spaSettings.tenantId}/B2C_1A_INVITEREDEEM`
        },
        forgotPassword: {
            authority: `https://${window.spaSettings.b2cDomain}/${window.spaSettings.tenantId}/B2C_1A_REDEEMRESETPASSWORD`
        }
    },
    authorityDomain: `https://${window.spaSettings.b2cDomain}`
};

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: window.spaSettings.appClientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

// Authentication Parameters
export const defaultAuthenticationParameters = {
    baseUrl: 'https://tlccstagingnimbla-g2f-as.azurewebsites.net',
    //baseUrl: '',
    scopes: [_B2C_API_PATH],
    state: btoa(
        JSON.stringify({
            redirectUrl: window.location.origin,
            campaignId: window.location.hostname
        })
    ),
    extraQueryParameters: {
        campaignId: window.location.hostname
    }
};

export const loginRequest = {
    scopes: []
};

export const authenticationParameters = defaultAuthenticationParameters;

const apiParameters = {
    base: `https://${window.spaSettings.instanceName}.onmicrosoft.com`
};

const request = {
    scopes: [`${apiParameters.base}/${window.spaSettings.apiClientId}/Api`],
    extraQueryParameters: {
        campaignId: window.location.hostname
    }
};

export const authResult: (msalInstance: PublicClientApplication, isAuthenticated: boolean, inProgress: InteractionStatus) => Promise<string> = async (
    msalInstance,
    isAuthenticated,
    inProgress
) => {
    if (!isAuthenticated && inProgress === InteractionStatus.None)
    {
        await msalInstance.loginRedirect(request);
        return '';
    }

    try {
        const authResult: AuthenticationResult = await msalInstance.acquireTokenSilent(request);
        return authResult.accessToken;
    } catch (error) {
        if (inProgress === InteractionStatus.None)
        {
            await msalInstance.loginRedirect(request);
        }
        return '';
    }
};
