import { combineReducers } from "lib/helpers/combineReducer";
import { AppReducer } from "./appReducer";
import { CustomerReducer } from "./customerReducer";
import { ICustomersData } from "utils/interfaces/customers.interface";
import { SnackbarData } from "utils/interfaces/app.interface";
import { ConnectorTypes } from "utils/interfaces/IntegrationItem";

interface InitialStateType {
  app: {
    token: string;
    snackbarData: SnackbarData | null;
    snackbarOpen: boolean;
    stepIntegrations: boolean;
    stepInvitations: boolean;
    connectors: ConnectorTypes[];
  };

  customer:{
    customers: ICustomersData[]
  }
}


export const initialState: InitialStateType = {
  app : {
    token :  "",
    snackbarData: null,
    snackbarOpen: false,
    stepIntegrations: false,
    stepInvitations: false,
    connectors: []
  },
  customer : {
    customers: []
  }
};

export const mainReducer = combineReducers({
  app : AppReducer,
  customer : CustomerReducer
});