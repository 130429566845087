import { device, themeColors, boxShadow } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

type BackgroundColorType = {
  bgColor: string;
};

export const ResetConnectorWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
`;

export const ResetConnectorHeader = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
`;

export const ResetConnectorCustomerInformationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobileOnly} {
    flex-direction: column;
    gap: 16px;
  } 
`;

export const ResetConnectorCustomerInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ResetConnectorCustomerInformationTitleName = styled.h1`
    margin: 0;
    font-size: ${fontSizes.medium};
    font-weight: ${fontWeights.bold};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    cursor: default;

    @media ${device.mobileOnly} {
        font-size: ${fontSizes.regular};
    }
`;

export const ResetConnectorCustomerInformationTextConnected = styled.div<BackgroundColorType>`
    display: flex;
    background-color: ${({ bgColor }) => bgColor || themeColors.success};
    color: ${themeColors.white};
    font-size: ${fontSizes.xsmall};
    border-radius: 8px;
    width: fit-content;
    padding: 5px;
    height: fit-content;
`;

export const ResetConnectorCustomerInformationTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const  ResetConnectorCustomerInformationText = styled.span`
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
`;

export const  ResetConnectorCustomerInformationTextBold = styled.span`
    font-size: ${fontSizes.xsmall};
    color: ${themeColors.darkGrey};
    font-weight: ${fontWeights.bold};
`;

export const ResetConnectorHeaderTextWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const ResetConnectorHeaderText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const ResetConnectorSnackBarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: column;
`;

export const ResetConnectorSnackBarTitle = styled.p`
  font-weight: ${fontWeights.bold};
`;

export const ResetConnectorConnectionMessage = styled.div`
  display: flex;
  font-size: ${fontSizes.xsmall};
  color: ${themeColors.white};
  justify-content: space-between;
  gap: 5px;
`;

export const ResetConnectorHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
`;

export const ResetConnectorChild = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;

  @media ${device.desktopS} {
    flex-direction: column;
  } 
`;

export const ResetConnectorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 30px;

  @media ${device.mobileOnly} {
    width: 100%;
  } 
`;

export const ResetConnectorChildContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  min-width: 240px;
  background-color: ${themeColors.white};
  border-radius: 8px;
  box-shadow: ${boxShadow};
  padding: 16px 32px; 
  gap: 20px;

  @media ${device.mobileOnly} {
    padding: 8px 16px; 
  } 
`;

export const ResetConnectorHeaderFlexible = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
`;
