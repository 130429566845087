
import styled from "styled-components";
import { Paper } from "@mui/material";
import { boxShadow, themeColors } from "assets/theme/style";
import InputBase from '@mui/material/InputBase';
import { fontSizes, typography } from "assets/theme/typography";

interface ChatViewProps {
  fullView: boolean;
}

interface ChatButtonProps {
  color: string;
  disabled: boolean;
}

export const ChatViewWrapper = styled.div<ChatViewProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
  width: ${({ fullView }) => fullView ? '100%' : '500px'};
  height: ${({ fullView }) => fullView ? '100vh' : 'fit-content'};
  position: fixed;
  top: ${({ fullView }) => fullView ? '0' : 'auto'};
  bottom: ${({ fullView }) => fullView ? 'auto' : '60px'};
  right: ${({ fullView }) => fullView ? '0' : '30px'};
  z-index: 9999;
`;

export const ChatViewLogo = styled.img`
  height: 14px;
`;

export const ChatViewHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ChatViewHeader = styled.div`
  display: flex;
  padding: 0 16px;
  height: 52px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: ${themeColors.primary};
`;

export const ChatViewContainer = styled.div<ChatViewProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ fullView }) => fullView ? '0' : '-28px'};
  max-width: ${({ fullView }) => fullView ? '100%' : '500px'};
  height: ${({ fullView }) => fullView ? '100%' : 'auto'};
  width: 100%;
  border-radius: 8px;
  background-color: ${themeColors.white};
  box-shadow: ${boxShadow};
`;

export const ChatViewIconWrapper = styled.div`
  display: flex;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  box-shadow: ${boxShadow};
  background-color: ${themeColors.primary};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ChatViewQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ChatViewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px 40px 30px;
`;

export const ChatViewMessageWrapper = styled.div<ChatViewProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: ${({ fullView }) => fullView ? 'calc(100vh - 280px)' : 'calc(100vh - 400px)'};
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 20px;

  ::-webkit-scrollbar {
    width: 6px;               
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${themeColors.darkGrey};    
    border-radius: 20px;
  }
`;

export const ChatViewMessageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${themeColors.background};
  width: calc(100% - 32px);
  border-radius: 8px;
  padding: 16px;
`;


export const ChatViewMessageTime = styled.div`
  display: flex;
  position: absolute;
  top: 8px; right: 8px;
  font-size: ${fontSizes.xsmall};
  color: ${themeColors.darkGrey};
`;

export const ChatViewMessageHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center; 
  gap: 8px;
  ${typography.bodySemiBold};
`;

export const ChatViewMessage = styled.div`
  display: flex;
  padding-left: 39px;
`;

export const ChatViewQuestionWrapper = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border: none;
  padding: 4px 8px;

  &.MuiPaper-root{
    box-shadow: none;
    border: 1px solid ${themeColors.lightGrey};
  }

  .MuiInputBase-root{
    width: calc(100% - 80px);
    margin-right: 50px;
  }
`;

export const ChatViewInput = styled(InputBase)`
  border: none;
`;

export const ChatViewWarning = styled.p`
  color: ${themeColors.darkGrey};
  font-size: ${fontSizes.xsmall};
`;

export const SearchIconContainer = styled.div<ChatButtonProps>`
  background-color: ${({ color }) => color};
  pointer-events: ${({ disabled }) => !disabled ? 'none' : 'auto'};
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;

  svg {
    fill: #fff;
  }
`;

export const ChatViewMessageLoading = styled.div`
  width: 50px;
  padding: 0 30px;
`;

export const ChatViewFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: ${themeColors.dark};
`;

export const MinimizeIconWrapper = styled.div`
  cursor: pointer;
  margin-top: -20px;
  display: flex;
`;

export const FullscreenIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

export const ChatViewButtonText = styled.p`
  margin: 0;
  ${typography.captionBold};
`;

export const ChatViewButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;

export const ChatViewButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  flex-direction: column;
  gap: 10px;
`;