/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { apiConfig, get, post } from 'api/api';
import { themeColors } from 'assets/theme/style';
import { authResult } from 'lib/authConfig';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Context from 'context';
import { useNavigate } from 'react-router-dom';
import FullViewDialog from 'components/dialog/FullViewDialog';
import { FC, useEffect, useState, useContext } from 'react';
import {
    ConnectionBannerConnectionContainer,
    ConnectionBannerConnectionLeft,
    ConnectionBannerConnectionLeftTextContainer,
    ConnectionBannerConnectionLogo,
    ConnectionBannerConnectionMessage,
    ConnectionBannerConnectionRight,
    ConnectionBannerConnectionRightTextContainer,
    ConnectionBannerConnectionSteps,
    ConnectionBannerConnectionStepsContainer,
    ConnectionBannerConnectionText,
    ConnectionBannerConnectionTextConnected,
    ConnectionBannerConnectionsContainer,
    ConnectionBannerContainer,
    ConnectionBannerSnackBarContainer,
    ConnectionBannerSnackBarTitle,
    LoaderInCircleContainer,
    ConnectionBannerConnectionsArrowContainer,
    ConnectionBannerTitleName,
    ConnectionBannerConnectionTextBold,
    ConnectionBannerConnectionSettingsIcon,
    ConnectionBannerCollapse
} from './styled';
import LoaderCircle from 'components/LoaderCircle/LoaderCircle';
import _ from 'lodash';
import { triggerSnackbar } from 'context/action/app';
import { ConnectorTypes } from 'utils/interfaces/IntegrationItem';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { Link } from 'components/link/styled';
import ConnectionSyncSteps from 'components/ConnectionSyncSteps';
import ResetConnector from 'components/ResetConnector';
import Tooltip from '@mui/material/Tooltip';

export interface ConnectionBannerProps {
    customerId: string;
    fullWidth?: boolean;
}
export interface CustomerDetailType {
    connector: ConnectionBannerConnectorType;
    createdDate: string;
    active: boolean;
    customerUid: string;
    name: string;
    registrationNumber: string;
    contactEmail: string;
    contactName: string;
}

export interface ConnectionBannerConnectorType {
    connectorUid: string;
    name: string;
    status: string;
    type: string;
    lastActivityDate: string;
}

export interface CustomerSyncType {
    syncId: string;
    createdDate: string;
    status: string;
    steps: CustomerSyncStepsType[];
}

export interface CustomerSyncStepsType {
    id: string | null;
    startTime: string | null;
    endTime: string | null;
    name: string | null;
    status: string | null;
    reference: string | null;
    referenceName: string | null;
    errorResponse: string | null;
    childSteps: any;
}

const ConnectionBanner: FC<ConnectionBannerProps> = ({ customerId, fullWidth = false }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [loading, setLoading] = useState<boolean>(false);
    const [showConnection, setShowConnection] = useState<boolean>(false);
    const [customer, setCustomer] = useState<CustomerDetailType | null>(null);
    const [customerSyncSteps, setCustomerSyncSteps] = useState<CustomerSyncType | null>(null);
    const [customerSyncRunning, setCustomerSyncRunning] = useState<boolean>(false);
    const [customersCount, setCustomersCount] = useState<number>(0);
    const [invoicesCount, setInvoicesCount] = useState<number>(0);
    const [connectorTypes, setConnectorTypes] = useState<ConnectorTypes[]>([]);
    const [customerSyncDialogOpen, setCustomerSyncDialogOpen] = useState<boolean>(false);
    const [deleteDataDialogOpen, setDeleteDataDialogOpen] = useState<boolean>(false);
    const [customerSyncData, setCustomerSyncData] = useState<any>(null);

    const groupedSteps: _.Dictionary<CustomerSyncStepsType[]> = _.groupBy<CustomerSyncStepsType>(
        customerSyncSteps?.steps,
        'name'
    );

    const connectors: ConnectorTypes[] | null = state?.app?.connectors || null;

    let count = 0;

    const showConnectionHandleChange: () => void = () => {
        setShowConnection((prev) => !prev);
    };

    const getStepCount = (orchestrator: string, stepName: string) =>
        _.groupBy<any>(groupedSteps[orchestrator]?.[0]?.childSteps, 'name')?.[stepName]?.length ||
        0;

    useEffect(() => {
        connectors && setConnectorTypes(connectors);
    }, [connectors]);

    useEffect(() => {
        if (customerSyncSteps) {
            setCustomersCount(getStepCount('CustomersOrchestrator', 'StoreCustomer'));
            setInvoicesCount(getStepCount('InvoicesOrchestrator', 'StoreInvoice'));
        }
    }, [customerSyncSteps]);

    useEffect(() => {
        setLoading(true);
        if (count === 0) {
            count++;
            getCustomer();
            getCustomerSync();
        }
        fullWidth && setShowConnection(true);
    }, []);

    const toggleCustomerSyncClickDialogHandler: () => void = () => {
        setCustomerSyncDialogOpen(!customerSyncDialogOpen);
    };
    const toggleDeleteDataDialogHandler: () => void = () => {
        setDeleteDataDialogOpen(!deleteDataDialogOpen);
    };
    const getCustomer: () => any = async () => {
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        get(apiConfig.customerDetail(customerId), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const {
                    connector,
                    createdDate,
                    active,
                    customerUid,
                    name,
                    registrationNumber,
                    contactEmail,
                    contactName
                }: CustomerDetailType = responseData.value[0];

                setCustomer({
                    connector,
                    createdDate,
                    active,
                    customerUid,
                    name,
                    registrationNumber,
                    contactEmail,
                    contactName
                });

                setLoading(false);
            });
    };

    const getCustomerSync: () => void = async () => {
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        await get(
            apiConfig.customersSync(
                `?$orderby=createdDate desc&$filter=customerUid eq '${customerId}'`
            ),
            token
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setCustomerSyncData(responseData.value[0]);
                setCustomerSyncRunning(false);
                if (responseData.value[0].status !== 'Completed') {
                    setTimeout(getCustomerSync, 5000);
                }
            });
    };
    const getCustomersSyncData: (id: string) => any = async (id) => {
        setCustomerSyncRunning(true);
        let count: number = 0;
        let countSync: number = 0;
        if (count === 10) return;
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        const customersSyncResponse = await get(apiConfig.getCustomersSync(`${id}`), token);
        if (customersSyncResponse?.status === 404 && countSync < 10) {
            countSync++;
            setTimeout(() => {
                getCustomersSyncData(id);
            }, 10000);
            return;
        }
        if (customersSyncResponse?.status === 200) {
            count++;
            const customersSyncResponseJson = await customersSyncResponse.json();
            if (customersSyncResponseJson?.status !== 'Completed' && countSync < 10) {
                setTimeout(() => {
                    getCustomersSyncData(id);
                }, 10000);
                return;
            } else {
                setCustomerSyncRunning(false);
                setCustomerSyncSteps(customersSyncResponseJson);
                getCustomerSync();
                const groupedSyncSteps: _.Dictionary<CustomerSyncStepsType[]> =
                    _.groupBy<CustomerSyncStepsType>(customersSyncResponseJson?.steps, 'name');
                const groupedcustomersSyncSteps = (orchestrator: string, stepName: string) =>
                    _.groupBy<any>(groupedSyncSteps[orchestrator]?.[0]?.childSteps, 'name')?.[
                        stepName
                    ]?.length || 0;

                triggerSnackbar(dispatch, {
                    open: true,
                    data: {
                        message: (
                            <ConnectionBannerSnackBarContainer>
                                <ConnectionBannerSnackBarTitle>
                                    {customer?.name} sync completed.
                                </ConnectionBannerSnackBarTitle>
                                <ConnectionBannerConnectionStepsContainer>
                                    <ConnectionBannerConnectionSteps
                                        style={{ color: themeColors.white }}
                                    >
                                        Total customers imported:{' '}
                                        <strong>
                                            {groupedcustomersSyncSteps(
                                                'CustomersOrchestrator',
                                                'StoreCustomer'
                                            )}
                                        </strong>
                                    </ConnectionBannerConnectionSteps>
                                    <ConnectionBannerConnectionSteps
                                        style={{ color: themeColors.white }}
                                    >
                                        Total invoices imported:{' '}
                                        <strong>
                                            {groupedcustomersSyncSteps(
                                                'InvoicesOrchestrator',
                                                'StoreInvoice'
                                            )}
                                        </strong>
                                    </ConnectionBannerConnectionSteps>
                                </ConnectionBannerConnectionStepsContainer>
                            </ConnectionBannerSnackBarContainer>
                        )
                    }
                });
            }
        }
    };

    const setCustomersSync: () => any = async () => {
        setCustomerSyncRunning(true);
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        const customersSyncResponse = await post(
            apiConfig.postCustomersSync(`${customerId}`),
            '',
            token
        );
        if (!customersSyncResponse.ok) return;
        const customersSyncResponseJson = await customersSyncResponse.json();

        customersSyncResponseJson.id && getCustomersSyncData(customersSyncResponseJson.id);
    };

    const statusColor: (status: string | undefined) => string = (status) => {
        switch (status) {
            case 'Reauthenticate':
                return themeColors.warning;
            case 'Active':
                return themeColors.success;
            case 'No active connector':
                return themeColors.darkGrey;
            default:
                return themeColors.error;
        }
    };

    const getConnectorTypeLogo = (type: string): string => {
        if (type === '') return '';

        const groupedConnectorTypes: _.Dictionary<ConnectorTypes[]> = _.groupBy<ConnectorTypes>(
            connectorTypes,
            'type'
        );

        return groupedConnectorTypes[type]?.[0]?.iconDataUrl ?? '';
    };

    return (
        <ConnectionBannerContainer fullWidth={fullWidth}>
            {loading ? (
                <LoaderInPage height="67px" />
            ) : (
                <>
                    <ConnectionBannerConnectionsContainer fullWidth={fullWidth}>
                        <ConnectionBannerCollapse showConnection={showConnection}>
                            {customer?.connector ? (
                                <ConnectionBannerConnectionContainer
                                    showConnection={showConnection}
                                    fullWidth={fullWidth}
                                >
                                    <ConnectionBannerConnectionLeft>
                                        {fullWidth ? (
                                            <ConnectionBannerConnectionLogo
                                                src={getConnectorTypeLogo(
                                                    customer?.connector?.type || ''
                                                )}
                                                alt={`${customer?.connector?.name || ''} Logo`}
                                            />
                                        ) : (
                                            <ConnectionBannerTitleName title={customer?.name || ''}>
                                                {customer?.name || ''}
                                            </ConnectionBannerTitleName>
                                        )}
                                        <ConnectionBannerConnectionLeftTextContainer>
                                            <ConnectionBannerConnectionTextConnected
                                                bgColor={statusColor(
                                                    customer?.connector.status || ''
                                                )}
                                            >
                                                {customer?.connector?.status}
                                            </ConnectionBannerConnectionTextConnected>
                                        </ConnectionBannerConnectionLeftTextContainer>
                                        <ConnectionBannerConnectionLeftTextContainer>
                                            <ConnectionBannerConnectionText>
                                                Source:
                                            </ConnectionBannerConnectionText>
                                            <ConnectionBannerConnectionTextBold>
                                                {customer?.connector?.name?.toUpperCase() || ''}
                                            </ConnectionBannerConnectionTextBold>
                                        </ConnectionBannerConnectionLeftTextContainer>
                                    </ConnectionBannerConnectionLeft>
                                    {showConnection && (
                                        <ConnectionBannerConnectionRight>
                                            <ConnectionBannerConnectionRightTextContainer>
                                                <ConnectionBannerConnectionSettingsIcon
                                                    onClick={() =>
                                                        navigate(`/customers/${customerId}`)
                                                    }
                                                >
                                                    <SettingsIcon color="action" />
                                                </ConnectionBannerConnectionSettingsIcon>
                                                {customerSyncData?.createdDate && (
                                                    <ConnectionBannerConnectionText>
                                                        <Tooltip title="Import Details">
                                                            <Link
                                                                onClick={
                                                                    toggleCustomerSyncClickDialogHandler
                                                                }
                                                            >
                                                                Last import:
                                                                {formatDateTime(
                                                                    customerSyncData?.createdDate ||
                                                                        '',
                                                                    'F'
                                                                )}
                                                            </Link>
                                                        </Tooltip>
                                                    </ConnectionBannerConnectionText>
                                                )}
                                            </ConnectionBannerConnectionRightTextContainer>
                                            {customer?.connector.status === 'Active' &&
                                                (!customerSyncRunning &&
                                                ((customerSyncData &&
                                                    customerSyncData?.status === 'Completed') ||
                                                    !customerSyncData) ? (
                                                    <Tooltip title="Import">
                                                        <PlayCircleFilledWhiteIcon
                                                            style={{
                                                                color: `${themeColors.primary}`,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => setCustomersSync()}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <LoaderInCircleContainer>
                                                        <LoaderCircle />
                                                    </LoaderInCircleContainer>
                                                ))}
                                            <Tooltip title="Reset Customer Connector">
                                                <DeleteSweepIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={toggleDeleteDataDialogHandler}
                                                    fontSize="large"
                                                    color="action"
                                                />
                                            </Tooltip>
                                        </ConnectionBannerConnectionRight>
                                    )}
                                    {!fullWidth && (
                                        <ConnectionBannerConnectionsArrowContainer
                                            showConnection={showConnection}
                                        >
                                            <ArrowForwardIosIcon
                                                onClick={showConnectionHandleChange}
                                                fontSize="large"
                                                color="action"
                                            />
                                        </ConnectionBannerConnectionsArrowContainer>
                                    )}
                                </ConnectionBannerConnectionContainer>
                            ) : (
                                <ConnectionBannerConnectionContainer
                                    showConnection={showConnection}
                                >
                                    <ConnectionBannerConnectionLeft>
                                        <ConnectionBannerTitleName title={customer?.name || ''}>
                                            {customer?.name || ''}
                                        </ConnectionBannerTitleName>
                                        <ConnectionBannerConnectionLeftTextContainer>
                                            <ConnectionBannerConnectionTextConnected
                                                bgColor={statusColor('No active connector')}
                                            >
                                                No active connector
                                            </ConnectionBannerConnectionTextConnected>
                                        </ConnectionBannerConnectionLeftTextContainer>
                                    </ConnectionBannerConnectionLeft>
                                </ConnectionBannerConnectionContainer>
                            )}
                        </ConnectionBannerCollapse>
                    </ConnectionBannerConnectionsContainer>
                </>
            )}
            {customer?.connector &&
                (customerSyncRunning ||
                    (customerSyncData && customerSyncData?.status !== 'Completed')) && (
                    <ConnectionBannerConnectionStepsContainer>
                        <ConnectionBannerConnectionMessage>
                            This sync may take a while. <br></br>Feel free to close or navigate away
                            from this page. The sync will continue.
                        </ConnectionBannerConnectionMessage>
                    </ConnectionBannerConnectionStepsContainer>
                )}
            {customerSyncSteps?.steps && !customerSyncRunning && (
                <ConnectionBannerConnectionStepsContainer>
                    <ConnectionBannerConnectionSteps>
                        Total customers imported: <strong>{customersCount}</strong>
                    </ConnectionBannerConnectionSteps>
                    <ConnectionBannerConnectionSteps>
                        Total invoices imported: <strong>{invoicesCount}</strong>
                    </ConnectionBannerConnectionSteps>
                </ConnectionBannerConnectionStepsContainer>
            )}
            <FullViewDialog
                open={customerSyncDialogOpen}
                clickHandler={toggleCustomerSyncClickDialogHandler}
                dialogContent={
                    <ConnectionSyncSteps syncId={customerSyncData?.syncId || ''} />
                }
            />
            <FullViewDialog
                open={deleteDataDialogOpen}
                clickHandler={toggleDeleteDataDialogHandler}
                dialogContent={
                    <ResetConnector
                        customerUid={customerId}
                        name={customer?.name || ''}
                        status={customer?.connector?.status || ''}
                        source={customer?.connector?.name?.toUpperCase() || ''}
                        lastImport={formatDateTime(customerSyncData?.createdDate || '', 'F')}
                    />
                }
            />
        </ConnectionBannerContainer>
    );
};

export default ConnectionBanner;
