import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { apiConfig, post } from 'api/api';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import Card from 'components/common/card';
import { Link } from 'components/link/styled';
import Typography from 'components/typography';
import { FC, useState } from 'react';
import { authResult } from 'lib/authConfig';

import {
    CardContentContainer,
    PaymentCardContainer,
    PaymentCardTitleCostContainer,
    PaymentCardTitleRow,
    PaymentContainer,
    PaymentSectionContainer
} from './styled';

const Payment: FC = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [selectedPlan, setSelectedPlan] = useState<'standard' | 'enterprise'>('standard');
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

    const onSubmit = () => stripeRequiredPaymentClickHandler();

    const stripeRequiredPaymentClickHandler: () => void = async () => {
        const apiParameters = {
            base: `https://${window.spaSettings.instanceName}.onmicrosoft.com`
        };

        const request = {
            scopes: [`${apiParameters.base}/${window.spaSettings.apiClientId}/Api`],
            extraQueryParameters: {
                campaignId: window.location.hostname
            }
        };
        
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);

        const postModel = {
            successUrl: window.location.origin,
            cancelUrl: window.location.origin,
            priceId: window.spaSettings.pricing.standard
        };

        const res = await post(apiConfig.billing(), postModel, token);
        if (!res.ok) return;
        const json = await res.json();
        window.location.href = json.url;
    };

    return (
        <PaymentContainer>
            <PaymentSectionContainer>
                <Typography tag="h1" fontWeight="regular">
                    Select <span style={{ fontWeight: 700 }}>your plan</span>
                </Typography>
                <Typography tag="h4" fontWeight="regular">
                    Free trial lasts for 30 days. After that, billing will accrue, and your card
                    charged monthly in arrears. You may cancel your account at any time.
                </Typography>
            </PaymentSectionContainer>
            <PaymentSectionContainer>
                <PaymentCardContainer>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Card
                                height="7rem"
                                bgColor={`${themeColors.primary}80`}
                                boxShadow={
                                    selectedPlan === 'standard' ? '#6d5fa5 0px 0px 15px' : ''
                                }
                            >
                                <CardContentContainer onClick={() => setSelectedPlan('standard')}>
                                    <PaymentCardTitleRow>
                                        <Typography tag="h2" fontWeight="regular">
                                            Standard plan
                                        </Typography>
                                        <PaymentCardTitleCostContainer>
                                            <Typography tag="h2">£25pm</Typography>
                                            <Typography tag="subheading1">Per customer</Typography>
                                        </PaymentCardTitleCostContainer>
                                    </PaymentCardTitleRow>
                                    <Typography tag="body1">
                                        The standard plan is unlimited and allows you to connect to
                                        all your customers at a predictable cost or £25 per customer
                                        per month.
                                    </Typography>
                                </CardContentContainer>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card
                                height="7rem"
                                boxShadow={
                                    selectedPlan === 'enterprise' ? '#6d5fa5 0px 0px 15px' : ''
                                }
                            >
                                <CardContentContainer
                                    onClick={() =>
                                        window.open(
                                            'mailto:hello@finecta.com?subject=Sales Enquiry'
                                        )
                                    }
                                >
                                    <PaymentCardTitleRow>
                                        <Typography tag="h2" fontWeight="regular">
                                            Enterprise
                                        </Typography>
                                        <Typography tag="h2">Contact Us</Typography>
                                    </PaymentCardTitleRow>
                                    <Typography tag="body1">
                                        Pay one price per month for unlimited use. Call us to
                                        discuss your needs in greater detail.
                                        <br />
                                        <Link
                                            onClick={() => window.open('mailto:hello@finecta.com')}
                                        >
                                            hello@finecta.com
                                        </Link>
                                    </Typography>
                                </CardContentContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </PaymentCardContainer>
            </PaymentSectionContainer>
            <FormControlLabel
                control={
                    <Checkbox
                        sx={{
                            color: themeColors.secondary,
                            '&.Mui-checked': {
                                color: themeColors.primary
                            },
                            '&:hover': {
                                backgroundColor: `${themeColors.primary}20`
                            }
                        }}
                        value={acceptedTerms}
                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                    />
                }
                label="I understand that the first 30 days are free. Any existing customer integrations after this time will be subject to billing as per the standard plan."
            />

            <PaymentSectionContainer>
                <PrimaryButton
                    disabled={!acceptedTerms || selectedPlan !== 'standard'}
                    clickHandler={onSubmit}
                >
                    Complete
                </PrimaryButton>
            </PaymentSectionContainer>
            <PaymentSectionContainer>
                <Typography tag="h4" fontWeight="regular" textColor="grey">
                    Any questions? Contact us on{' '}
                    <Link onClick={() => window.open('mailto:hello@finecta.com')}>
                        hello@finecta.com
                    </Link>
                    .
                </Typography>
            </PaymentSectionContainer>
        </PaymentContainer>
    );
};

export default Payment;
