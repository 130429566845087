
import { Accordion } from '@mui/material';
import { device, themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const CustomerViewContainer = styled.div`
  width: calc(100% - 64px);
  display: flex;
  padding: 0 32px;
  justify-content: flex-end;
  flex-direction: column;
  gap: 20px;
`;

export const CustomerViewTableContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 8px;
  background-color: transparent!important;
`;

export const CustomerViewFilterWrapper =  styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
`;

export const SelectLabel =  styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const AccordionStyled = styled(Accordion)`
  width: 100%;
  @media ${device.mobileOnly} {
    padding: 0;
  }
  
  &.MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none;
    
    ::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      width: 100%;
      padding: 0 8px;
      align-items: center;
      background-color: ${themeColors.primary};
      &.Mui-expanded {
        margin: 0;
      }
    }
  }
  
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.body};
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionSummary-root {
    &.Mui-disabled {
      opacity: 1;
    }
    &.MuiButtonBase-root{
      min-height: 32px;
    }
  }
  &.Mui-disabled {
    background-color: transparent !important;
  }
  .MuiAccordionDetails-root {
    @media ${device.mobileOnly} {
      padding: 16px 0;
    }
  }
`;

export const CustomerViewTableCustomerName = styled.p`
  margin: 0;
  ${typography.captionRegular};

  cursor: pointer;

    &:hover {
      ${typography.captionBold};
    }
`;

export const CustomerViewFilterContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const CustomerViewTableAction = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  ${typography.bodyBold}
`;

export const CustomerIconContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  justify-content: center;
`;


