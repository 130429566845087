import { device, themeColors } from 'assets/theme/style';
import { G2FSSContainer } from 'components/common/styled';
import { isMobile } from 'lib/helpers/mobile';
import styled from 'styled-components';
import { FormControlLabel } from '@mui/material';
import { fontSizes } from 'assets/theme/typography';

export const PaymentContainer = styled(G2FSSContainer)`
    max-width: 40rem;
    @media ${device.mobileOnly} {
        padding: 2rem;
    }
`;

export const PaymentSectionContainer = styled(G2FSSContainer)`
    margin-bottom: ${isMobile() ? '0' : '24px'};
`;

export const PaymentRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
`;

export const PaymentCardContainer = styled.div`
    display: flex;
    flex-direction: ${isMobile() ? 'column' : 'row'};
    justify-content: space-between;
    align-items: center;
    gap: ${isMobile() ? '16px' : '3rem'};
`;

export const CardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
    cursor: pointer;
`;

export const PaymentCardTitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const PaymentCardTitleCostContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;