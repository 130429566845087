/* eslint-disable react-hooks/exhaustive-deps */
import { PrimaryButton } from 'components/common/buttons';
import SecondaryButton from 'components/common/buttons/SecondaryButton';
import Typography from 'components/typography';
import { FC } from 'react';
import {
    ConnectorConfirmationViewButtonContainer,
    ConnectorConfirmationViewButtonText,
    ConnectorConfirmationViewButtonWrapper,
    ConnectorConfirmationViewContent,
    ConnectorConfirmationViewHeaderTitle,
    ConnectorConfirmationViewWrapper
} from './styled';

interface ConnectorConfirmationViewProps {
    closeHandler?: () => void;
    confirmHandler?: (customerId?: string) => void;
    title: string;
    description?: string;
    buttonText: string;
}

const ConnectorConfirmationView: FC<ConnectorConfirmationViewProps> = ({
    closeHandler,
    confirmHandler,
    buttonText,
    title,
    description
}) => {
    return (
        <ConnectorConfirmationViewWrapper>
            <ConnectorConfirmationViewHeaderTitle>{title}</ConnectorConfirmationViewHeaderTitle>
            <ConnectorConfirmationViewContent>
                <Typography tag="body1large">{description}</Typography>
            </ConnectorConfirmationViewContent>
            <ConnectorConfirmationViewButtonWrapper>
                <ConnectorConfirmationViewButtonContainer>
                    <SecondaryButton clickHandler={closeHandler}>
                        <ConnectorConfirmationViewButtonText>
                            Cancel
                        </ConnectorConfirmationViewButtonText>
                    </SecondaryButton>
                </ConnectorConfirmationViewButtonContainer>
                <ConnectorConfirmationViewButtonContainer>
                    <PrimaryButton clickHandler={confirmHandler}>
                        <ConnectorConfirmationViewButtonText>
                            {buttonText}
                        </ConnectorConfirmationViewButtonText>
                    </PrimaryButton>
                </ConnectorConfirmationViewButtonContainer>
            </ConnectorConfirmationViewButtonWrapper>
        </ConnectorConfirmationViewWrapper>
    );
};

export default ConnectorConfirmationView;
