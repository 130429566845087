import { FC, ReactNode } from 'react';
import { CardContainer } from './styled';

type CardProps = {
    minHeight?: string;
    maxHeight?: string;
    height?: string;
    width?: string;
    bgColor?: string;
    children: ReactNode;
    boxShadow?: string;
};

const Card: FC<CardProps> = ({
    minHeight,
    maxHeight,
    height,
    width,
    bgColor,
    children,
    boxShadow
}) => {
    return (
        <CardContainer
            minHeight={minHeight}
            maxHeight={maxHeight}
            height={height}
            width={width}
            bgColor={bgColor}
            boxShadow={boxShadow}
        >
            {children}
        </CardContainer>
    );
};

export default Card;
