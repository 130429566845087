import Context from 'context';
import { FC, useContext, useEffect, useState } from 'react';
import BasicDialog from 'components/dialog/BasicDialog';
import IntroDashboardView from 'views/IntroDashboardView';
import { useLocation } from 'react-router';

const IntroDialog: FC = () => {
    const [state] = useContext(Context);
    const location = useLocation();
    const [introDashboardDialogOpen, setIntroDashboardDialogOpen] = useState<boolean>(false);
    const stepIntegrations: boolean = state?.app?.stepIntegrations || false;
    const stepInvitations: boolean = state?.app?.stepInvitations || false;

    useEffect(() => {
        if (location?.pathname === '/' && (!stepIntegrations || !stepInvitations)) {
            setIntroDashboardDialogOpen(true);
        }
    }, [location?.pathname]);

    useEffect(() => {
        setIntroDashboardDialogOpen(!stepIntegrations || !stepInvitations);
    }, [stepIntegrations, stepInvitations]);

    const toggleCloseClickHandler: () => void = () => {
        setIntroDashboardDialogOpen(false);
    };

    const toggleClickHandler: () => void = () => {
        setIntroDashboardDialogOpen(!introDashboardDialogOpen);
    };

    const renderDialog: () => JSX.Element | null = () => {
        if (
            introDashboardDialogOpen &&
            stepIntegrations &&
            location?.pathname !== '/developer' &&
            location?.pathname !== '/integrations' &&
            location?.pathname !== '/payment'
        ) {
            return (
                <BasicDialog
                    open={introDashboardDialogOpen}
                    clickHandler={toggleClickHandler}
                    dialogContent={<IntroDashboardView closeHandler={toggleCloseClickHandler} />}
                />
            );
        } else if (
            introDashboardDialogOpen &&
            !stepIntegrations &&
            location?.pathname !== '/integrations' &&
            location?.pathname !== '/payment'
        ) {
            return (
                <BasicDialog
                    open={introDashboardDialogOpen}
                    clickHandler={toggleClickHandler}
                    dialogContent={<IntroDashboardView closeHandler={toggleCloseClickHandler} />}
                />
            );
        } else {
            return null;
        }
    };
    return renderDialog();
};

export default IntroDialog;
