import { device, themeColors, boxShadow } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const DashboardWrapper = styled.div`
    width: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    padding: 0 32px;
    gap: 50px;

    @media ${device.mobileOnly} {
        gap: 30px;
    }
`;

export const DashboardContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    gap: 5%;

    @media ${device.mobileOnly} {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        width: 100%;
    }
`;

export const DashboardBottomContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media ${device.mobileOnly} {
        flex-direction: column-reverse;
        justify-content: center;
        gap: 20px;
        width: 100%;
    }
`;

export const DashboardCardContainer = styled.div`
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 28px 16px;
    justify-content: space-between;
    background-color: ${themeColors.white};
    box-shadow: ${boxShadow};
    border-radius: 8px;
    width: calc(30% - 32px);
    @media ${device.mobileOnly} {
        padding: 16px;
        width: calc(100% - 32px);
    }
`;

export const DashboardCardsContainer = styled.div`
    display: flex;
    width: 65%;
    justify-content: space-between;
    gap: 5%;
    @media ${device.desktopM} {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 50px;
        width: 100%;
    }
`;

export const DashboardCardTitle = styled.div`
    position: absolute;
    top: -14px;
    left: 15px;
    padding: 5px;
    ${typography.cardTitle};
`;

export const DashboardDocumentationCard = styled.div`
    display: flex;
    padding: 26px 32px;
    border-radius: 8px;
    align-items: center;
    gap: 20px;
    box-shadow: ${boxShadow};
    background-color: ${themeColors.green};
    cursor: pointer;
    @media ${device.mobileOnly} {
        padding: 16px;
    }
    svg {
      height: 70%;
    }
`;

export const DashboardCardsText = styled.p`
    color: #ffffff;
    line-height: ${fontSizes.xLarge};
    font-size: ${fontSizes.large};
`;

export const DashboardApiKeysCard = styled.div`
    display: flex;
    padding: 26px 32px;
    border-radius: 8px;
    align-items: center;
    gap: 20px;
    box-shadow: ${boxShadow};
    background-color: ${themeColors.purple};
    cursor: pointer;
    @media ${device.mobileOnly} {
        padding: 16px;
    }
    svg {
      height: 70%;
    }
`;

export const DashboardCardContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

export const DashboardCustomerCardContainer = styled.div`
    position: relative;
    display: flex;
    padding: 32px;
    flex-direction: column;
    background-color: ${themeColors.white};
    border-radius: 8px;
    width: calc(65% - 64px);
    gap: 10px;
    box-shadow: ${boxShadow};
    @media ${device.mobileOnly} {
        padding: 16px;
        width: calc(100% - 32px);
    }
`;

export const DashboardInvitationsCardContainer = styled.div`
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 28px 16px;
    justify-content: space-between;
    background-color: ${themeColors.white};
    box-shadow: ${boxShadow};
    border-radius: 8px;
    width: calc(30% - 32px);
    @media ${device.mobileOnly} {
        padding: 28px 16px;
        width: calc(100% - 32px);
    }
`;

export const DashboardCardContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 4px;
    @media ${device.mobileOnly} {
        flex-direction: column;
    }
`;

export const CheckIconContainer = styled.div`
    .MuiSvgIcon-root {
        width: 40px;
        height: 40px;
    }
`;

export const DashboardCardRowText = styled.p`
    margin: 0;
`;

export const DashboardCardTextBold = styled.span`
    ${typography.bodyBold};
`;

export const DashboardCardButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
`;

export const DashboardCardButtonText = styled.p`
    margin: 0;
    ${typography.bodySemiBold};
`;

export const BarContainer = styled.div`
    display: flex;
    height: 30vh;
`;

export const DoughnutContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 20vh;
`;

export const DashboardInvitationsOptionSent = styled.div`
    display: flex;
    position: absolute;
    top: 42%;
    left: 39%;
    font-size: ${fontSizes.small};
    text-align: center;
    font-weight: ${fontWeights.bold};
`;

export const DashboardInvitationsContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
`;

export const DashboardInvitationsOptionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const DashboardInvitationsOptionIcon = styled.div`
    display: flex;
    height: 10px;
    width: 20px;
    background-color: black;
`;

export const DashboardInvitationsOptionText = styled.p`
    margin: 0;
    ${typography.captionRegular};
`;
