import { device, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import DatePicker from 'react-datepicker';
import { Accordion } from '@mui/material';
import styled from 'styled-components';

export const InvoicesViewContainer = styled.div`
  width: calc(100% - 64px);
  display: flex;
  padding: 0 32px;
  justify-content: flex-end;
  flex-direction: column;
  gap: 20px;
`;

export const InvoicesViewTableContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: transparent !important;
`;

export const InvoicesViewTableAction = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  ${typography.bodyBold};
  justify-content: space-between;
  align-items: center;
`;

export const InvoicesViewTableActionButtonContainer = styled.div`
  width: fit-content;
  align-items: center;
  display: flex;
`;

export const InvoicesViewAnalyticsButtonContainer = styled.div`
  min-width: 170px;
`;

export const InvoicesViewFilterContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const InvoicesViewPageTitle = styled.h1`
  margin: 0;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
`;

export const AccordionStyled = styled(Accordion)`
  width: 100%;
  @media ${device.mobileOnly} {
    padding: 0;
  }

  &.MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none;

    ::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      width: 100%;
      padding: 0 8px;
      align-items: center;
      background-color: ${themeColors.primary};
      &.Mui-expanded {
        margin: 0;
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: ${themeColors.body};
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(360deg);
    }
  }
  .MuiAccordionSummary-root {
    &.Mui-disabled {
      opacity: 1;
    }
    &.MuiButtonBase-root {
      min-height: 32px;
    }
  }
  &.Mui-disabled {
    background-color: transparent !important;
  }
  .MuiAccordionDetails-root {
    @media ${device.mobileOnly} {
      padding: 16px 0;
    }
  }
`;

export const InvoicesViewFilterRow = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

export const InvoiceViewFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
`;

export const InvoicesViewFilterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 38px;
  gap: 10px;
`;

export const InvoicesViewFilterButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const InvoicesViewFilterButtonText = styled.p`
  margin: 0;
  ${typography.bodySemiBold};
`;

export const InvoicesViewFilterSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -13px;
`;

export const DatePickerStyled = styled(DatePicker)`
  display: none;
`;

export const TextInputWrapper = styled.div``;

export const InvoicesLoaderInPageContainer = styled.div``;
