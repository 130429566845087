/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import InputAdornment from '@mui/material/InputAdornment';
import { apiConfig, get } from 'api/api';
import { themeColors } from 'assets/theme/style';
import DataGrid from 'components/DataGrid';
import LoaderFullPage from 'components/LoaderFullPage';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { PrimaryButton } from 'components/common/buttons';
import SecondaryButton from 'components/common/buttons/SecondaryButton';
import Select from 'components/forms/inputs/Select';
import TextInput from 'components/forms/inputs/TextInput';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import { authResult } from 'lib/authConfig';
import {
    AccordionStyled,
    DatePickerStyled,
    InvoiceViewFilterWrapper,
    InvoicesLoaderInPageContainer,
    InvoicesViewAnalyticsButtonContainer,
    InvoicesViewContainer,
    InvoicesViewFilterButtonContainer,
    InvoicesViewFilterButtonTextWrapper,
    InvoicesViewFilterContainer,
    InvoicesViewFilterSegment,
    InvoicesViewPageTitle,
    InvoicesViewTableAction,
    InvoicesViewTableActionButtonContainer,
    InvoicesViewTableContainer,
    TextInputWrapper
} from './styled';
import { DateTime } from 'luxon';
import OutlinedInput from 'components/forms/inputs/OutlinedInput';
import ConnectionBanner from 'components/ConnectionBanner';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import { IInvoiceDetail } from 'utils/interfaces/invoice.interface';

export interface SelectMenuItem {
    name: string;
    value: any;
}

const fieldDictionary = {
    buyer: 'buyer/name',
    supplier: 'supplier/name',
    source: 'source',
    invoiceNumber: 'invoiceNumber',
    issueDate: 'issueDate',
    dueDate: 'dueDate',
    status: 'status',
    balanceDue: 'balanceDue',
    amount: 'amount',
    type: 'type'
};

const InvoicesView: FC = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const params = useParams();
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [loading, setLoading] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [filterLoading, setFilterLoading] = useState<boolean>(false);
    const [data, setData]: [DataGridRowItem[], Dispatch<SetStateAction<DataGridRowItem[]>>] =
        useState<DataGridRowItem[]>([]);
    const [fromOpen, setFromOpen] = useState<boolean>(false);
    const [toOpen, setToOpen] = useState<boolean>(false);
    const [orderBy, setOrderBy] = useState<string>('updatedDate desc');
    const [orderDirection, setOrderDirection] = useState<string>('desc');
    const [invoiceType, setInvoiceType] = useState<string>('');
    const [searchValues, setSearchValues] = useState<any>({
        buyer: '',
        type: '',
        invoiceNumber: '',
        currency: '',
        from: '',
        to: ''
    });
    const [page, setPage] = useState<number>(0);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    const customerTableHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem('Invoice Id', 'invoiceUid', {
            display: false
        }),
        new DataGridHeaderItem('Currency', 'currency', {
            display: false
        }),
        new DataGridHeaderItem('From / To', 'buyer', new DataGridHeaderOptions(false, true, true)),
        new DataGridHeaderItem('Source', 'source', new DataGridHeaderOptions(false, true, true)),
        new DataGridHeaderItem(
            'Invoice Number',
            'invoiceNumber',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem(
            'Issue Date',
            'issueDate',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem('Due Date', 'dueDate', new DataGridHeaderOptions(false, true, true)),
        new DataGridHeaderItem('Status', 'status', new DataGridHeaderOptions(false, true, true)),
        new DataGridHeaderItem('Amount', 'amount', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any, tableMeta: any) => (
                <p>
                    {CurrencySymbolsLookUp[tableMeta.rowData[1]]}
                    {value}
                </p>
            )
        }),
        new DataGridHeaderItem('Type', 'type', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any) => <p>{value === 'AP' ? 'BILL' : 'INVOICE'}</p>
        })
    ];

    const customerId: string = params?.customerId || '';
    const currencyData: SelectMenuItem[] = [
        { value: 'AUD', name: 'AUD' },
        { value: 'EUR', name: 'EUR' },
        { value: 'GBP', name: 'GBP' },
        { value: 'USD', name: 'USD' }
    ];
    const typeData: SelectMenuItem[] = [
        { value: 'AR', name: 'Invoice' },
        { value: 'AP', name: 'Bill' }
    ];

    const formattedFromDate: () => string = () =>
        !searchValues.from ? '' : formatDateTime(searchValues.from.toISOString(), 'MM/dd/yyyy');

    const formattedToDate: () => string = () =>
        !searchValues.to ? '' : formatDateTime(searchValues.to.toISOString(), 'MM/dd/yyyy');

    const updateSearchValue: (key: string, value: string | boolean | Date) => void = (key, value) =>
        setSearchValues({ ...searchValues, [key]: value });

    const resetSearchValue: () => void = () => {
        setSearchValues({
            buyer: '',
            type: '',
            invoiceNumber: '',
            currency: '',
            from: '',
            to: ''
        });
        setFilterLoading(true);
        getInvoices('reset');
        setOrderBy('updatedDate desc');
        setInvoiceType('');
        setOrderDirection('desc');
        setPage(0);
    };

    const clickSearchHandle: () => void = () => {
        setFilterLoading(true);
        getInvoices('search');
        setInvoiceType(searchValues.type);
        setPage(0);
    };

    const searchHandleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void = (event) => {
        if (event.key === 'Enter') {
            clickSearchHandle();
        }
    };

    useEffect(() => {
        setLoading(true);
        setPage(0);
        return () => {
            setPage(0);
        };
    }, []);

    useEffect(() => {
        if (page > -1) getInvoices();
    }, [orderBy, page, rowsPerPage]);

    const getInvoices: (status?: string) => any = async (status = '') => {
        setTableLoading(true);
        const queryExpand: string = `?$expand=buyer,supplier`;
        const queryPage: string = `&$count=true&$top=${rowsPerPage}&$orderby=${orderBy}&$skip=${
            page === -1 ? 0 : rowsPerPage * page
        }`;

        let filter: string = `&$filter=customerUid eq '${customerId}'`;

        if (status !== 'reset') {
            if (searchValues.invoiceNumber) {
                filter += ` and contains(invoiceNumber,'${searchValues.invoiceNumber}')`;
            }
            if (searchValues.type) {
                filter += ` and type eq '${searchValues.type}'`;
            }
            if (searchValues.currency) {
                filter += ` and currency eq '${searchValues.currency}'`;
            }
            if (searchValues.from && searchValues.to) {
                filter += ` and (issueDate ge ${DateTime.fromJSDate(searchValues.from).toISO({
                    includeOffset: false
                })}Z) and (issueDate le ${DateTime.fromJSDate(searchValues.to)
                    .plus({ days: 1 })
                    .toISO({ includeOffset: false })}Z)`;
            }
            if (searchValues.from && !searchValues.to) {
                filter += ` and (issueDate ge ${DateTime.fromJSDate(searchValues.from).toISO({
                    includeOffset: false
                })}Z)`;
            }
            if (searchValues.buyer) {
                filter += ` and (contains(buyer/name,'${searchValues.buyer}') or contains(supplier/name,'${searchValues.buyer}'))`;
            }
        }

        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        get(apiConfig.invoices(`${queryExpand}${queryPage}${filter}`), token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                const mappedData = responseData.value.map((d: IInvoiceDetail) => ({
                    buyer: d.type === 'AR' ? d.buyer?.name || '-' : d.supplier?.name || '-',
                    supplier: d.supplier?.name || '',
                    source: d.source || '',
                    invoiceUid: d.invoiceUid || '',
                    invoiceNumber: d.invoiceNumber || '',
                    issueDate: formatDateTime(d.issueDate || '', 'dd MMM yyyy'),
                    dueDate: formatDateTime(d.dueDate || '', 'dd MMM yyyy'),
                    status: d.status || '',
                    amount: formatNumber(d.amount || 0, 2),
                    balanceDue: formatNumber(d.balanceDue || 0, 2),
                    currency: d.currency || '',
                    type: d.type || ''
                }));
                setTotalRecordCount(responseData['@odata.count']);
                setData(mappedData);
                setLoading(false);
                setFilterLoading(false);
                setTableLoading(false);
            });
    };

    const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) =>
        setPage(tableState.page);

    const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) =>
        setRowsPerPage(numberOfRows);

    const columnSortChangeClickHandler = (col: any) => {
        const odataField: string | undefined = fieldDictionary[col];
        const sortable = Boolean(odataField);
        if (!sortable) return;
        const direction = orderDirection === 'asc' ? 'desc' : 'asc';
        const field: string =
            col === 'buyer'
                ? `${fieldDictionary['buyer']} ${direction}, ${fieldDictionary['supplier']} ${direction}`
                : `${fieldDictionary[col]} ${direction}`;

        setOrderDirection(direction);
        setOrderBy(field);
    };

    const rowClickHandler: (row: any) => void = (row) => {
        navigate(`/invoice/${customerId}/${row[0]}`);
    };

    const getTypeName: (value: string) => string = (value) => {
        switch (value) {
            case 'AR':
                return 'Invoice';
            default:
                return 'Bill';
        }
    };

    return (
        <InvoicesViewContainer>
            {loading ? (
                <LoaderFullPage />
            ) : (
                <>
                    <ConnectionBanner customerId={customerId} />
                    <InvoicesViewFilterContainer>
                        <AccordionStyled disableGutters defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                FILTER OPTIONS
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingBottom: 0 }}>
                                <InvoiceViewFilterWrapper>
                                    <TextInputWrapper>
                                        <TextInput
                                            placeholder="Buyer"
                                            defaultValue={searchValues.buyer}
                                            handleKeyDown={(event: any) =>
                                                searchHandleKeyDown(event)
                                            }
                                            changeHandler={(event) =>
                                                updateSearchValue('buyer', event.target.value)
                                            }
                                        />
                                    </TextInputWrapper>
                                    <TextInputWrapper>
                                        <TextInput
                                            placeholder="Invoice Number"
                                            defaultValue={searchValues.invoiceNumber}
                                            handleKeyDown={(event: any) =>
                                                searchHandleKeyDown(event)
                                            }
                                            changeHandler={(event) =>
                                                updateSearchValue(
                                                    'invoiceNumber',
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </TextInputWrapper>
                                    <Select
                                        background={themeColors.white}
                                        menuItems={typeData}
                                        name={getTypeName(searchValues.type)}
                                        value={searchValues.type}
                                        placeholder="Type"
                                        changeHandler={(event: any) =>
                                            updateSearchValue('type', event.target.value)
                                        }
                                    />
                                    <Select
                                        background={themeColors.white}
                                        menuItems={currencyData}
                                        name={searchValues.currency}
                                        value={searchValues.currency}
                                        placeholder="Currency"
                                        changeHandler={(event: any) =>
                                            updateSearchValue('currency', event.target.value)
                                        }
                                    />
                                    <InvoicesViewFilterSegment>
                                        <label>
                                            <OutlinedInput
                                                defaultValue={formattedFromDate()}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <CalendarMonthIcon />
                                                    </InputAdornment>
                                                }
                                                placeholder="From issue date"
                                                onClick={() => setFromOpen(!fromOpen)}
                                            />
                                        </label>
                                        <DatePickerStyled
                                            open={fromOpen}
                                            selected={searchValues.from}
                                            onChange={(date: Date) =>
                                                updateSearchValue('from', date)
                                            }
                                            onClickOutside={() => setFromOpen(false)}
                                        />
                                    </InvoicesViewFilterSegment>
                                    <InvoicesViewFilterSegment>
                                        <label>
                                            <OutlinedInput
                                                defaultValue={formattedToDate()}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <CalendarMonthIcon />
                                                    </InputAdornment>
                                                }
                                                placeholder="To issue date"
                                                onClick={() => setToOpen(!toOpen)}
                                            />
                                        </label>
                                        <DatePickerStyled
                                            open={toOpen}
                                            selected={searchValues.to}
                                            onChange={(date: Date) => updateSearchValue('to', date)}
                                            onClickOutside={() => setToOpen(false)}
                                        />
                                    </InvoicesViewFilterSegment>

                                    <InvoicesViewFilterButtonContainer>
                                        <PrimaryButton
                                            clickHandler={clickSearchHandle}
                                            padding="none"
                                            disabled={
                                                searchValues.type === '' &&
                                                searchValues.buyer === '' &&
                                                searchValues.invoiceNumber === '' &&
                                                searchValues.currency === '' &&
                                                searchValues.from === ''
                                            }
                                            height="36px"
                                        >
                                            <InvoicesViewFilterButtonTextWrapper>
                                                <SearchIcon />
                                            </InvoicesViewFilterButtonTextWrapper>
                                        </PrimaryButton>
                                    </InvoicesViewFilterButtonContainer>
                                </InvoiceViewFilterWrapper>
                            </AccordionDetails>
                        </AccordionStyled>
                    </InvoicesViewFilterContainer>
                    {filterLoading ? (
                        <InvoicesLoaderInPageContainer>
                            <LoaderInPage height="15vh" />
                        </InvoicesLoaderInPageContainer>
                    ) : (
                        <>
                            <InvoicesViewTableAction>
                                {totalRecordCount} {invoiceType === 'AR' && 'invoices'}
                                {invoiceType === 'AP' && 'bills'}
                                {invoiceType === '' && 'invoices / bills'}
                                <InvoicesViewTableActionButtonContainer>
                                    <InvoicesViewAnalyticsButtonContainer>
                                        <SecondaryButton
                                            width="fit-content"
                                            text="Go to Analytics"
                                            clickHandler={() =>
                                                navigate(`/analytics/${customerId}`)
                                            }
                                        />
                                    </InvoicesViewAnalyticsButtonContainer>
                                    <SecondaryButton
                                        padding="none"
                                        backgroundColor="transparent"
                                        textColor={themeColors.body}
                                        borderColor="none"
                                        clickHandler={resetSearchValue}
                                    >
                                        <InvoicesViewFilterButtonTextWrapper>
                                            <RestartAltIcon color="inherit" /> Reset
                                        </InvoicesViewFilterButtonTextWrapper>
                                    </SecondaryButton>
                                </InvoicesViewTableActionButtonContainer>
                            </InvoicesViewTableAction>

                            <InvoicesViewTableContainer>
                                <DataGrid
                                    loading={tableLoading}
                                    headers={customerTableHeaders}
                                    data={data}
                                    ariaLabel="Customer table"
                                    ariaCaption="A table that shows customers."
                                    rowsPerPage={rowsPerPage}
                                    totalRows={totalRecordCount}
                                    rowClickHandler={rowClickHandler}
                                    tableChangeHandler={tableChangeHandler}
                                    columnSortChangeClickHandler={columnSortChangeClickHandler}
                                    changeRowsPerPageHandler={rowsPerPageHandler}
                                    fixedHeader
                                    serverSide
                                    page={page === -1 ? 0 : page}
                                    tableMobileWidth="50%"
                                    showFooter={totalRecordCount > 20 ? true : false}
                                />
                            </InvoicesViewTableContainer>
                        </>
                    )}
                </>
            )}
        </InvoicesViewContainer>
    );
};

export default InvoicesView;
