import { Paper, Table, TableBody } from '@mui/material';
import { device, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import MUIDataTable from 'mui-datatables';
import styled from 'styled-components';

interface TableMUIStyledProps {
  rowClickable?: boolean;
  tableMobileWidth?: string;
  lastChildDiv?: boolean;
  showFooter?: boolean;
}

export const TableStyled = styled(Table)`
  min-width: 600px;
  @media ${device.desktopM} {
    min-width: auto;
  }
`;

export const TableMUIStyled = styled(MUIDataTable)<TableMUIStyledProps>`
  min-width: 600px;
  @media ${device.desktopM} {
    min-width: auto;
  }
  .MuiToolbar-gutters {
    display: none;
  }
  .MuiTablePagination-toolbar{
    display: flex;
  }

  .MUIDataTable-responsiveBase {
    overflow-x: hidden;
  }
  th {
    ${typography.tableHeader};
    background-color: ${themeColors.primary};
    border-right: 2px solid ${themeColors.white};
    border-bottom: none;
    text-transform: uppercase;
    vertical-align: middle;
    button {
      width: 100%;
      justify-content: flex-start;
      div {
        align-items: center;
      }
    }
  
    &:last-child {
      border-right: none;
    }
  }
  tbody {
    tr {
      cursor: ${({ rowClickable }) => rowClickable && 'pointer'};
      :hover {
        background-color: ${themeColors.white} !important;
      }
      td {
        padding: 8px 16px !important;
        @media ${device.mobileOnly} {
          > div:first-child {
            font-weight: ${fontWeights.bold};
          }
          div {
            font-size: ${fontSizes.small};
            width: ${({ tableMobileWidth }) => tableMobileWidth ? tableMobileWidth : '100%'};
          }
        }
      }
      > td:last-child {
        > div:first-child {
          display: ${({ lastChildDiv }) => lastChildDiv && 'none'};
          }
      }
        
    }
  }
  [class*='MUIDataTableResize-resizer'] {
    border: 1px solid transparent !important;
    cursor: ew-resize;
  }
  .MuiTableBody-root{
    .MuiTableRow-root {
      @media ${device.mobileOnly} {
        border-top: solid 2px ${themeColors.body};
        border-bottom: solid 2px ${themeColors.body};
      }
    }
  }
  .MuiTableRow-root {
    vertical-align: baseline;
  }

  .MuiTableCell-head {
    padding: 0 !important;
  }

  .MuiTableCell-body {
    padding: 8px !important;
    a {
      color: ${themeColors.accent};
      font-weight: ${fontWeights.bold};
      text-decoration: none;
      cursor: pointer;
      &:hover {
          color: ${themeColors.primary};
      }
      &:visited {
          color: ${themeColors.accent};
      }
    }
  }

  .MuiButton-root {
    padding: 0 !important;
    margin: 0 !important;
    min-height: 32px !important;

    > div:first-child {
      display: flex !important;
      width: 100% !important;
      justify-content: space-between !important;
    }
  }

  .MuiCheckbox-root {
    color: ${themeColors.darkGrey}!important;
    background-color: transparent;
    border-radius: 4px;
  }
  .MuiTableCell-paddingCheckbox {
    > td {
      vertical-align: top;
    }
  }
  .MuiTableCell-footer {
    border-bottom: none;
    padding: 0;

    .MuiToolbar-root{
      padding: 0;
    }
    @media ${device.mobileOnly} {
      .MuiTablePagination-toolbar {
        display: none;
      }
    }
  }
  .MuiTableFooter-root {
    display: ${({ showFooter }) => !showFooter && 'none'};
  }
`;

export const TableMobileSubTitle = styled.h4`
  margin: 0;
  padding-right: 8px;
  border-right: 4px solid ${themeColors.lightGrey};
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled(Paper)`
  width: 100%;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .MuiPaper-root {
    background-color: transparent !important;
  }
`;

export const TableBodyStyled = styled(TableBody)`
  tr {
    :hover {
      background-color: ${themeColors.darkGrey};
    }
  }
`;

export const TableExportButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColors.green};
  background-color: transparent;
  height: 24px;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid ${themeColors.green};
  align-self: flex-end;
  cursor: pointer;
`;

export const TableExportText = styled.h5`
  margin: 0;
`;

export const TableMobileTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

export const TableHeaderStyled = styled.p`
  ${typography.tableHeader};
  padding: 0 8px;
`;

export const ExportDataButtonText = styled.h5``;