import { FC, MouseEvent, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { authResult } from 'lib/authConfig';
import { useNavigate } from 'react-router';
import Avatar from '@mui/material/Avatar';
import { ProfileContainer } from './styled';
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { apiConfig, post } from 'api/api';

const Profile: FC = () => {
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick: (event: MouseEvent<HTMLElement>) => void = (event) =>
        setAnchorEl(event.currentTarget);

    const handleClose: () => void = () => {
        setAnchorEl(null);
    };

    const logout: () => void = () => {
        instance.logoutRedirect();
        handleClose();
    };

    const changePassword: () => void = () => {
        navigate('/reset-password');
        handleClose();
    };

    const manageSubscription: () => void = async () => {
        const apiParameters = {
            base: `https://${window.spaSettings.instanceName}.onmicrosoft.com`
        };

        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);

        const postModel = {
            returnUrl: window.location.origin
        };

        const res = await post(apiConfig.billingPortalSession(), postModel, token);
        if (!res.ok) return;
        const json = await res.json();
        window.location.href = json.url;
        handleClose();
    };

    return (
        <ProfileContainer>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }}>
                        {instance.getActiveAccount()?.name?.charAt(0).toUpperCase()}
                    </Avatar>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={changePassword}>
                    <ListItemIcon>
                        <ManageAccountsIcon fontSize="small" />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem onClick={manageSubscription}>
                    <ListItemIcon>
                        <ReceiptIcon fontSize="small" />
                    </ListItemIcon>
                    Manage Subscription
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() =>
                        window.open(
                            'https://finecta.stoplight.io/docs/trade-api/7akcfd0d297tn-help-and-support',
                            '_BLANK'
                        )
                    }
                >
                    <ListItemIcon>
                        <HelpOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    Support
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </ProfileContainer>
    );
};

export default Profile;
