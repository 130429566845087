import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import G2Flogo from 'assets/logos/g2f_logo.png';
import { themeColors } from 'assets/theme/style';
import { isMobile } from 'lib/helpers/mobile';
import { FC } from 'react';
import { FullScreenDialogAppBarLogo } from './styled';
import G2FLogo from 'assets/icons/G2FLogo';

export interface FullScreenDialogAppBarProps {
    clickHandler: () => void;
}

const FullScreenDialogAppBar: FC<FullScreenDialogAppBarProps> = ({ clickHandler }) => (
    <AppBar
        style={{
            position: 'relative',
            backgroundColor: themeColors.white,
            height: '84px',
            display: 'flex',
            justifyContent: 'center',
            boxShadow: 'none',
            borderBottom: `1px solid ${themeColors.darkGrey}`
        }}
    >
        <Toolbar
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: `0 ${isMobile() ? 1 : 5}rem`
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                onClick={clickHandler}
                aria-label="close"
                style={{ cursor: 'pointer' }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <G2FLogo />
                </div>
            </IconButton>
            <CloseIcon
                onClick={clickHandler}
                style={{ color: themeColors.body, cursor: 'pointer' }}
            />
        </Toolbar>
    </AppBar>
);

export default FullScreenDialogAppBar;
