import loader from 'assets/lottie-animations/finecta_loader.json';
import Lottie from 'lottie-react';
import { FC } from 'react';

export interface LoaderInPageProps {
  height?: string;
}

const LoaderInPage: FC<LoaderInPageProps> = ({height= "5vh"}) => (
    <Lottie
        style={{
            height: height
        }}
        loop
        animationData={loader}
        data-testid="loader-in-page"
    />
);

export default LoaderInPage;
