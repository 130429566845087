/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useRef } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { authResult } from 'lib/authConfig';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import finectaAILogo from 'assets/logos/finecta-ai.png';
import MinimizeIcon from '@mui/icons-material/Minimize';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import Footer from 'components/footer';
import {
    ChatViewContainer,
    ChatViewFooter,
    ChatViewHeader,
    ChatViewIconWrapper,
    ChatViewInput,
    ChatViewLogo,
    ChatViewQuestionContainer,
    ChatViewQuestionWrapper,
    ChatViewMessage,
    ChatViewMessageHeader,
    ChatViewMessageLoading,
    ChatViewMessageWrapper,
    ChatViewWarning,
    ChatViewWrapper,
    FullscreenIconWrapper,
    MinimizeIconWrapper,
    SearchIconContainer,
    ChatViewMessageContainer,
    ChatViewContentWrapper,
    ChatViewButtonText,
    ChatViewButtonContainer,
    ChatViewButtonWrapper,
    ChatViewHeaderWrapper,
    ChatViewMessageTime
} from './styled';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import { fontSizes } from 'assets/theme/typography';
import { DateTime } from 'luxon';
import { apiConfig, post } from 'api/api';
export interface PreviousChatsData {
    role: string;
    content: string;
    time: any;
}

const ChatView: FC = () => {
    const chatMessageBottomRef = useRef<HTMLDivElement | null>(null);
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [value, setValue] = useState<string>('');
    const [userMessage, setUserMessage] = useState<string>('');
    const [previousChats, setPreviousChats] = useState<PreviousChatsData[]>([]);
    const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
    const [isChatFullView, setIsChatFullView] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const clearChat: () => void = () => {
        setUserMessage('');
        setPreviousChats([]);
        setValue('');
    };

    const handleSend: () => void = async () => {
        setUserMessage(value);
        setLoading(true);
        const token = await authResult(
            instance as PublicClientApplication,
            isAuthenticated,
            inProgress
        );

        const data = {
            question: value
        };

        post(apiConfig.postFinectAi(), data, token)
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setPreviousChats((prevChats) => [
                    ...prevChats,

                    {
                        role: 'assistant',
                        content: responseData?.output?.output || '',
                        time: DateTime.now().toFormat('HH:mm')
                    }
                ]);
                setValue('');
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (chatMessageBottomRef.current) {
            chatMessageBottomRef.current.scrollTop = chatMessageBottomRef.current.scrollHeight;
        }
    }, [previousChats]);

    useEffect(() => {
        clearChat();
    }, []);

    useEffect(() => {
        if (userMessage.length > 0) {
            setPreviousChats((prevChats) => [
                ...prevChats,
                {
                    role: 'user',
                    content: userMessage,
                    time: DateTime.now().toFormat('HH:mm')
                }
            ]);
            setValue('');
        }
    }, [userMessage]);

    const searchHandleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void = (event) => {
        if (event.key === 'Enter') {
            handleSend();
        }
    };

    const renderChatView: () => JSX.Element = () => {
        return (
            <ChatViewWrapper fullView={isChatFullView}>
                {isChatVisible && (
                    <ChatViewContainer fullView={isChatFullView}>
                        <ChatViewHeaderWrapper>
                            <ChatViewHeader>
                                <ChatViewLogo src={finectaAILogo} />
                                {!isChatFullView ? (
                                    <FullscreenIconWrapper title="Expand View">
                                        <OpenInFullIcon
                                            onClick={() => setIsChatFullView(!isChatFullView)}
                                            sx={{ fontSize: '24px' }}
                                        />
                                    </FullscreenIconWrapper>
                                ) : (
                                    <MinimizeIconWrapper title="Exit Fullview">
                                        <MinimizeIcon
                                            onClick={() => setIsChatFullView(!isChatFullView)}
                                            sx={{ fontSize: '32px' }}
                                        />
                                    </MinimizeIconWrapper>
                                )}
                            </ChatViewHeader>
                            {previousChats.length > 0 && (
                                <ChatViewButtonWrapper>
                                    <ChatViewButtonContainer>
                                        <PrimaryButton
                                            clickHandler={() => clearChat()}
                                            backgroundColor={themeColors.white}
                                            textColor={themeColors.primaryButton}
                                            hoverColor={themeColors.primary}
                                        >
                                            <DeleteForeverIcon sx={{ fontSize: fontSizes.large }} />
                                            <ChatViewButtonText>Clear chat</ChatViewButtonText>
                                        </PrimaryButton>
                                    </ChatViewButtonContainer>
                                </ChatViewButtonWrapper>
                            )}
                        </ChatViewHeaderWrapper>
                        <ChatViewContentWrapper>
                            <ChatViewMessageWrapper
                                fullView={isChatFullView}
                                ref={chatMessageBottomRef}
                            >
                                {previousChats.map((chat, index) => (
                                    <ChatViewMessageContainer key={`chat-${index}`}>
                                        <ChatViewMessageHeader>
                                            <PersonRoundedIcon
                                                sx={{
                                                    width: 25,
                                                    height: 25,
                                                    padding: '3px',
                                                    borderRadius: 50,
                                                    backgroundColor:
                                                        chat.role === 'user'
                                                            ? `${themeColors.darkGrey}`
                                                            : `${themeColors.primary}`,
                                                    color:
                                                        chat.role === 'user'
                                                            ? `${themeColors.white}`
                                                            : `${themeColors.dark}`
                                                }}
                                            />
                                            {chat.role === 'user' ? (
                                                instance.getActiveAccount()?.name
                                            ) : (
                                                <ChatViewLogo
                                                    src={finectaAILogo}
                                                    style={{ height: '12px' }}
                                                />
                                            )}
                                            <ChatViewMessageTime>
                                                {chat.time || ''}
                                            </ChatViewMessageTime>
                                        </ChatViewMessageHeader>
                                        <ChatViewMessage>{chat.content || ''} </ChatViewMessage>
                                    </ChatViewMessageContainer>
                                ))}
                                {loading && (
                                    <ChatViewMessageLoading>
                                        <svg
                                            version="1.1"
                                            id="L4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 100 100"
                                            enableBackground="new 0 0 0 0"
                                            xmlSpace="preserve"
                                        >
                                            <circle fill="#000" stroke="none" cx="6" cy="50" r="6">
                                                <animate
                                                    attributeName="opacity"
                                                    dur="1s"
                                                    values="0;1;0"
                                                    repeatCount="indefinite"
                                                    begin="0.1"
                                                />
                                            </circle>
                                            <circle fill="#000" stroke="none" cx="26" cy="50" r="6">
                                                <animate
                                                    attributeName="opacity"
                                                    dur="1s"
                                                    values="0;1;0"
                                                    repeatCount="indefinite"
                                                    begin="0.2"
                                                />
                                            </circle>
                                            <circle fill="#000" stroke="none" cx="46" cy="50" r="6">
                                                <animate
                                                    attributeName="opacity"
                                                    dur="1s"
                                                    values="0;1;0"
                                                    repeatCount="indefinite"
                                                    begin="0.3"
                                                />
                                            </circle>
                                        </svg>
                                    </ChatViewMessageLoading>
                                )}
                            </ChatViewMessageWrapper>
                            <ChatViewQuestionContainer>
                                <ChatViewQuestionWrapper>
                                    <ChatViewInput
                                        multiline
                                        maxRows="200"
                                        disabled={loading}
                                        placeholder="Type your message..."
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                        inputProps={{ 'aria-label': 'Type your message' }}
                                        onKeyDown={(event: any) => searchHandleKeyDown(event)}
                                    />

                                    <SearchIconContainer
                                        color={
                                            value.length > 0
                                                ? `${themeColors.dark}`
                                                : `${themeColors.darkGrey}`
                                        }
                                        disabled={value.length > 0}
                                    >
                                        <ArrowUpwardIcon onClick={handleSend} />
                                    </SearchIconContainer>
                                </ChatViewQuestionWrapper>
                                <ChatViewWarning>
                                    Beta Test Version Only! - Finectai can make mistakes. Consider checking important
                                    information.
                                </ChatViewWarning>
                            </ChatViewQuestionContainer>
                        </ChatViewContentWrapper>
                    </ChatViewContainer>
                )}
                {isChatFullView && isChatVisible ? (
                    <ChatViewFooter>
                        <Footer />
                    </ChatViewFooter>
                ) : (
                    <ChatViewIconWrapper onClick={() => setIsChatVisible(!isChatVisible)}>
                        {!isChatVisible ? <ChatIcon /> : <ExpandMoreIcon />}
                    </ChatViewIconWrapper>
                )}
            </ChatViewWrapper>
        );
    };

    return isChatFullView ? (
        <Dialog fullScreen open={isChatFullView}>
            {renderChatView()}
        </Dialog>
    ) : (
        renderChatView()
    );
};

export default ChatView;
