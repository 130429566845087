/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import {
    AnalyticsChartSummaryRiskNumber,
    AnalyticsChartSummaryTitle,
    AnalyticsChartSummaryContainer,
    AnalyticsChartSummaryWrapper,
    AnalyticsChartSummaryInfoContainer,
    AnalyticsChartSummaryRiskStatus,
    AnalyticsChartSummaryDetailsContainer,
    AnalyticsChartSummaryDetailBoxContainer,
    AnalyticsChartSummaryDetailBox,
    AnalyticsChartSummaryDetailTitle,
    AnalyticsChartSummaryDetailsWrapper,
    TooltipWrapper,
    TooltipTitle,
    TooltipDescription,
    AnalyticsChartSummaryHeader,
    AnalyticsChartSummaryRiskStatusWrapper,
    AnalyticsChartSummaryDetailBoxSubtitle
} from './styled';
import GaugeComponent from 'react-gauge-component';
import { IAnalyticsData } from 'utils/interfaces/analytics.interface';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import IconTooltip from 'components/tooltip/IconTooltip';

interface SummaryViewProps {
    data: IAnalyticsData | null;
}

const SummaryView: FC<SummaryViewProps> = ({ data }) => {
    const [scoreLabel, setScoreLabel] = useState<string>('');

    const determineScoreOfCompany = (score: number | undefined) => {
        if (score) {
            switch (true) {
                case score < 100:
                    setScoreLabel('This company is a high risk');

                    break;
                case score < 200:
                    setScoreLabel('This company could be a potential risk');

                    break;
                case score < 300:
                    setScoreLabel('Overall this company is in need of improvements');

                    break;
                case score < 400:
                    setScoreLabel('Overall this company is doing OK');

                    break;
                case score < 500:
                    setScoreLabel('Overall this company is in good shape');

                    break;
                default:
                    setScoreLabel('Overall this company is in good shape');
            }
        }
    };

    useEffect(() => {
        determineScoreOfCompany(data?.score);
    }, []);

    return (
        <AnalyticsChartSummaryWrapper>
            <AnalyticsChartSummaryHeader>
                <AnalyticsChartSummaryTitle>SUMMARY</AnalyticsChartSummaryTitle>
                <IconTooltip
                    tooltipText={
                        <TooltipWrapper>
                            {/* <TooltipTitle>Title</TooltipTitle> */}
                            <TooltipDescription>
                                Our overall performance score is derived from a combination of
                                sales, bills, customer concentration, and the number of data points
                                that are available to analyse. DSO and DPO are calculated based on
                                the most recent three months.
                            </TooltipDescription>
                        </TooltipWrapper>
                    }
                />
            </AnalyticsChartSummaryHeader>
            <AnalyticsChartSummaryContainer>
                <AnalyticsChartSummaryInfoContainer>
                    <AnalyticsChartSummaryRiskStatusWrapper>
                        <GaugeComponent
                            value={data?.score || 0}
                            maxValue={1000}
                            style={{ width: '100%' }}
                            type="semicircle"
                            labels={{
                                valueLabel: {
                                    hide: true
                                },
                                tickLabels: {
                                    defaultTickValueConfig: {
                                        style: { fontSize: 14 }
                                    },
                                    hideMinMax: true,
                                    ticks: [
                                        {
                                            value: 0,
                                            valueConfig: {
                                                formatTextValue: () => {
                                                    return '0';
                                                }
                                            }
                                        },
                                        {
                                            value: 200,
                                            valueConfig: {
                                                formatTextValue: () => {
                                                    return '200';
                                                }
                                            }
                                        },
                                        {
                                            value: 400,
                                            valueConfig: {
                                                formatTextValue: () => {
                                                    return '400';
                                                }
                                            }
                                        },
                                        {
                                            value: 600,
                                            valueConfig: {
                                                formatTextValue: () => {
                                                    return '600';
                                                }
                                            }
                                        },
                                        {
                                            value: 800,
                                            valueConfig: {
                                                formatTextValue: () => {
                                                    return '800';
                                                }
                                            }
                                        },
                                        {
                                            value: 1000,
                                            valueConfig: {
                                                formatTextValue: () => {
                                                    return '1000';
                                                }
                                            }
                                        }
                                    ]
                                }
                            }}
                            arc={{
                                colorArray: ['#E15367', '#f3df39', '#29C68F'],
                                padding: 0.03,
                                width: 0.3,
                                subArcs: [
                                    { limit: 200 },
                                    { limit: 400 },
                                    { limit: 600 },
                                    { limit: 800 },
                                    { limit: 1000 }
                                ]
                            }}
                            pointer={{
                                elastic: true,
                                animationDelay: 0
                            }}
                        />
                        <AnalyticsChartSummaryRiskNumber>
                            {data ? data?.score : 0}
                        </AnalyticsChartSummaryRiskNumber>
                        <AnalyticsChartSummaryRiskStatus>
                            {scoreLabel || 'Unable to retrieve score'}
                        </AnalyticsChartSummaryRiskStatus>
                    </AnalyticsChartSummaryRiskStatusWrapper>
                    <AnalyticsChartSummaryDetailsWrapper>
                        <AnalyticsChartSummaryDetailBoxSubtitle>
                            Current Outstanding
                        </AnalyticsChartSummaryDetailBoxSubtitle>
                        <AnalyticsChartSummaryDetailsContainer>
                            <AnalyticsChartSummaryDetailBoxContainer>
                                <AnalyticsChartSummaryDetailBox>
                                    £{data ? formatNumber(data.sales.outstanding, 2) : 0}
                                </AnalyticsChartSummaryDetailBox>
                                <AnalyticsChartSummaryDetailTitle>
                                    Invoices
                                </AnalyticsChartSummaryDetailTitle>
                            </AnalyticsChartSummaryDetailBoxContainer>
                            <AnalyticsChartSummaryDetailBoxContainer>
                                <AnalyticsChartSummaryDetailBox>
                                    £{data ? formatNumber(data.expenses.outstanding, 2) : 0}
                                </AnalyticsChartSummaryDetailBox>
                                <AnalyticsChartSummaryDetailTitle>
                                    Payables
                                </AnalyticsChartSummaryDetailTitle>
                            </AnalyticsChartSummaryDetailBoxContainer>
                        </AnalyticsChartSummaryDetailsContainer>
                        <AnalyticsChartSummaryDetailBoxSubtitle>
                            Days Outstanding
                        </AnalyticsChartSummaryDetailBoxSubtitle>
                        <AnalyticsChartSummaryDetailsContainer>
                            <AnalyticsChartSummaryDetailBoxContainer>
                                <AnalyticsChartSummaryDetailBox>
                                    {data ? data.sales.daysSalesOutstanding : 0}
                                </AnalyticsChartSummaryDetailBox>
                                <AnalyticsChartSummaryDetailTitle>
                                    Sales
                                </AnalyticsChartSummaryDetailTitle>
                            </AnalyticsChartSummaryDetailBoxContainer>
                            <AnalyticsChartSummaryDetailBoxContainer>
                                <AnalyticsChartSummaryDetailBox>
                                    {data ? data.expenses.daysPayablesOutstanding : 0}
                                </AnalyticsChartSummaryDetailBox>
                                <AnalyticsChartSummaryDetailTitle>
                                    Payables
                                </AnalyticsChartSummaryDetailTitle>
                            </AnalyticsChartSummaryDetailBoxContainer>
                        </AnalyticsChartSummaryDetailsContainer>
                    </AnalyticsChartSummaryDetailsWrapper>
                </AnalyticsChartSummaryInfoContainer>
            </AnalyticsChartSummaryContainer>
        </AnalyticsChartSummaryWrapper>
    );
};

export default SummaryView;
