import { device } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const ConnectorConfirmationViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 42px;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 32px;
    @media ${device.mobileOnly} {
        padding: 0;
    }
`;

export const ConnectorConfirmationViewHeaderTitle = styled.h2`
    margin: 0;
    font-size: ${fontSizes.xLarge};
    font-weight: ${fontWeights.bold};
`;

export const ConnectorConfirmationViewContentDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
`;

export const ConnectorConfirmationViewContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    width: 100%;
    align-items: center;
`;

export const ConnectorConfirmationViewContentVideo = styled.div`
    display: flex;
    width: 80%;
    .video-react .video-react-big-play-button {
        top: 45%;
        left: 45%;
        @media ${device.mobileOnly} {
            top: 30%;
            left: 30%;
        }
    }
    @media ${device.mobileOnly} {
        width: 100%;
    }
`;

export const ConnectorConfirmationViewButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media ${device.mobileOnly} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
    }
`;

export const ConnectorConfirmationViewButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    flex-direction: column;
    gap: 10px;
`;

export const ConnectorConfirmationViewContentStepsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
`;

export const ConnectorConfirmationViewContentTitle = styled.p`
    margin: 0;
    ${typography.bodySemiBold};
`;

export const ConnectorConfirmationViewContentSteps = styled.p`
    margin: 0;
    ${typography.captionRegular};
`;

export const ConnectorConfirmationViewButtonText = styled.p`
    margin: 0;
    ${typography.bodySemiBold};
`;

export const ConnectorConfirmationViewTextBold = styled.span`
    font-weight: ${fontWeights.bold};
`;
