/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { apiConfig, get, put } from 'api/api';
import DataGrid from 'components/DataGrid';
import LoaderFullPage from 'components/LoaderFullPage';
import { authResult } from 'lib/authConfig';
import { formatDateTime } from 'lib/helpers/datetimeFormatters';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import DataGridHeaderItem from 'utils/classes/data-grid/dataGridHeaderBuilder';
import DataGridHeaderOptions from 'utils/classes/data-grid/dataGridHeaderOptions';
import { DataGridRowItem } from 'utils/interfaces/data-grid/dataGrid.interface';
import { CustomerViewTableAction, CustomerViewTableContainer } from 'views/CustomersView/styled';
import { InvitationButtonContainer, InvitationsContainer } from './styled';
import { PrimaryButton } from 'components/common/buttons';

const fieldDictionary = {
    createdDate: 'createdDate',
    email: 'email',
    status: 'status'
};
interface InvitationStatus {
    error: boolean;
    warning: boolean;
    status: boolean;
}

const Invitations: FC = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingInvite, setLoadingInvite] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(-1);
    const [data, setData]: [DataGridRowItem[], Dispatch<SetStateAction<DataGridRowItem[]>>] =
        useState<DataGridRowItem[]>([]);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<string>('createdDate desc');
    const [orderDirection, setOrderDirection] = useState<string>('desc');
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [messages, setMessages] = useState<InvitationStatus>({
        error: false,
        warning: false,
        status: false
    });
    const [invitedEmail, setInvitedEmail] = useState<string>('');

    useEffect(() => {
        invitedEmail &&
            setTimeout(() => {
                setInvitedEmail('');
            }, 10000);
    }, [invitedEmail]);

    const inviteCustomersClickHandler: (email: string) => void = async (email) => {
        setLoadingInvite(true);

        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);

        await put(
            apiConfig.inviteCustomers(),
            {
                sendEmail: true,
                invites: [
                    {
                        email: email,
                        code: '',
                        name: ''
                    }
                ]
            },
            token
        ).then((response: any) => {
            response.status === 200
                ? setMessages({ ...messages, status: true })
                : setMessages({ ...messages, error: true });
        });
        setLoadingInvite(false);
        setInvitedEmail(email);
    };

    const customerTableHeaders: DataGridHeaderItem[] = [
        new DataGridHeaderItem(
            'Invitation Email',
            'email',
            new DataGridHeaderOptions(false, true, true)
        ),
        new DataGridHeaderItem('Date Sent', 'createdDate', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any) => (
                <p>{formatDateTime(value || '', 'dd MMM yyyy HH:mm:ss')}</p>
            )
        }),
        new DataGridHeaderItem('Status', 'status', new DataGridHeaderOptions(false, true, true)),

        new DataGridHeaderItem('', '', {
            ...new DataGridHeaderOptions(false, true, true),
            customBodyRender: (value: any, tableMeta: any) =>
                tableMeta.rowData[2] === 'Pending' && (
                    <InvitationButtonContainer>
                        <PrimaryButton
                            width="fit-content"
                            text={invitedEmail === tableMeta.rowData[0] ? 'Sent!' : 'Resend'}
                            disabled={invitedEmail === tableMeta.rowData[0]}
                            clickHandler={() =>
                                inviteCustomersClickHandler(tableMeta.rowData[0] || value)
                            }
                        />
                    </InvitationButtonContainer>
                )
        })
    ];

    useEffect(() => {
        setLoading(true);
        setPage(0);
    }, []);

    useEffect(() => {
        if (page > -1) getCustomerInvites();
    }, [orderBy, page, rowsPerPage]);

    const getCustomerInvites: () => any = async () => {
        setTableLoading(true);
        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
        get(
            apiConfig.customerInvites(
                `?$count=true&$top=${rowsPerPage}&$orderby=${orderBy}&$skip=${
                    page === -1 ? 0 : rowsPerPage * page
                }`
            ),
            token
        )
            .then((response: any) => response.json())
            .then((responseData: any) => {
                setTotalRecordCount(responseData['@odata.count']);
                setData(responseData.value);
                setLoading(false);
                setTableLoading(false);
            });
    };

    const tableChangeHandler: (action: any, tableState: any) => void = (_action, tableState) =>
        setPage(tableState.page);

    const columnSortChangeClickHandler = (col: any) => {
        const odataField: string | undefined = fieldDictionary[col];
        const sortable = Boolean(odataField);
        if (!sortable) return;
        const direction = orderDirection === 'asc' ? 'desc' : 'asc';
        setOrderDirection(direction);
        setOrderBy(`${fieldDictionary[col]} ${direction}`);
    };

    const rowsPerPageHandler: (numberOfRows: any) => void = (numberOfRows) =>
        setRowsPerPage(numberOfRows);

    const renderLoading = () =>
        loading ? <LoaderFullPage /> : <LoaderFullPage detail="Email is sending..." />;

    return (
        <InvitationsContainer>
            {loading || loadingInvite ? (
                renderLoading()
            ) : (
                <>
                    <CustomerViewTableAction>
                        You have {totalRecordCount} invitations
                    </CustomerViewTableAction>
                    <CustomerViewTableContainer>
                        <DataGrid
                            loading={tableLoading}
                            headers={customerTableHeaders}
                            data={data}
                            ariaLabel="Invitations table"
                            ariaCaption="A table that shows invitations."
                            rowsPerPage={rowsPerPage}
                            totalRows={totalRecordCount}
                            fixedHeader
                            tableChangeHandler={tableChangeHandler}
                            serverSide
                            changeRowsPerPageHandler={rowsPerPageHandler}
                            columnSortChangeClickHandler={columnSortChangeClickHandler}
                            page={page === -1 ? 0 : page}
                            lastChildDiv
                            showFooter={totalRecordCount > 20 ? true : false}
                        />
                    </CustomerViewTableContainer>
                </>
            )}
        </InvitationsContainer>
    );
};

export default Invitations;
