/* eslint-disable react-hooks/exhaustive-deps */
import { useMsal } from '@azure/msal-react';
import { PrimaryButton } from 'components/common/buttons';
import SecondaryButton from 'components/common/buttons/SecondaryButton';
import Typography from 'components/typography';
import { FC } from 'react';
import {
    NoSubscriptionViewButtonContainer,
    NoSubscriptionViewButtonText,
    NoSubscriptionViewButtonWrapper,
    NoSubscriptionViewContent,
    NoSubscriptionViewHeaderTitle,
    NoSubscriptionViewWrapper
} from './styled';

interface NoSubscriptionViewProps {
    closeHandler?: () => void;
}

const NoSubscriptionView: FC<NoSubscriptionViewProps> = ({ closeHandler }) => {
    const { instance } = useMsal();
    return (
        <NoSubscriptionViewWrapper>
            <NoSubscriptionViewHeaderTitle>
                You do not have an active G2F subscription.
            </NoSubscriptionViewHeaderTitle>
            <NoSubscriptionViewContent>
                <Typography tag="body1large">
                    To invite customers you need to set up your subscription to G2F services.
                </Typography>
            </NoSubscriptionViewContent>
            <NoSubscriptionViewButtonWrapper>
                <NoSubscriptionViewButtonContainer>
                    <SecondaryButton clickHandler={() => instance.logoutRedirect()}>
                        <NoSubscriptionViewButtonText>Logout</NoSubscriptionViewButtonText>
                    </SecondaryButton>
                </NoSubscriptionViewButtonContainer>
                <NoSubscriptionViewButtonContainer>
                    <PrimaryButton clickHandler={closeHandler}>
                        <NoSubscriptionViewButtonText>
                            Set up subscription
                        </NoSubscriptionViewButtonText>
                    </PrimaryButton>
                </NoSubscriptionViewButtonContainer>
            </NoSubscriptionViewButtonWrapper>
        </NoSubscriptionViewWrapper>
    );
};

export default NoSubscriptionView;
