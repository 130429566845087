import styled from 'styled-components';
import { device, themeColors } from 'assets/theme/style';
import { fontSizes, fontWeights, typography } from 'assets/theme/typography';

export const CustomerInvitationViewWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
`;

export const CustomerInvitationViewContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    min-width: 400px;
    @media ${device.mobileOnly} {
        min-width: auto;
        width: 80%;
    }
`;

export const CustomerInvitationViewTitle = styled.h2`
    margin: 0;
    font-size: ${fontSizes.xLarge};
    font-weight: ${fontWeights.bold};
    color: ${themeColors.body};
    margin-bottom: 32px;
`;

export const CustomerInvitationViewDescription = styled.p`
    margin: 0;
    margin-bottom: 16px;
`;

export const CustomerInvitationViewFooter = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const CustomerInvitationViewMessageError = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${themeColors.white};
`;

export const CustomerInvitationViewMessageSent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${themeColors.white};
`;

export const CustomerInvitationViewMessageWarning = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${themeColors.white};
`;

export const CustomerInvitationViewButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
    gap: 16px;
`;

export const CustomerInvitationViewButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
`;

export const CustomerInvitationViewButtonText = styled.p`
    margin: 0;
    ${typography.bodySemiBold};
`;

export const CustomerInvitationChipContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 32px;
`;
