/* eslint-disable react-hooks/exhaustive-deps */
import { IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';
import { authResult } from 'lib/authConfig';
import { apiConfig, get, put } from 'api/api';
import { MSALProps } from 'App';
import LoaderFullPage from 'components/LoaderFullPage';
import { _B2C_API_PATH } from 'config/constants';
import { b2cPolicies } from 'lib/authConfig';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Typography from 'components/typography';
import { Link } from 'components/link/styled';
import { SignUpContainer } from './styled';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

const SignUp: FC<MSALProps> = ({ msalInstance }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [confirmedEmail, setConfirmedEmail] = useState<boolean>(false);
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    let count = 0;

    useEffect(() => {
        if (count === 0 && !searchParams.get('stage2')) initSignupSequence();
        if (count === 0 && searchParams.get('stage2')) completeSignupSequence();
        count++;
    }, []);

    const initSignupSequence: () => void = async () => {
        if (!params?.invitationId) return;
        setLoadingMessage('Retrieving your invitation details...');
        const regByInviteRes = await get(apiConfig.registerByInvite(params.invitationId), '');
        regByInviteRes.status === 404 && setConfirmedEmail(true);
        if (!regByInviteRes.ok) return;
        const regByInviteResJson = await regByInviteRes.json();
        const request: RedirectRequest = {
            scopes: [_B2C_API_PATH],
            extraQueryParameters: {
                id_token_hint: regByInviteResJson.idTokenHint,
                campaignId: window.location.hostname
            },
            state: `inviteUID=${params.invitationId}`,
            prompt: 'login',
            redirectStartPage: `${window.location.origin}/signup/${params.invitationId}/accept?stage2=true`,
            authority: b2cPolicies.authorities.inviteRedeem.authority
        };
        await (msalInstance as IPublicClientApplication).loginRedirect(request);
    };

    const completeSignupSequence: () => void = async () => {
        if (!params?.invitationId) return;
        setLoadingMessage('Accepting your invitation details...');

        const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);

        const regByInviteAcceptRes = await put(
            apiConfig.registerByInvite(params.invitationId),
            null,
            token
        );

        if (!regByInviteAcceptRes.ok) return;

        setLoadingMessage('You have been successfully signed up!');
        setTimeout(() => {
            navigate('/payment');
        }, 2000);
    };

    return confirmedEmail ? (
        <SignUpContainer>
            <Typography tag="h1" fontWeight="regular">
                Your email <span style={{ fontWeight: 700 }}>has already been confirmed. </span>
            </Typography>
            <br />
            <br />
            <Typography tag="subheading1">
                <Link onClick={() => navigate('/')}>Click here to go to Dashboard.</Link>
            </Typography>
        </SignUpContainer>
    ) : (
        <LoaderFullPage detail={loadingMessage} />
    );
};

export default SignUp;
